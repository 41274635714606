import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { makeTransparentColor } from 'assets/styles/transparentColor';

import ButtonBase from './ButtonBase';

const GrayButton = styled(ButtonBase)`
  background-color: ${theme.color.borderGray};
  color: ${theme.color.overlay};

  :hover {
    box-shadow: 0 0 10px ${makeTransparentColor(theme.color.borderGray, 0.7)};
  }

  :disabled {
    background-color: ${theme.color.disabled};
    box-shadow: none;
  }
`;

export default GrayButton;
