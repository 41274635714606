import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { Text2Bold } from 'components/typography/Texts';

import { isSubMenuActive } from './menuHelper';
import { SubMenuData } from './menuTypes';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  overflow: unset;

  align-items: center;
  height: 30px;

  ${theme.mq.desktop} {
    flex-direction: column;
    height: unset;
    flex: unset;
  }
`;

const SubMenuButton = styled(Text2Bold)<{ $isSelected: boolean }>`
  display: flex;
  flex: 1;
  color: ${props => (props.$isSelected ? theme.color.red : theme.color.black)};

  ${theme.mq.desktop} {
    margin-top: 20px;
    margin-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    white-space: nowrap;
  }

  &:hover {
    color: ${theme.color.red};
  }
`;

const ActiveIndicator = styled.div`
  width: 8px;
  height: 30px;
  background: ${theme.color.red};
  border-radius: 50px 0 0 50px;

  ${theme.mq.desktop} {
    width: 50px;
    height: 8px;
    border-radius: 50px 50px 0 0;
  }
`;

export const SubMenuItem = (subMenuData: SubMenuData) => {
  const { nameKey, url } = subMenuData;

  const { t } = useTranslation('myProfile');
  const location = useLocation();
  const isSelected = isSubMenuActive(location.pathname, subMenuData);

  return (
    <Container data-testid={'my-profile-menu-sub-item'}>
      <SubMenuButton as={Link} to={url} $isSelected={isSelected}>
        {t(nameKey)}
      </SubMenuButton>
      {isSelected && <ActiveIndicator data-testid={'my-profile-menu-item-active'} />}
    </Container>
  );
};
