import { useTranslation } from 'react-i18next';

import { useNotification } from 'providers/NotificationProvider';

export const useEnterOfferNotifications = () => {
  const { t } = useTranslation('item');
  const { showNotification } = useNotification();

  const offerRegistered = () => {
    showNotification({
      title: t('enter-offer-notification.offer-registered'),
      type: 'success',
    });
  };

  const unknownError = () => {
    showNotification({
      title: t('unknown-error-title'),
      description: t('unknown-error-description'),
      type: 'error',
    });
  };

  return { offerRegistered, unknownError };
};
