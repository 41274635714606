import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import SuccessIconSource from 'assets/svg/valid.svg';

const Message = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.0125em;
  padding: 0;
  margin: 0;
  max-inline-size: min-content;
  min-inline-size: fit-content;
`;

const ErrorMessage = styled(Message)`
  color: ${theme.color.red};
`;

const SuccessMessage = styled(Message)`
  color: ${theme.color.green};
`;

const MessageContainer = styled.div`
  padding: 3px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SuccessIcon = styled.img`
  width: 10px;
  height: 10px;
  padding-right: 2px;
`;

interface Props {
  message: string;
  type: 'success' | 'error';
}

const InputMessage = ({ message, type }: Props) => (
  <MessageContainer data-testid={type === 'success' ? 'success-message' : 'error-message'}>
    {type === 'success' && <SuccessIcon src={SuccessIconSource} />}
    {type === 'success' && <SuccessMessage>{message}</SuccessMessage>}
    {type === 'error' && <ErrorMessage>{message}</ErrorMessage>}
  </MessageContainer>
);

export default InputMessage;
