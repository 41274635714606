import styled from 'styled-components';

import { LoadingIndicator } from 'components/ui-elements/LoadingIndicator';

import CampaignStatusIcon from './CampaignStatusIcon';

const ImageContainer = styled.div`
  width: 100%;
  padding-bottom: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
interface Props {
  image?: string;
  isHidden: boolean;
  campaignStatus: string;
}

const CampaignPhoto = ({ image, isHidden, campaignStatus }: Props) => {
  return (
    <ImageContainer>
      {image ? (
        <Image src={image} alt={'campaign image'} data-testid={'campaign-image'} />
      ) : (
        <LoadingWrapper>
          <LoadingIndicator />
        </LoadingWrapper>
      )}
      <CampaignStatusIcon isHidden={isHidden} campaignStatus={campaignStatus} />
    </ImageContainer>
  );
};

export default CampaignPhoto;
