import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { RowContainer } from 'components/containers/Containers';
import Dropdown from 'components/inputs/Dropdown';
import { PhoneInputField } from 'components/inputs/PhoneInputField';
import TextInputField from 'components/inputs/TextInputField';

export type InputVariant = 'NEW' | 'UPDATE';

export const FormContainer = styled.div<{ variant: InputVariant }>`
  display: flex;
  flex-direction: column;
  background-color: ${props => (props.variant === 'NEW' ? theme.color.white : theme.color.lighterGray)};
  row-gap: 12px;
`;

export const InputsContainer = styled(RowContainer)`
  gap: 12px;
  width: 100%;
  flex: 1;
  align-items: flex-start;
  flex-wrap: wrap;
`;

export const TextInput = styled(TextInputField)<{ variant: InputVariant }>`
  flex: 1;

  & input {
    background-color: ${props => (props.variant === 'NEW' ? theme.color.lighterGray : theme.color.white)};
  }

  & input:disabled {
    background-color: ${theme.color.disabled};
  }
`;

export const DropdownInput = styled(Dropdown)<{ variant: InputVariant }>`
  flex: 1;

  .react-select__control {
    background-color: ${props => (props.variant === 'NEW' ? theme.color.lighterGray : theme.color.white)};
  }
`;

export const PhoneNumberInput = styled(PhoneInputField)<{ variant: InputVariant }>`
  flex: 1;

  .PhoneInput {
    background-color: ${props => (props.variant === 'NEW' ? theme.color.lighterGray : theme.color.white)};
  }

  ${theme.mq.phone} {
    flex-basis: 100%;
  }
`;

export const GrowingInput = styled(TextInput)`
  ${theme.mq.phone} {
    flex-basis: 100%;
  }
`;
