interface OrderActionModel {
  canBeCanceledByBuyer: boolean;
  canBeRefundedByBuyer: boolean;
  canBeCanceledBySeller: boolean;
  canAddressChange: boolean;
  isMeetUpPossible: boolean;
  canPayNow: boolean;
  canPrintLabel: boolean;
}

export const PURCHASE_ACTIONS: { [key: string]: OrderActionModel } = {
  CONFIRMED: {
    canBeCanceledByBuyer: true,
    canBeRefundedByBuyer: false,
    canBeCanceledBySeller: true,
    canAddressChange: false,
    isMeetUpPossible: false,
    canPayNow: true,
    canPrintLabel: false,
  },
  AUTHORIZED: {
    canBeCanceledByBuyer: true,
    canBeRefundedByBuyer: false,
    canBeCanceledBySeller: true,
    canAddressChange: false,
    isMeetUpPossible: true,
    canPayNow: false,
    canPrintLabel: false,
  },
  CHARGED: {
    canBeCanceledByBuyer: false,
    canBeRefundedByBuyer: true,
    canBeCanceledBySeller: false,
    canAddressChange: false,
    isMeetUpPossible: true,
    canPayNow: false,
    canPrintLabel: false,
  },
  PICKED_UP: {
    canBeCanceledByBuyer: false,
    canBeRefundedByBuyer: true,
    canBeCanceledBySeller: false,
    canAddressChange: false,
    isMeetUpPossible: false,
    canPayNow: false,
    canPrintLabel: false,
  },
  COMPLETED: {
    canBeCanceledByBuyer: false,
    canBeRefundedByBuyer: true,
    canBeCanceledBySeller: false,
    canAddressChange: false,
    isMeetUpPossible: false,
    canPayNow: false,
    canPrintLabel: false,
  },
  PAID: {
    canBeCanceledByBuyer: false,
    canBeRefundedByBuyer: true,
    canBeCanceledBySeller: true,
    canAddressChange: true,
    isMeetUpPossible: false,
    canPayNow: false,
    canPrintLabel: false,
  },
  SHIPPABLE: {
    canBeCanceledByBuyer: false,
    canBeRefundedByBuyer: true,
    canBeCanceledBySeller: true,
    canAddressChange: true,
    isMeetUpPossible: false,
    canPayNow: false,
    canPrintLabel: true,
  },
  SHIPPED: {
    canBeCanceledByBuyer: false,
    canBeRefundedByBuyer: true,
    canBeCanceledBySeller: false,
    canAddressChange: false,
    isMeetUpPossible: false,
    canPayNow: false,
    canPrintLabel: false,
  },
};
