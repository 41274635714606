import axios from 'axios';

import { TransactionErrorDto } from 'services/Payment/paymentService.dto';

export const isPaymentDeclined = (error: any) => {
  if (axios.isAxiosError<TransactionErrorDto>(error)) {
    const { errorType } = error.response?.data || {};
    return errorType === 'PAYMENT_DECLINED';
  } else {
    return false;
  }
};

export const isPaymentFailed = (error: any) => {
  if (axios.isAxiosError<TransactionErrorDto>(error)) {
    const { errorType } = error.response?.data || {};
    return errorType === 'PAYMENT_PROVIDER_PROCESSING' || errorType === 'INTERNAL_PROCESSING';
  } else {
    return false;
  }
};

export const isItemUnavailable = (error: any) => {
  if (axios.isAxiosError<TransactionErrorDto>(error)) {
    return error?.response?.status === 400;
  } else {
    return false;
  }
};
