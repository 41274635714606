import styled from 'styled-components';

import { theme } from 'assets/styles/theme';

const ButtonBase = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  width: 100%;
  height: 54px;
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  font-family: ${theme.font.family.header};
  cursor: pointer;
  border: none;
  transition: all 0.3s;

  ${theme.mq.desktop} {
    height: 46px;
  }

  ${theme.mq.phone} {
    font-size: 16px;
    line-height: 18px;
    height: 42px;
  }

  :disabled {
    cursor: initial;
  }
`;

export default ButtonBase;
