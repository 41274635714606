import { useEffect, useState } from 'react';

import { shipmentService } from './shipmentService';
import { GeocodingDto } from './shipmentService.dto';

export const useGeocoding = (zipCode: string) => {
  const [geocoding, setGeocoding] = useState<GeocodingDto>();

  useEffect(() => {
    shipmentService
      .fetchGeocoding(zipCode)
      .then(({ data }) => setGeocoding(data))
      .catch(() => setGeocoding(undefined));
  }, [zipCode]);

  return geocoding;
};
