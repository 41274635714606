import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import ItemTileSlider from 'components/item/ItemSlider/ItemTileSlider';
import { useSimilarItems } from 'services/Item/useSimilarItems';

const Container = styled.div`
  width: 100%;
  max-width: ${theme.maxWidthContainer}px;
  padding: 0 24px;
  margin: 0 auto;

  ${theme.mq.phone} {
    padding: 0 12px;
  }
`;

interface Props {
  itemId: number;
}

const SimilarItems = ({ itemId }: Props) => {
  const { t } = useTranslation('item');
  const { content: items } = useSimilarItems(itemId);

  if (!items || items.length === 0) return null;

  return (
    <Container data-testid={'similar-items'}>
      <ItemTileSlider header={t('similar-items')} items={items} />
    </Container>
  );
};

export default SimilarItems;
