import { SwipeableDrawer } from '@mui/material';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import arrowIcon from 'assets/svg/arrow-right.svg';
import closeIcon from 'assets/svg/close-red.svg';
import { IconWrapper, RowContainer } from 'components/containers/Containers';
import { NavbarText } from 'components/typography/NavbarTexts';
import { useModal } from 'providers/ModalProvider';

import DrawerAccountSection from './DrawerAccountSection';
import { navItems } from './navItems';

const StyledDrawer = styled(SwipeableDrawer)`
  background-color: ${theme.color.overlay};
`;

const Container = styled.div`
  height: 100vh;
  width: 80vw;
  background-color: ${theme.color.white};
  opacity: 1;
`;

const CloseButtonWrapper = styled(RowContainer)`
  justify-content: right;
`;

const CloseButton = styled(IconWrapper)`
  display: flex;
  margin: 24px;
`;

const NavButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 24px;

  & :last-child {
    border-bottom: none;
  }
`;

const NavButton = styled(RowContainer)`
  padding: 18px 0;
  border-bottom: 1px solid ${theme.color.borderGray};
  cursor: pointer;
`;

const NavIcon = styled.img`
  margin-right: 10px;
`;

interface Props {
  toggleSidebar: () => void;
  isOpen: boolean;
}

const Drawer = (props: Props) => {
  const { isOpen, toggleSidebar } = props;
  const { showGetApp } = useModal();

  const displayGetApp = () => {
    toggleSidebar();
    showGetApp();
  };

  return (
    <StyledDrawer onClose={toggleSidebar} onOpen={toggleSidebar} open={isOpen} anchor={'right'} swipeAreaWidth={0}>
      <Container>
        <CloseButtonWrapper>
          <CloseButton src={closeIcon} onClick={toggleSidebar} />
        </CloseButtonWrapper>
        <NavButtonsContainer>
          {navItems.map(navItem => (
            <NavButton key={navItem.name} onClick={displayGetApp}>
              <RowContainer>
                {navItem.icon && <NavIcon src={navItem.icon} />}
                <NavbarText>{navItem.name}</NavbarText>
              </RowContainer>
              <img src={arrowIcon} alt={''} />
            </NavButton>
          ))}
        </NavButtonsContainer>
        <RowContainer>
          <DrawerAccountSection toggleSidebar={toggleSidebar} />
        </RowContainer>
      </Container>
    </StyledDrawer>
  );
};

export default Drawer;
