import { useTranslation } from 'react-i18next';

import { useNotification } from 'providers/NotificationProvider';

export const usePaymentNotification = () => {
  const { showNotification } = useNotification();
  const { t } = useTranslation('myProfile');

  const paymentMethodAdded = () => showNotification({ title: t('payment.notification.added'), type: 'success' });

  const paymentMethodUpdated = () => showNotification({ title: t('payment.notification.updated'), type: 'success' });

  const paymentMethodDeleted = () => showNotification({ title: t('payment.notification.deleted'), type: 'success' });

  return { paymentMethodAdded, paymentMethodUpdated, paymentMethodDeleted };
};
