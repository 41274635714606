import styled from 'styled-components';

import { theme } from 'assets/styles/theme';

import {
  HEADER_HEIGHT_DEFAULT,
  HEADER_HEIGHT_DESKTOP,
  HEADER_HEIGHT_PHONE,
  ONLY_LOGO_HEADER_HEIGHT_DESKTOP,
  ONLY_LOGO_HEADER_HEIGHT_PHONE,
} from './headerConstants';

const calculateHeight = (props: { isOnlyLogo?: boolean }, defaultHeight: number, onlyLogoHeight?: number) => {
  return props.isOnlyLogo ? (onlyLogoHeight ? onlyLogoHeight : defaultHeight) : defaultHeight;
};

const calculateTop = (
  props: { isVisible: boolean; isOnlyLogo?: boolean },
  defaultHeight: number,
  onlyLogoHeight?: number
) => {
  const height = calculateHeight(props, defaultHeight, onlyLogoHeight);
  return props.isVisible ? '0' : props.isOnlyLogo ? -height + 'px' : -defaultHeight + 'px';
};

export const Container = styled.nav<{ isVisible: boolean; isOnlyLogo?: boolean }>`
  position: sticky;
  display: flex;
  width: 100%;
  background-color: ${theme.color.white};
  z-index: ${theme.zIndex.navbar};
  transition: top 0.5s;
  border-bottom: 1px solid ${theme.color.darkGray};

  height: ${HEADER_HEIGHT_DEFAULT}px;
  top: ${props => calculateTop(props, HEADER_HEIGHT_DEFAULT)};

  ${theme.mq.desktop} {
    height: ${props => calculateHeight(props, HEADER_HEIGHT_DESKTOP, ONLY_LOGO_HEADER_HEIGHT_DESKTOP) + 'px'};
    top: ${props => calculateTop(props, HEADER_HEIGHT_DESKTOP, ONLY_LOGO_HEADER_HEIGHT_DESKTOP)};
  }

  ${theme.mq.phone} {
    height: ${props => calculateHeight(props, HEADER_HEIGHT_PHONE, ONLY_LOGO_HEADER_HEIGHT_PHONE) + 'px'};
    top: ${props => calculateTop(props, HEADER_HEIGHT_PHONE, ONLY_LOGO_HEADER_HEIGHT_PHONE)};
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  max-width: ${theme.maxWidthContainer}px;
  padding: 24px;
  margin: 0 auto;
  justify-content: center;

  ${theme.mq.phone} {
    padding: 12px;
    height: initial;
  }
`;
