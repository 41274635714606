import styled from 'styled-components';

import { Caption } from 'components/typography/Texts';

const Wrapper = styled.div`
  width: 100%;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 12px 0;
  flex-wrap: wrap;
  gap: 6px;
`;

const SingleItem = styled.div`
  display: flex;
  min-width: 30%;
  justify-content: center;
  align-items: center;
  gap: 3px;
  padding: 0 8px;
`;

const CaptionBold = styled(Caption)`
  font-weight: bold;
`;

interface ItemSellerStatisticsProps {
  items: ItemSellerStatisticsItem[];
}

interface ItemSellerStatisticsItem {
  id: number;
  name: string;
  value: string | number | undefined;
}

const OwnerStatistic = ({ items }: ItemSellerStatisticsProps) => (
  <Wrapper>
    <ItemContainer>
      {items.map(item => {
        return (
          <SingleItem key={item.id}>
            <CaptionBold data-testid={'statistic-value'}>{item.value}</CaptionBold>
            <Caption>{item.name}</Caption>
          </SingleItem>
        );
      })}
    </ItemContainer>
  </Wrapper>
);

export default OwnerStatistic;
