import { useEffect, useRef, useState } from 'react';

export const useHeaderVisibility = () => {
  const [isNavbarVisible, setIsNavbarVisible] = useState(true);
  const lastScrollY = useRef(0);

  const handleNavbarVisibility = () => {
    setIsNavbarVisible(window.scrollY < lastScrollY.current);
    lastScrollY.current = window.scrollY;
  };

  useEffect(() => {
    window.addEventListener('scroll', handleNavbarVisibility);
    return () => window.removeEventListener('scroll', handleNavbarVisibility);
  }, []);

  return isNavbarVisible;
};
