import { useTranslation } from 'react-i18next';

import { useLoginWithEmailPopup } from 'features/account/login-email/useLoginWithEmailPopup';
import { useEmailCodeRetry } from 'features/account/resend-code/useEmailCodeRetry';
import { useAlertPopup } from 'hooks/useAlertPopup';

export const useSignUpError = () => {
  const { showAlertPopup } = useAlertPopup();
  const { showLoginWithEmailPopup } = useLoginWithEmailPopup();
  const { retryVerification } = useEmailCodeRetry();

  const { t } = useTranslation('account');

  const accountExists = () =>
    showAlertPopup({
      header: t('sign-up.address-in-use-title'),
      description: t('sign-up.address-in-use-message'),
      firstButton: { label: t('sign-up.address-in-use-cancel-button') },
      secondButton: { label: t('sign-up.address-in-use-login-button'), onClick: showLoginWithEmailPopup },
      testId: 'existing-account-alert',
    });

  const inactiveAccount = (email: string) =>
    showAlertPopup({
      header: t('sign-up.inactive-account-title'),
      description: t('sign-up.inactive-account-message', { email }),
      firstButton: { label: t('sign-up.inactive-account-cancel-button') },
      secondButton: { label: t('sign-up.inactive-account-resend-button'), onClick: () => retryVerification(email) },
      testId: 'inactive-account-alert',
    });

  const restrictedName = () =>
    showAlertPopup({
      header: t('sign-up.restricted-name-title'),
      description: t('sign-up.restricted-name-message'),
      firstButton: { label: t('sign-up.restricted-name-button') },
      testId: 'restricted-name-alert',
    });

  return { accountExists, inactiveAccount, restrictedName };
};
