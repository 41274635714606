import { Trans } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { ExternalLinkButton } from 'components/buttons/LinkButton';
import { Text1 } from 'components/typography/Texts';
import { resolvePrivacyPolicyUrl, resolveTermsUrl } from 'utils/webRedirections';

const PrivacyTermsText = styled(Text1)`
  color: ${theme.color.lightBlack};
  white-space: pre-line;
`;

const Link = styled(ExternalLinkButton)`
  color: ${theme.color.lightBlack};
  font-weight: 500;
  text-decoration: underline;
  text-underline-offset: 3px;
  text-decoration-color: ${theme.color.lightBlack};
`;

const PrivacyAndTerms = () => {
  return (
    <PrivacyTermsText data-testid={'privacy-and-terms'}>
      <Trans
        ns={'checkout'}
        i18nKey='terms-and-privacy'
        components={[
          <Link key={0} href={resolveTermsUrl()} target='_blank' />,
          <Link key={1} href={resolvePrivacyPolicyUrl()} target='_blank' />,
        ]}
      />
    </PrivacyTermsText>
  );
};

export default PrivacyAndTerms;
