import { useNewPaymentForm } from 'features/my-profile/payment/hooks/usePaymentForm';
import { usePayment } from 'providers/PaymentProvider';

import { PaymentForm } from './PaymentForm';
import { PaymentFormHeader } from './PaymentFormHeader';

interface Props {
  header?: string;
}

export const NewPaymentMethod = ({ header }: Props) => {
  const { setIsInsertMode, setEditedPaymentMethodId } = usePayment();
  const { values, errors, submitForm, setFieldValue, resetForm, isValid, dirty } = useNewPaymentForm();

  const handleCancel = () => {
    setIsInsertMode(false);
    setEditedPaymentMethodId();
    resetForm();
  };

  return (
    <>
      <PaymentFormHeader header={header} onSave={submitForm} onCancel={handleCancel} isFormValid={isValid && dirty} />
      <PaymentForm values={values} setFieldValue={setFieldValue} errors={errors} isEditMode={false} />
    </>
  );
};
