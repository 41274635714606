import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import MapPointerSource from 'assets/svg/pointer-map.svg';
import { H5, H6 } from 'components/typography/Headers';
import { Text1, Text2 } from 'components/typography/Texts';
import { ItemDto } from 'services/Item/itemService.dto';
import { formatLocationShort } from 'utils/locationFormatter';

import LocationMap from './LocationMap';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Header = styled(H5)`
  margin-bottom: 5px;
`;

const MapPointerIcon = styled.img`
  width: 15px;
  height: 20px;
  margin-right: 12px;
`;

export const LocationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

interface Props {
  item: ItemDto;
}

const LocalPickupDetails = ({ item }: Props) => {
  const { t } = useTranslation('checkout');

  return (
    <Container data-testid={'local-pickup-details'}>
      <Header data-testid={'header'}>{t('pick-up.header')}</Header>
      <H6 data-testid={'contact-header'}>{t('pick-up.contact-header')}</H6>
      <Text2 data-testid={'contact-description'}>{t('pick-up.contact-description')}</Text2>
      <H6 data-testid={'location-header'}>{t('pick-up.location-header')}</H6>
      <LocationContainer>
        <MapPointerIcon src={MapPointerSource} />
        <Text1 data-testid={'location-description'}>
          {t('pick-up.location-description', { location: formatLocationShort(item.itemLocation) })}
        </Text1>
      </LocationContainer>
      <LocationMap zipCode={item.itemLocation.zipCode} />
    </Container>
  );
};

export default LocalPickupDetails;
