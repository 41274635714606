import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import appStoreBanner from 'assets/svg/app-store.svg';
import googlePlayBanner from 'assets/svg/google-play.svg';
import { appStoreLink, googlePlayLink } from 'utils/mobileStoreRedirections';

import StoreBanner from './StoreBanner';

const Container = styled.div<{ topPadding: number }>`
  width: 100%;
  margin: 15px 0;
  display: flex;
  padding-top: ${props => props.topPadding}px;
  justify-content: space-between;
`;

export const openInNewTab = (url: string) => {
  window.open(url, '_blank', 'noopener,noreferrer');
};

interface Props {
  topPadding?: number;
}

const StoreBanners = ({ topPadding }: Props) => {
  const { t } = useTranslation();

  return (
    <Container topPadding={topPadding || 0}>
      <StoreBanner link={googlePlayLink()} imgPath={googlePlayBanner} alt={t('navbar.google')} />
      <StoreBanner link={appStoreLink()} imgPath={appStoreBanner} alt={t('navbar.apple')} />
    </Container>
  );
};

export default StoreBanners;
