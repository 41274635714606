import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import RedButton from 'components/buttons/RedButton';
import { PhoneInputField } from 'components/inputs/PhoneInputField';
import { Text1 } from 'components/typography/Texts';
import StoreBanners from 'components/ui-elements/StoreBanners';
import { notificationService } from 'services/Notification/notificationService';

const US_COUNTRY_CODE = '1';
const MIN_PHONE_LENGTH_WITH_PLUS_SIGN = 12;
const ERROR_CODE_NOT_SUPPORTED = 'NOT_SUPPORTED';

const InputSection = styled.div`
  margin-top: 50px;
  width: 80%;
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const StyledButton = styled(RedButton)`
  margin: 10px 0;
  width: 100%;
`;

const StyledDescription = styled(Text1)`
  color: ${theme.color.red};
  font-weight: 700;
  cursor: pointer;
`;

interface Props {
  context?: string;
  objectId?: number;
}

export const PhoneInputSection = ({ context, objectId }: Props) => {
  let recaptcha: ReCAPTCHA;
  const { t } = useTranslation('common');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [responseToken, setResponseToken] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const navigate = useNavigate();

  const sendLink = () => {
    if (responseToken) {
      if (!isSending) {
        setIsSending(true);
        const mobilePhone = parsePhoneNumberFromString(phoneNumber);
        const countryCode = mobilePhone?.countryCallingCode ? mobilePhone.countryCallingCode.valueOf() : '';
        const phone = {
          countryCode: countryCode.length > 0 ? countryCode : US_COUNTRY_CODE,
          areaCode: phoneNumber.substring(countryCode.length + 1, countryCode.length + 4),
          number: phoneNumber.substring(countryCode.length + 4, phoneNumber.length),
        };
        notificationService
          .sendAppLink(phone, responseToken, context, objectId)
          .then(() => {
            navigate('/thanks');
          })
          .catch(error => {
            if (
              error.response &&
              error.response.status === 400 &&
              error.response.data.code === ERROR_CODE_NOT_SUPPORTED
            ) {
              alert(t('phone.not-supported'));
            } else {
              alert(t('phone.captcha-error'));
            }
          })
          .finally(() => {
            recaptcha?.reset();
            onExpireCaptcha();
            setIsSending(false);
          });
      }
    } else {
      alert(t('phone.captcha-alert'));
    }
  };

  const onVerifyCaptcha = (response: string) => {
    setResponseToken(response);
  };

  const onExpireCaptcha = () => {
    setResponseToken('');
  };

  return isMobile ? (
    <StoreBanners topPadding={20} />
  ) : (
    <InputSection>
      {showInput ? (
        <>
          <PhoneInputField
            value={''}
            onChange={text => setPhoneNumber(text)}
            countries={['US', 'CA']}
            placeholder={t('phone.placeholder')}
          />
          <StyledButton
            disabled={!phoneNumber || phoneNumber.length < MIN_PHONE_LENGTH_WITH_PLUS_SIGN}
            onClick={sendLink}>
            {t('phone.button')}
          </StyledButton>
          <ReCAPTCHA
            ref={(ref: any) => (recaptcha = ref)}
            sitekey={process.env.REACT_APP_CAPTCHA_KEY || ''}
            onChange={value => value && onVerifyCaptcha(value)}
            onExpired={onExpireCaptcha}
          />
        </>
      ) : (
        <StyledDescription onClick={() => setShowInput(true)}>{t('phone.switch-message')}</StyledDescription>
      )}
    </InputSection>
  );
};
