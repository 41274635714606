import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';

import { InfoSection, InfoSectionWrapper, StyledTitle } from 'components/containers/InfoSection';

interface Props {
  testId?: string;
  error?: AxiosError;
}

const ObjectError = ({ error, testId }: Props) => {
  const { t } = useTranslation('error');
  return (
    <InfoSectionWrapper data-testid={testId}>
      <InfoSection onSuccess={false} />
      <StyledTitle>
        {error?.response && error.response.status !== 404 ? t('no-page.loading-page') : t('no-page.not-found')}
      </StyledTitle>
    </InfoSectionWrapper>
  );
};
export default ObjectError;
