import { createContext, PropsWithChildren, useContext, useState } from 'react';

import { clearAuthToken, clearAuthType, getAuthToken, storeAuthToken, storeAuthType } from 'utils/localStorageData';

import SocialMediaProviders from './SocialMediaProviders';

export type AuthType = 'Email' | 'Google' | 'Facebook';

interface AuthContextType {
  appLogin: (authToken: string, authProvider: AuthType) => void;
  appLogout: () => void;
  logged: boolean;
}

const AuthContext = createContext<AuthContextType>(null!);

const AuthProvider = ({ children }: PropsWithChildren) => {
  const [logged, setLogged] = useState(!!getAuthToken());

  const appLogin = (authToken: string, authType: AuthType) => {
    storeAuthToken(authToken);
    storeAuthType(authType);
    setLogged(true);
  };

  const appLogout = () => {
    clearAuthToken();
    clearAuthType();
    setLogged(false);
  };

  return (
    <AuthContext.Provider value={{ appLogin, appLogout, logged }}>
      <SocialMediaProviders>{children}</SocialMediaProviders>
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
