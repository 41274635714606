import styled from 'styled-components';

import { theme } from 'assets/styles/theme';

export const AddressesContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 767px;
  margin-top: 24px;
  margin-bottom: 48px;

  ${theme.mq.phone} {
    margin-bottom: 24px;
  }
`;
