import { useShippingAddress } from 'providers/ShippingAddressProvider';

import { NewShippingAddress } from './components/NewShippingAddress';
import { ShippingAddressesList } from './components/ShippingAddressesList';
import { ShippingAddressSuggestions } from './components/suggestions/ShippingAddressSuggestions';

interface Props {
  header?: string;
}

export const ShippingAddressesModule = ({ header }: Props) => {
  const { displayMode } = useShippingAddress();
  return (
    <>
      {displayMode === 'LIST' && <ShippingAddressesList header={header} />}
      {displayMode === 'NEW' && <NewShippingAddress header={header} />}
      {displayMode === 'SUGGESTION' && <ShippingAddressSuggestions header={header} />}
    </>
  );
};
