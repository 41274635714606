import styled from 'styled-components';

const InactiveOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.3;
`;

interface Props {
  isHidden: boolean;
  campaignStatus: string;
}

export const CampaignInactiveOverlay = ({ isHidden, campaignStatus }: Props) => {
  const displayOverlay = isHidden || campaignStatus === 'CLOSED';

  return displayOverlay ? <InactiveOverlay data-testid={'campaign-inactive-overlay'} /> : null;
};
