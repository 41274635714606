import PhoneInput from 'react-phone-number-input';
import { CountryCode } from 'libphonenumber-js';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';

import BaseInputField, {
  BaseInputProps,
  containerStyle,
  focusStyle,
  fontStyle,
  inputStyle,
  placeholderStyle,
} from './BaseInputField';

const StyledPhoneInput = styled(PhoneInput)<{ $error: boolean; $success: boolean }>`
  &.PhoneInput {
    ${containerStyle};
    ${inputStyle};
  }

  &.PhoneInput--focus {
    ${focusStyle};
  }

  & input {
    ${fontStyle};
    display: flex;
    align-items: center;
    border: none;
    outline: 0;
    background: inherit;
    width: 100%;
  }

  & input::placeholder {
    ${placeholderStyle}
  }

  .PhoneInputCountrySelect {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    border: 0;
    opacity: 0;
    cursor: pointer;
  }

  .PhoneInputCountry {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 10px;
  }

  .PhoneInputCountryIconImg {
    width: 36px;
  }

  .PhoneInputCountrySelectArrow {
    display: block;
    width: 8px;
    height: 8px;
    border: 2px solid ${theme.color.darkerGray};
    border-top-width: 0;
    border-left-width: 0;
    transform: rotate(45deg);
  }
`;

interface Props extends BaseInputProps {
  countries?: CountryCode[];
  defaultCountry?: CountryCode;
}

export const PhoneInputField = ({
  value,
  placeholder,
  onChange,
  countries,
  defaultCountry,
  errorMessage,
  successMessage,
  className,
  testId,
}: Props) => {
  return (
    <BaseInputField testId={testId} className={className} errorMessage={errorMessage} successMessage={successMessage}>
      <StyledPhoneInput
        placeholder={placeholder}
        international={true}
        defaultCountry={defaultCountry}
        limitMaxLength={true}
        value={value}
        onChange={onChange}
        countries={countries}
        addInternationalOption={false}
        countryCallingCodeEditable={true}
        $error={!!errorMessage}
        $success={!!successMessage}
      />
    </BaseInputField>
  );
};
