import React, { createContext, ReactNode, useContext } from 'react';
import {
  ConversationAction,
  conversationsInitialState,
  ConversationsState,
  useConversationsReducer,
} from 'store/reducers/conversations.reducer';
import {
  MessageAction,
  messagesInitialState,
  MessagesState,
  useMessagesReducer,
} from 'store/reducers/messages.reducer';

type State = {
  messagesState: MessagesState;
  conversationsState: ConversationsState;
};

const initialState: State = {
  messagesState: messagesInitialState,
  conversationsState: conversationsInitialState,
};

const MessagingContext = createContext<{
  state: State;
  dispatchMessages: React.Dispatch<MessageAction>;
  dispatchConversations: React.Dispatch<ConversationAction>;
}>({
  state: initialState,
  dispatchMessages: () => null,
  dispatchConversations: () => null,
});

export const MessagingStateProvider = ({ children }: { children: ReactNode }) => {
  const { state: messagesState, dispatch: dispatchMessages } = useMessagesReducer();
  const { state: conversationsState, dispatch: dispatchConversations } = useConversationsReducer();

  const state = { messagesState, conversationsState };

  return (
    <MessagingContext.Provider value={{ state, dispatchMessages, dispatchConversations }}>
      {children}
    </MessagingContext.Provider>
  );
};

export const useMessaging = () => useContext(MessagingContext);
