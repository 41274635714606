import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { Caption } from 'components/typography/Texts';

const Container = styled.div`
  color: ${theme.color.red};
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
`;
interface Props {
  title: string;
}
export const RedWarning = ({ title }: Props) => {
  return (
    <Container>
      <Caption data-testid={'red-warning'}>{title}</Caption>
    </Container>
  );
};
