import { useTranslation } from 'react-i18next';

import { useAlertPopup } from 'hooks/useAlertPopup';

export const usePaymentError = () => {
  const { showAlertPopup } = useAlertPopup();
  const { t } = useTranslation('myProfile');

  const invalidRequest = () => {
    showAlertPopup({
      header: t('payment.error-popup.header'),
      description: t('payment.error-popup.description'),
      firstButton: { label: t('payment.error-popup.confirm-btn') },
      testId: 'invalid-payment-request-alert',
    });
  };

  const limitExceeded = () => {
    showAlertPopup({
      header: t('payment.error-popup.header'),
      description: t('payment.error-popup.limit-description'),
      firstButton: { label: t('payment.error-popup.confirm-btn') },
      testId: 'payment-methods-limit-alert',
    });
  };

  return { invalidRequest, limitExceeded };
};
