import { useEffect, useState } from 'react';

import { SharePanel } from 'components/share/SharePanel';
import { campaignService } from 'services/Campaign/campaignService';
import { itemService } from 'services/Item/itemService';
import { AppAreaType } from 'services/Notification/notificationService.dto';
import {
  CAMPAIGN_SHARE_TYPES,
  DEFAULT_SHARE_TYPE,
  generateDeepLink,
  ITEM_SHARE_TYPES,
  ShareType,
  ShareTypeAppArea,
} from 'services/utils/shareUtils';

export interface Props {
  shareType?: ShareType;
  objectId?: number;
}

const Share = ({ shareType, objectId }: Props) => {
  const [url, setUrl] = useState<string>();
  const [appArea, setAppArea] = useState<AppAreaType | undefined>();
  const [campaignId, setCampaignId] = useState<number | undefined>();
  const [itemId, setItemId] = useState<number | undefined>();
  const [campaignTitle, setCampaignTitle] = useState<string | undefined>();
  const [itemTitle, setItemTitle] = useState<string | undefined>();

  const resolvedShareType: ShareType = shareType || DEFAULT_SHARE_TYPE;

  useEffect(() => {
    if (!!resolvedShareType) {
      setAppArea(ShareTypeAppArea[resolvedShareType]);
    }
  }, [resolvedShareType]);

  useEffect(() => {
    if (!shareType) return;
    if (ITEM_SHARE_TYPES.includes(shareType)) {
      setItemId(objectId);
    } else if (CAMPAIGN_SHARE_TYPES.includes(shareType)) {
      setCampaignId(objectId);
    }
  }, [shareType, objectId]);

  useEffect(() => {
    if (!!campaignId) {
      campaignService.fetchCampaignDetails(campaignId).then(response => setCampaignTitle(response.data.name));
    }
  }, [campaignId]);

  useEffect(() => {
    if (!!itemId) {
      itemService.fetchItemDetails(itemId).then(response => {
        setCampaignId(response.data.supportedCampaignId);
        setItemTitle(response.data.title);
      });
    }
  }, [itemId]);

  useEffect(() => {
    if (!!appArea) {
      generateDeepLink({ context: appArea, objectId }).then(deepLinkUrl => setUrl(deepLinkUrl));
    }
  }, [appArea, objectId]);

  return <SharePanel shareType={resolvedShareType} url={url} campaignTitle={campaignTitle} itemTitle={itemTitle} />;
};

export default Share;
