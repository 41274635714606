import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { TextButton2 } from 'components/typography/TextButtons';
import { Text2 } from 'components/typography/Texts';
import { useResendCounter } from 'features/account/resend-code/useResendCounter';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Description = styled(Text2)`
  margin-left: 12px;
  color: ${theme.color.placeholderGrey};
`;

interface Props {
  onResendClick: () => void;
}

const ResendSection = ({ onResendClick }: Props) => {
  const { t } = useTranslation('account');
  const { secondsLeft, resendsLeft, resetCounter } = useResendCounter();

  const handleResend = () => {
    onResendClick();
    resetCounter();
  };

  return (
    <Container>
      {!!resendsLeft && (
        <TextButton2 data-testid={'resend-button'} disabled={!!secondsLeft} onClick={handleResend}>
          {t('resend-code.resend-code-button')}
        </TextButton2>
      )}
      {!!secondsLeft && (
        <Description data-testid={'resend-availability'}>
          {t('resend-code.resend-availability', { seconds: secondsLeft })}
        </Description>
      )}
    </Container>
  );
};

export default ResendSection;
