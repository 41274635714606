import styled from 'styled-components';

import { theme } from 'assets/styles/theme';

export const CarouselContainer = styled.div`
  height: 605px;
  width: 100%;
  position: relative;

  border: 1px solid ${theme.color.darkGray};
  border-radius: 5px;

  ${theme.mq.desktop} {
    height: 515px;
  }

  ${theme.mq.tablet} {
    height: 400px;
  }

  ${theme.mq.phone} {
    height: 300px;
  }
`;

export const HeartIcon = styled.img`
  z-index: ${theme.zIndex.icon};
  position: absolute;
  top: 50px;
  right: 50px;
  cursor: pointer;

  ${theme.mq.desktop} {
    top: 30px;
    right: 30px;
  }

  ${theme.mq.phone} {
    top: 24px;
    right: 24px;
  }
`;

export const FullScreenIcon = styled.img`
  z-index: ${theme.zIndex.icon};
  position: absolute;
  bottom: 50px;
  left: 50px;
  cursor: pointer;

  ${theme.mq.desktop} {
    bottom: 30px;
    left: 30px;
  }

  ${theme.mq.phone} {
    bottom: 24px;
    left: 24px;
  }
`;
