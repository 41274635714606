import { ReasonDto } from 'services/Report/reportService.dto';

export const addReason = (reasonDto: ReasonDto, reasons: ReasonDto[]): ReasonDto[] => {
  return [...reasons, reasonDto];
};

export const removeReasons = (reasonDto: ReasonDto, reasons: ReasonDto[]): ReasonDto[] => {
  const index = reasons.map(item => item.name).indexOf(reasonDto.name, 0);

  if (index > -1) {
    reasons.splice(index, 1);
  }

  return [...reasons];
};
