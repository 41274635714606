import styled from 'styled-components';
import Lightbox, { YoutubeVideoSlide } from 'yet-another-react-lightbox';

import { CarouselProps } from './carousel.types';
import { resolveSlidesLength } from './utils/carouselUtils';
import VideoPlayer from './utils/VideoPlayer';

import 'yet-another-react-lightbox/styles.css';

const VideoWrapper = styled.div`
  height: 90%;
  width: 90%;
`;

export const CarouselFullScreen = (props: CarouselProps) => {
  const { videoUrl, photos, isFullScreen, unsetFullScreen, initSlideIndex } = props;
  const slidesLength = resolveSlidesLength(props);

  const resolveVideoSlide = (videoUrl: string): YoutubeVideoSlide => {
    return {
      type: 'youtube', // currently only YT is supported
      url: videoUrl,
    };
  };

  return (
    <Lightbox
      open={isFullScreen}
      close={unsetFullScreen}
      index={initSlideIndex || 0}
      carousel={{ finite: slidesLength <= 1 }}
      slides={
        !!videoUrl
          ? [resolveVideoSlide(videoUrl), ...photos.map(photo => ({ src: photo }))]
          : photos.map(photo => ({ src: photo }))
      }
      render={{
        slide: ({ slide }) =>
          slide.type === 'youtube' ? (
            <VideoWrapper>
              <VideoPlayer videoUrl={slide.url} autoplay={false} />
            </VideoWrapper>
          ) : undefined,
      }}
      styles={{ container: { backgroundColor: 'rgba(0, 0, 0, .8)' } }}
    />
  );
};
