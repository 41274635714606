import { useTranslation } from 'react-i18next';

import { useNotification } from 'providers/NotificationProvider';

export const useShippingAddressNotification = () => {
  const { showNotification } = useNotification();
  const { t } = useTranslation('myProfile');

  const shippingAddressAdded = () =>
    showNotification({ title: t('shipping-address.notification.added'), type: 'success' });

  const shippingAddressUpdated = () =>
    showNotification({ title: t('shipping-address.notification.updated'), type: 'success' });

  const shippingAddressDeleted = () =>
    showNotification({ title: t('shipping-address.notification.deleted'), type: 'success' });

  return { shippingAddressAdded, shippingAddressUpdated, shippingAddressDeleted };
};
