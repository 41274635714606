import styled from 'styled-components';

import { theme } from 'assets/styles/theme';

const FormSeparator = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${theme.color.gray};
  margin-top: 15px;
  margin-bottom: 15px;
`;

export default FormSeparator;
