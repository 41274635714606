import { ReactNode } from 'react';
import { Popover } from '@mui/material';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';

interface Props {
  anchor: HTMLDivElement | null;
  children: ReactNode;
  onClose: () => void;
}

const StyledPopover = styled(Popover)`
  & .MuiPopover-paper {
    border: 1px solid ${theme.color.darkGray};
    padding: 14px 4px;
  }
`;

const ActionPanelPopover = (props: Props) => {
  return (
    <StyledPopover
      anchorEl={props.anchor}
      open={true}
      onClose={props.onClose}
      onClick={props.onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: -10, horizontal: 65 }}>
      {props.children}
    </StyledPopover>
  );
};

export default ActionPanelPopover;
