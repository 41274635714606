import { useEffect, useRef, useState } from 'react';

export const RESEND_LIMIT = 5;
export const INIT_TIMEOUT_MILLISECONDS = 9000;
export const TIMEOUT_MILLISECONDS = 60000;

export const useResendCounter = () => {
  const [resendsLeft, setResendsLeft] = useState(RESEND_LIMIT);
  const [secondsLeft, setSecondsLeft] = useState(INIT_TIMEOUT_MILLISECONDS / 1000);

  const nextResendTime = useRef(getTimeWithOffset(INIT_TIMEOUT_MILLISECONDS));

  useEffect(() => {
    const clock = setInterval(refreshState, 1000);
    return () => clearInterval(clock);
  }, []);

  const refreshState = () => {
    const diffInSeconds = Math.round((nextResendTime.current - Date.now()) / 1000);
    const adjustedSecondsLeft = Math.max(diffInSeconds, 0);

    setSecondsLeft(adjustedSecondsLeft);
  };

  const resetCounter = () => {
    if (resendsLeft > 0 && secondsLeft === 0) {
      setResendsLeft(current => current - 1);
      setSecondsLeft(TIMEOUT_MILLISECONDS / 1000);
      nextResendTime.current = getTimeWithOffset(TIMEOUT_MILLISECONDS);
    }
  };

  return { resendsLeft, secondsLeft, resetCounter };
};

const getTimeWithOffset = (offset: number) => Date.now() + offset;
