import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { AxiosError } from 'axios';

import { userService } from 'services/User/userService';
import { PublicUserDto } from 'services/User/userService.dto';

interface UsersContextType {
  users: PublicUserDto[];
  fetchUsers: (userIds: number[], handleError?: (e: AxiosError) => void) => void;
  isLoading: boolean;
}

const UsersContext = createContext<UsersContextType>(null!);

interface PublicUsersState {
  users: PublicUserDto[];
  isLoading: boolean;
  userIds: number[];
  errorHandler?: (e: AxiosError) => void;
}

const initialState: PublicUsersState = {
  users: [],
  isLoading: false,
  userIds: [],
};

const UserProvider = ({ children }: PropsWithChildren) => {
  const [publicUsers, setPublicUsers] = useState<PublicUsersState>(initialState);

  useEffect(() => {
    if (publicUsers.isLoading) {
      userService
        .fetchBulkPublicUsers(publicUsers.userIds)
        .then(response => response.data)
        .then(users => setPublicUsers({ ...initialState, users }))
        .catch(e => {
          publicUsers.errorHandler?.(e);
          setPublicUsers({ ...initialState, users: publicUsers.users });
        });
    }
  }, [publicUsers.isLoading]);

  const fetchUsers = (userIds: number[], handleError?: (e: AxiosError) => void) => {
    setPublicUsers({ ...publicUsers, userIds: userIds, isLoading: true, errorHandler: handleError });
  };

  return (
    <UsersContext.Provider value={{ users: publicUsers.users, fetchUsers, isLoading: publicUsers.isLoading }}>
      {children}
    </UsersContext.Provider>
  );
};

const useUsers = () => useContext(UsersContext);

export { UserProvider, useUsers };
