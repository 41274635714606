import React, { createContext, ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';

import { orderService } from 'services/Order/orderService';
import { SafetySpotDto } from 'services/Order/orderService.dto';

import { calculateGridKey, GRID_CELL_SIZE } from './utils/safetySpotProviderUtils';

export interface SafetySpotsCache {
  [key: string]: SafetySpotDto[];
}

interface SafetySpotsContextProps {
  fetchAndCacheSpots: (
    centerLatitude: number,
    centerLongitude: number,
    latitudeDelta: number,
    longitudeDelta: number
  ) => Promise<SafetySpotDto[]>;
}

const SafetySpotsContext = createContext<SafetySpotsContextProps | undefined>(undefined);

export const SafetySpotsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [safetySpotsCache, setSafetySpotsCache] = useState<SafetySpotsCache>({});
  const cacheRef = useRef<SafetySpotsCache>({});

  useEffect(() => {
    cacheRef.current = safetySpotsCache;
  }, [safetySpotsCache]);

  useEffect(() => {
    return () => {
      setSafetySpotsCache({});
    };
  }, []);

  const updateSafetySpotsCache = useCallback((newCache: SafetySpotsCache) => {
    setSafetySpotsCache(prevCache => {
      return { ...prevCache, ...newCache };
    });
  }, []);

  const fetchSafetySpots = useCallback(
    (
      centerLatitude: number,
      centerLongitude: number,
      latitudeDelta: number,
      longitudeDelta: number
    ): Promise<SafetySpotDto[]> => {
      return orderService
        .fetchSafetySpot(centerLatitude, centerLongitude, latitudeDelta, longitudeDelta)
        .then(response => response.data);
    },
    []
  );

  const fetchAndCacheSpots = useCallback(
    async (
      centerLatitude: number,
      centerLongitude: number,
      latitudeDelta: number,
      longitudeDelta: number
    ): Promise<SafetySpotDto[]> => {
      const latitudeMin = centerLatitude - latitudeDelta / 2;
      const latitudeMax = centerLatitude + latitudeDelta / 2;
      const longitudeMin = centerLongitude - longitudeDelta / 2;
      const longitudeMax = centerLongitude + longitudeDelta / 2;

      const gridKeys: string[] = [];
      for (let lat = latitudeMin; lat <= latitudeMax + GRID_CELL_SIZE; lat += GRID_CELL_SIZE) {
        for (let lng = longitudeMin; lng <= longitudeMax + GRID_CELL_SIZE; lng += GRID_CELL_SIZE) {
          gridKeys.push(calculateGridKey(lat, lng));
        }
      }

      const uncachedKeys = gridKeys.filter(key => !cacheRef.current[key]);

      if (uncachedKeys.length > 0) {
        const newSpots = await fetchSafetySpots(centerLatitude, centerLongitude, latitudeDelta, longitudeDelta);
        const newCache: SafetySpotsCache = {};

        gridKeys.forEach(key => {
          newCache[key] = newSpots.filter(spot => calculateGridKey(spot.lat, spot.lon) === key);
        });

        updateSafetySpotsCache(newCache);
        return newSpots;
      } else {
        return gridKeys.flatMap(key => cacheRef.current[key]);
      }
    },
    [safetySpotsCache]
  );

  return <SafetySpotsContext.Provider value={{ fetchAndCacheSpots }}>{children}</SafetySpotsContext.Provider>;
};

export const useSafetySpots = (): SafetySpotsContextProps => {
  const context = useContext(SafetySpotsContext);
  if (!context) {
    throw new Error('useSafetySpots must be used along with SafetySpotsProvider');
  }
  return context;
};
