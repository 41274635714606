import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { H5 } from 'components/typography/Headers';
import { Text1 } from 'components/typography/Texts';

import ConversationAwareContactSellerSection from './ConversationAwareContactSellerSection';

const Container = styled.div`
  margin-top: 48px;
  display: flex;
  flex-direction: column;
`;

const Description = styled(Text1)`
  margin-top: 10px;
`;

interface Props {
  sellerId: number;
  itemId: number;
}

const PickupDetails = ({ sellerId, itemId }: Props) => {
  const { t } = useTranslation('checkout');

  return (
    <Container data-testid={'pick-up-details'}>
      <H5 data-testid={'pick-up-header'}>{t('success.pick-up-header')}</H5>
      <Description data-testid={'pick-up-description'}>{t('success.pick-up-description')}</Description>
      <ConversationAwareContactSellerSection sellerId={sellerId} itemId={itemId} />
    </Container>
  );
};

export default PickupDetails;
