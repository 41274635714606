import { AxiosPromise } from 'axios';

import PageableType from 'services/pageableType';
import { apiService, apiUrl } from 'services/utils/apiService';

import { SearchItemDto, SearchMarketplaceItemsParams } from './searchService.dto';

class SearchService {
  BASE_URL = `${apiUrl}/api/search`;

  searchItems(params: SearchMarketplaceItemsParams): AxiosPromise<PageableType<SearchItemDto>> {
    const url = `${this.BASE_URL}/public/items`;
    return apiService.get(url, {
      ...params,
    });
  }
}

const searchService = new SearchService();

export { searchService };
