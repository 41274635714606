import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { useMessengerAlert } from 'features/messenger/hooks/useMessengerAlert';
import { useConversations } from 'providers/ConversationProvider';
import { ConversationDto } from 'services/Message/messageService.dto';

interface ConversationProps {
  conversation: ConversationDto;
  children: ReactNode;
}

export const TrashIconWrapper = ({ conversation, children }: ConversationProps) => {
  const { t } = useTranslation();
  const { handleMessengerAlert } = useMessengerAlert();
  const { setCurrentConversationId, removeConversation } = useConversations();

  const handleDeleteClick = () => {
    const firstButton = {
      label: t('message:delete-conversation.delete-button'),
      onClick: () => {
        removeConversation(conversation.conversationId);
        setCurrentConversationId(undefined);
      },
    };
    const secondButton = {
      label: t('message:delete-conversation.cancel-button'),
    };
    handleMessengerAlert(
      t('message:delete-conversation.header'),
      firstButton,
      secondButton,
      t('message:delete-conversation.body')
    );
  };

  return conversation.context !== 'SUPPORT' ? <div onClick={handleDeleteClick}>{children}</div> : <></>;
};
