import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import RedButton from 'components/buttons/RedButton';
import WhiteButton from 'components/buttons/WhiteButton';
import AccountInfo from 'components/nav/AccountInfo';
import { useLoginPopup } from 'features/account';
import { useLogout } from 'features/account';
import { useAuth } from 'providers/AuthProvider';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 24px;
`;

const DrawerAccountInfo = styled(AccountInfo)`
  margin-bottom: 24px;
`;

const LoginButton = styled(RedButton)`
  margin-bottom: 12px;
`;

const MyAccountButton = styled(RedButton)`
  margin-bottom: 12px;
`;

const LogoutButton = styled(WhiteButton)`
  margin-bottom: 12px;
`;

interface Props {
  toggleSidebar: () => void;
}

const DrawerAccountSection = ({ toggleSidebar }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { showLoginPopup } = useLoginPopup();
  const { logged } = useAuth();
  const { logout } = useLogout();

  const handleLoginClick = () => {
    toggleSidebar();
    showLoginPopup();
  };

  const handleLogoutClick = async () => {
    toggleSidebar();
    await logout();
  };

  const handleAccountClick = () => {
    toggleSidebar();
    navigate('/my-profile');
  };

  if (logged) {
    return (
      <Container>
        <DrawerAccountInfo />
        <MyAccountButton onClick={handleAccountClick}>{t('navbar.account')}</MyAccountButton>
        <LogoutButton onClick={handleLogoutClick}>{t('navbar.logout')}</LogoutButton>
      </Container>
    );
  } else {
    return (
      <Container>
        <LoginButton onClick={handleLoginClick}>{t('navbar.login')}</LoginButton>
      </Container>
    );
  }
};

export default DrawerAccountSection;
