import styled from 'styled-components';

import AuctionIconSource from 'assets/svg/auction.svg';
import DeliveryIconSource from 'assets/svg/delivery.svg';
import MultipleIconSource from 'assets/svg/multiple-items.svg';
import HeartButton from 'components/buttons/HeartButton';

const Image = styled.img`
  width: 15px;
  height: 15px;
  margin-left: 5px;
`;

const HeartWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

export const MultipleIcon = () => <Image src={MultipleIconSource} data-testid={'multiple-icon'} />;

export const AuctionIcon = () => <Image src={AuctionIconSource} data-testid={'auction-icon'} />;

export const DeliveryIcon = () => <Image src={DeliveryIconSource} data-testid={'delivery-icon'} />;

export const HeartIcon = () => (
  <HeartWrapper>
    <HeartButton selected={false} />
  </HeartWrapper>
);
