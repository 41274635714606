import { AxiosPromise } from 'axios';

import PageableType from 'services/pageableType';
import { apiService, apiUrl } from 'services/utils/apiService';

import {
  ConversationDto,
  ConversationMessagingContext,
  MessageDto,
  MessageUrlsDto,
  UnreadMessageCountDto,
} from './messageService.dto';

class MessageService {
  private static BASE_URL = `${apiUrl}/api/message`;

  fetchConversations(pageSize: number, pageNumber: number): AxiosPromise<PageableType<ConversationDto>> {
    const url = `${MessageService.BASE_URL}/conversations`;
    return apiService.get(url, {
      size: pageSize,
      page: pageNumber,
    });
  }

  fetchConversation(conversationId: number) {
    return apiService.get(`${MessageService.BASE_URL}/conversations/${conversationId}`);
  }

  createConversation(
    context: ConversationMessagingContext,
    interlocutorId: number,
    objectId?: number
  ): AxiosPromise<ConversationDto> {
    const payload = {
      context,
      itemId: context === 'ITEM' ? objectId : null,
      campaignId: context === 'CAMPAIGN' ? objectId : null,
      interlocutorId,
    };
    return apiService.post(`${MessageService.BASE_URL}/conversations`, payload);
  }

  removeConversation(conversationId: number) {
    const url = `${MessageService.BASE_URL}/conversations/${conversationId}`;
    return apiService.delete(url);
  }

  fetchUnreadMessagesCount(): AxiosPromise<UnreadMessageCountDto> {
    return apiService.get(`${MessageService.BASE_URL}/unread-messages-count`);
  }

  refreshConversationImages(conversationId: number, messageIds: number[]): AxiosPromise<MessageDto[]> {
    const url = `${MessageService.BASE_URL}/refresh-images`;
    return apiService.post(url, { conversationId, messageIds });
  }

  closeSupportConversation(conversationId: number) {
    return apiService.post(`${MessageService.BASE_URL}/support-conversation-closures`, { conversationId });
  }

  addMessage(conversationId: number, type: string, content: string): AxiosPromise<MessageDto> {
    const url = `${MessageService.BASE_URL}/conversations/${conversationId}/messages`;
    const payload = type === 'PHOTO' ? { type, mediaUrl: content } : { type, content };

    return apiService.post(url, payload);
  }

  fetchConversationMessages(
    conversationId: number,
    pageSize: number,
    pageNumber: number
  ): AxiosPromise<PageableType<MessageDto>> {
    const url = `${MessageService.BASE_URL}/conversations/${conversationId}/messages`;
    return apiService.get(url, {
      size: pageSize,
      page: pageNumber,
      sort: 'created,desc',
    });
  }

  confirmMessages(messageIds: number[]) {
    const payload = messageIds.map(id => {
      return { messageId: id };
    });
    return apiService.post(`${MessageService.BASE_URL}/message-confirmations`, payload);
  }

  generateMsgUrls(conversationId: number): AxiosPromise<MessageUrlsDto[]> {
    const url = `${MessageService.BASE_URL}/conversations/${conversationId}/message-urls`;
    return apiService.post(url, { conversationId });
  }
}

const messageService = new MessageService();

export { messageService, MessageService };
