import { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { makeTransparentColor } from 'assets/styles/transparentColor';
import plusIcon from 'assets/svg/plus.svg';
import ButtonBase from 'components/buttons/ButtonBase';

const StyledButton = styled(ButtonBase)`
  width: fit-content;
  padding: 0 20px;
  background-color: ${theme.color.white};
  color: ${theme.color.red};
  border: 2px solid ${theme.color.red};

  :hover {
    box-shadow: 0 0 10px ${makeTransparentColor(theme.color.red, 0.7)};
  }
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

const PlusIcon = styled.img`
  width: 18px;
  height: 18px;
`;

export const CreateButton = ({ children, ...otherProps }: ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <StyledButton {...otherProps}>
      <ContentContainer>
        <PlusIcon src={plusIcon} alt={'plus-icon'} />
        {children}
      </ContentContainer>
    </StyledButton>
  );
};
