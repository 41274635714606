import { useTranslation } from 'react-i18next';

import { useAlertPopup } from 'hooks/useAlertPopup';

export const useShippingAddressError = () => {
  const { showAlertPopup } = useAlertPopup();
  const { t } = useTranslation('myProfile');

  const invalidRequest = () => {
    showAlertPopup({
      header: t('shipping-address.error-popup.header'),
      description: t('shipping-address.error-popup.description'),
      firstButton: { label: t('shipping-address.error-popup.confirm-btn') },
      testId: 'invalid-shipping-address-request-alert',
    });
  };

  const addressAlreadyExists = () => {
    showAlertPopup({
      header: t('shipping-address.error-popup.header'),
      description: t('shipping-address.error-popup.duplicate-description'),
      firstButton: { label: t('shipping-address.error-popup.confirm-btn') },
      testId: 'duplicate-shipping-address-alert',
    });
  };

  const zipNotLocated = () => {
    showAlertPopup({
      header: t('shipping-address.error-popup.header'),
      description: t('shipping-address.error-popup.zip-not-located-description'),
      firstButton: { label: t('shipping-address.error-popup.confirm-btn') },
      testId: 'zip-not-located-shipping-address-alert',
    });
  };

  return { invalidRequest, addressAlreadyExists, zipNotLocated };
};
