const AMEX_PREFIX = 'american';
const AMEX_REPLACEMENT = 'AMEX';

export const MIN_CREDIT_CARD_LENGTH = 13;
export const MAX_CREDIT_CARD_LENGTH = 16;
export const MIN_PHONE_NUMBER_LENGTH = 11;

export const EXPIRATION_DATE_REGEX = /^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/;

export const formatCardNumber = (cardNumber: string) => {
  return cardNumber.replace('XXXX', '**** ');
};

export const formatCardType = (cardType: string) => {
  return cardType.toLowerCase().startsWith(AMEX_PREFIX) ? AMEX_REPLACEMENT : cardType;
};

export const formatExpirationDate = (expirationDate: string) => {
  return `${expirationDate.substring(5, 7)}/${expirationDate.substring(2, 4)}`;
};

export const parseExpirationDate = (expirationDate: string) => {
  return `20${expirationDate.substring(3, 5)}-${expirationDate.substring(0, 2)}`;
};

export const isExpirationDateValid = (expirationDate: string) => {
  const now = new Date();
  const expirationYear = `20${expirationDate.substring(3, 5)}`;
  const expirationMonth = expirationDate.substring(0, 2);
  const currentMonth = now.getMonth() + 1; // counts from 0
  const currentYear = now.getFullYear();
  return +expirationYear > currentYear || (+expirationYear === currentYear && +expirationMonth >= currentMonth);
};
