import { usePopup } from 'hooks/usePopup';

import VerifyEmailPopup from './VerifyEmailPopup';

export const useVerifyEmailPopup = () => {
  const { showPopup, hidePopup } = usePopup();

  const showVerifyEmailPopup = (email: string, password?: string, onConfirmed?: () => void) =>
    showPopup(
      <VerifyEmailPopup
        credentials={{ email, password }}
        onConfirmed={() => {
          hidePopup();
          onConfirmed?.();
        }}
      />
    );

  return { showVerifyEmailPopup };
};
