import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { storeLastPublicRoute } from 'utils/localStorageData';

const PUBLIC_ROUTES = ['/items/', '/campaigns/'];

const TrackLastPublicRoute = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (PUBLIC_ROUTES.some(publicRoute => pathname.startsWith(publicRoute))) {
      storeLastPublicRoute(pathname);
    }
  }, [pathname]);

  return null;
};

export default TrackLastPublicRoute;
