import styled from 'styled-components';

const Banner = styled.img`
  cursor: pointer;
  height: auto;
  max-width: 200px;
  margin: 5px 12px;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.05);
  }
`;

export const openInNewTab = (url: string) => {
  window.open(url, '_blank', 'noopener,noreferrer');
};

interface Props {
  link: string;
  imgPath: string;
  alt?: string;
}

const StoreBanner = ({ link, imgPath, alt }: Props) => {
  return <Banner onClick={() => openInNewTab(link)} src={imgPath} alt={alt} />;
};

export default StoreBanner;
