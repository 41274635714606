import { useEffect, useState } from 'react';

import { useGlobalData } from 'providers/GlobalDataProvider';
import { ItemDto } from 'services/Item/itemService.dto';
import { PurchaseDto } from 'services/Order/orderService.dto';

export const usePurchaseAccess = (
  itemId: number,
  isBuyer: boolean,
  item?: ItemDto,
  order?: PurchaseDto,
  orderError?: any
) => {
  const { currentUser } = useGlobalData();

  const [userNotAllowed, setUserNotAllowed] = useState(false);

  useEffect(() => {
    if (!!item && !isBuyer) {
      if (item.ownerId !== currentUser?.id) {
        setUserNotAllowed(true);
      }
    }
  }, [item]);

  useEffect(() => {
    if (!!order) {
      if (order.itemId !== itemId) {
        setUserNotAllowed(true);
      } else if (order.buyerId !== currentUser?.id && order.ownerId !== currentUser?.id) {
        setUserNotAllowed(true);
      }
    } else if (orderError?.response?.status === 404 || orderError?.response?.status === 403) {
      setUserNotAllowed(true);
    }
  }, [order, orderError]);

  return {
    userNotAllowed,
  };
};
