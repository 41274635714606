import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import logoIcon from 'assets/svg/logo-full.svg';
import { IconWrapper } from 'components/containers/Containers';
import { openInNewTab } from 'components/ui-elements/StoreBanners';
import { resolveLandingUrl } from 'utils/webRedirections';

const LogoIcon = styled(IconWrapper)`
  width: 8vw;

  ${theme.mq.desktop} {
    width: 77px;
  }
`;

export const GiveoLogo = () => {
  const openLandingPage = () => openInNewTab(resolveLandingUrl());

  return <LogoIcon src={logoIcon} onClick={openLandingPage} />;
};
