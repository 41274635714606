import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import WhiteButton from 'components/buttons/WhiteButton';
import Spinner from 'components/ui-elements/Spinner';

const ButtonWrapper = styled.div`
  width: 335px;
`;

const Footer = styled.div`
  margin-top: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface Props {
  hasNextPage: boolean;
  loading: boolean;
  onClick: () => void;
}

export const LoadMore = ({ hasNextPage, loading, onClick }: Props) => {
  const { t } = useTranslation('common');

  const displayLoadMore = hasNextPage && !loading;
  const displaySpinner = loading;
  const displayFooter = displayLoadMore || displaySpinner;

  return (
    <>
      {displayFooter && (
        <Footer>
          {displayLoadMore && (
            <ButtonWrapper>
              <WhiteButton onClick={onClick}>{t('load-more')}</WhiteButton>
            </ButtonWrapper>
          )}
          {displaySpinner && <Spinner />}
        </Footer>
      )}
    </>
  );
};
