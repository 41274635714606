import styled from 'styled-components';

import { theme } from 'assets/styles/theme';

import ButtonBase from './ButtonBase';

export const RedTextButton = styled(ButtonBase)`
  margin-left: auto;
  width: 100px;
  background-color: ${theme.color.white};
  color: ${theme.color.red};
  border: none;
`;
