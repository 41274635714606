import { useNavigate } from 'react-router-dom';

import { ItemFilter } from 'services/useMyItems';

export const useMyActivityNavigate = () => {
  const navigate = useNavigate();

  const goToHotDeals = (initialFilter: ItemFilter) => {
    if (initialFilter === ItemFilter.SELLING) {
      goToSelling();
    } else if (initialFilter === ItemFilter.DONATED) {
      goToDonated();
    } else if (initialFilter === ItemFilter.BOUGHT) {
      goToBought();
    }
  };

  const goToCampaigns = () => navigate('/my-profile/my-activity/campaigns');

  const goToSelling = () => navigate('/my-profile/my-activity/selling');

  const goToDonated = () => navigate('/my-profile/my-activity/donated');

  const goToBought = () => navigate('/my-profile/my-activity/bought');

  return { goToCampaigns, goToHotDeals, goToSelling, goToDonated, goToBought };
};
