import { apiService, apiUrl } from 'services/utils/apiService';

class ReportService {
  BASE_URL = `${apiUrl}/api/report`;

  fetchReasons() {
    const url = `${this.BASE_URL}/public/reasons`;
    return apiService.get(url);
  }
}

const reportService = new ReportService();

export { reportService, ReportService };
