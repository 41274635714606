import { useState } from 'react';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import ButtonBase from 'components/buttons/ButtonBase';
import PriceInputField from 'components/inputs/PriceInputField';
import { Text1Bold, Text2 } from 'components/typography/Texts';
import { NotificationContent, useNotification } from 'providers/NotificationProvider';
import { InternalMessageDto } from 'services/Message/messageService.dto';

import { MessageDetails } from './MessageDetails';

const Container = styled.div`
  max-width: 270px;
  background-color: ${theme.color.lighterGray};
  border-radius: 6px;

  ${theme.mq.phone} {
    max-width: 100%;
  }
`;

const Title = styled(Text1Bold)`
  margin-top: 0;
`;

const Message = styled(Text2)`
  margin: 6px 0;
  color: ${theme.color.darkerGray};
  width: 100%;
  align-items: flex-start;
`;

const VerticalLine = styled.div`
  height: 100%;
  width: 1px;
  margin-top: 0;
  background-color: ${theme.color.darkGray};
`;

const ButtonsContainer = styled.div`
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 0;
  gap: 1px;
  width: 100%;
  border-top: 1px solid ${theme.color.darkGray};
`;

const TextContainer = styled.div`
  align-items: flex-start;
  align-self: center;
  width: 100%;
  padding: 12px;
`;

const Button = styled(ButtonBase)`
  color: ${theme.color.red};
  background-color: ${theme.color.lighterGray};
  height: 100%;
`;

const StyledPriceInput = styled(PriceInputField)`
  margin-bottom: 0;
  input {
    height: 32px;
    background-color: ${theme.color.white};
    font-size: 14px;
    line-height: 18px;
  }
`;

export interface NotificationMessageProps {
  message: InternalMessageDto;
  isMessageOwner: boolean;
}

export interface MessageDialogButton {
  label: string;
  action: (data?: any) => void;
}

export const NotificationMessage = ({ message, isMessageOwner }: NotificationMessageProps) => {
  const [inputValue, setInputValue] = useState<number>();
  const { createInfoNotificationFromPayload } = useNotification();
  const notificationData: NotificationContent = createInfoNotificationFromPayload(
    message.payload.type,
    message.payload.attributes
  );

  const handlePriceChange = (inputValue: number) => setInputValue(inputValue);

  const resolveActionData = () => {
    return notificationData.inputType !== 'Price'
      ? notificationData.attributes
      : { ...notificationData.attributes, input: inputValue };
  };

  return (
    <div>
      <Container data-testid={'notification-message'}>
        <TextContainer>
          <Title data-testid={'notification-message-title'}>{notificationData.title}</Title>
          {notificationData.description && notificationData.description.length > 0 && (
            <Message data-testid={'notification-message-text'}>{notificationData.description}</Message>
          )}
          {notificationData.inputType === 'Price' && (
            <StyledPriceInput
              data-testid={'notification-message-input'}
              value={inputValue}
              onChange={handlePriceChange}
              placeholder={notificationData.priceInputPlaceholder}
            />
          )}
        </TextContainer>
        {notificationData.firstButton && (
          <ButtonsContainer>
            <Button
              data-testid={'notification-message-first-button'}
              onClick={() => notificationData.firstButton?.action(resolveActionData())}>
              {notificationData.firstButton.label}
            </Button>
            {notificationData.secondButton && <VerticalLine />}
            {notificationData.secondButton && (
              <Button
                data-testid={'notification-message-second-button'}
                onClick={() => notificationData.secondButton?.action(resolveActionData())}>
                {notificationData.secondButton.label}
              </Button>
            )}
          </ButtonsContainer>
        )}
      </Container>
      <MessageDetails message={message} isMessageOwner={isMessageOwner} />
    </div>
  );
};
