import { PropsWithChildren } from 'react';
import { FacebookProvider } from 'react-facebook';
import { GoogleOAuthProvider } from '@react-oauth/google';

const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID as string;
const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID as string;

const SocialMediaProviders = ({ children }: PropsWithChildren) => {
  return (
    <GoogleOAuthProvider clientId={googleClientId}>
      <FacebookProvider appId={facebookAppId}>{children}</FacebookProvider>
    </GoogleOAuthProvider>
  );
};

export default SocialMediaProviders;
