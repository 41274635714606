import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Caption, Text2Bold } from 'components/typography/Texts';
import { ItemWithAuctionDto } from 'services/Item/withAuctionsData';
import { parseBackendDate } from 'utils/backendDateParser';
import { formatDate } from 'utils/dateFormatter';
import { formatLocationShort } from 'utils/locationFormatter';
import { formatMoneyInteger } from 'utils/numberFormatter';

import { AuctionIcon, DeliveryIcon, MultipleIcon } from './tileIcons';

const MainContainer = styled.div`
  margin-top: auto;
  width: 100%;
`;

const LocationContainer = styled.div`
  margin-bottom: 5px;
`;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const TypeDataContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

interface Props {
  item: ItemWithAuctionDto;
}

const ItemDetails = ({ item }: Props) => {
  const { t } = useTranslation('item');

  const renderLocation = () => {
    return <Caption data-testid={'item-location'}>{formatLocationShort(item.itemLocation)}</Caption>;
  };

  const renderPrice = () => {
    const { price, canAcceptLoHiOffers } = item;
    if (price) {
      return <Text2Bold data-testid={'item-price'}>{formatMoneyInteger(price)}</Text2Bold>;
    } else if (canAcceptLoHiOffers) {
      return <Text2Bold data-testid={'item-price'}>{t('no-price')}</Text2Bold>;
    } else {
      return <Text2Bold data-testid={'item-price'} />;
    }
  };

  const renderStandard = () => {
    const { canNationwideShipping } = item;

    return (
      <TypeDataContainer>
        {canNationwideShipping && <Caption>{t('shippable')}</Caption>}
        {canNationwideShipping && <DeliveryIcon />}
      </TypeDataContainer>
    );
  };

  const renderMultiple = () => {
    const { unitsQuantity, unitsSold, canNationwideShipping } = item;
    const remainingUnits = unitsQuantity! - unitsSold!;

    return (
      <TypeDataContainer>
        <Caption data-testid={'multiple-items-count'}>
          {t('multiple-left', { remainingUnits, count: remainingUnits })}
        </Caption>
        <MultipleIcon />
        {canNationwideShipping && <DeliveryIcon />}
      </TypeDataContainer>
    );
  };

  const renderAuction = () => {
    const { auction, canNationwideShipping } = item;

    if (!!auction) {
      const formattedAuctionDate = formatDate(parseBackendDate(auction.endDateTime));
      return (
        <TypeDataContainer>
          <Caption data-testid={'auction-end-date'}>{formattedAuctionDate}</Caption>
          <AuctionIcon />
          {canNationwideShipping && <DeliveryIcon />}
        </TypeDataContainer>
      );
    }
  };

  return (
    <MainContainer>
      <LocationContainer>{renderLocation()}</LocationContainer>
      <BottomContainer>
        {renderPrice()}
        {item.sellingMethod === 'STANDARD' && renderStandard()}
        {item.sellingMethod === 'MULTIPLE' && renderMultiple()}
        {item.sellingMethod === 'AUCTION' && renderAuction()}
      </BottomContainer>
    </MainContainer>
  );
};

export default ItemDetails;
