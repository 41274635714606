import { AxiosPromise } from 'axios';

import { apiService, apiUrl } from 'services/utils/apiService';
import { getOrGenerateDeviceId } from 'utils/deviceId';

import { LoggedWithEmailDto, LoggedWithSocialMediaDto } from './authService.dto';

class AuthService {
  static BASE_URL = `${apiUrl}/api/auth`;

  login(username: string, password: string): AxiosPromise<LoggedWithEmailDto> {
    const url = `${AuthService.BASE_URL}/public/user`;
    return apiService.post(url, { username: username, password: password, deviceId: getOrGenerateDeviceId() });
  }

  logout(token: string) {
    const url = `${AuthService.BASE_URL}/user/logout`;
    return apiService.delete(url, {}, {}, { authToken: token });
  }

  resetPassword(newPassword: string, passToken: string) {
    const url = `${AuthService.BASE_URL}/public/user/password`;
    return apiService.post(url, { newPassword, passToken });
  }

  loginFacebook(accessToken: string): AxiosPromise<LoggedWithSocialMediaDto> {
    const url = `${AuthService.BASE_URL}/public/facebook/signin`;
    return apiService.post(url, { accessToken, deviceId: getOrGenerateDeviceId() });
  }

  loginGoogle(accessToken: string): AxiosPromise<LoggedWithSocialMediaDto> {
    const url = `${AuthService.BASE_URL}/public/google/signin`;
    return apiService.post(url, { accessToken, deviceId: getOrGenerateDeviceId() });
  }
}

const authService = new AuthService();

export { authService, AuthService };
