import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { DEFAULT_COUNTRY } from 'assets/constants/countries';
import {
  checkAddressAlreadyExists,
  fetchCoordinates,
  MIN_PHONE_NUMBER_LENGTH,
  MIN_POSTAL_CODE_LENGTH,
} from 'features/my-profile/shippingAddress/utils/shippingAddressUtils';
import { useShippingAddress } from 'providers/ShippingAddressProvider';
import { UserShippingAddressDto, UserShippingAddressRequestDto } from 'services/User/userService.dto';
import { convertToPhoneDto, convertToString } from 'utils/phoneFormatter';

import { useShippingAddressError } from './useShippingAddressError';

export interface ShippingAddressFormData {
  firstName: string;
  lastName: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  phone: string;
}

export const useShippingAddressForm = (
  shippingAddress?: UserShippingAddressDto,
  shippingAddressRequest?: UserShippingAddressRequestDto
) => {
  const { t } = useTranslation('myProfile');
  const { shippingAddresses, updateShippingAddress, addShippingAddress } = useShippingAddress();
  const { zipNotLocated, addressAlreadyExists } = useShippingAddressError();

  const initialValues: ShippingAddressFormData = {
    firstName: shippingAddressRequest?.firstName || shippingAddress?.firstName || '',
    lastName: shippingAddressRequest?.lastName || shippingAddress?.lastName || '',
    addressLine1: shippingAddressRequest?.addressLine1 || shippingAddress?.addressLine1 || '',
    addressLine2: shippingAddressRequest?.addressLine2 || shippingAddress?.addressLine2 || '',
    city: shippingAddressRequest?.city || shippingAddress?.city || '',
    state: shippingAddressRequest?.state || shippingAddress?.state || '',
    zip: shippingAddressRequest?.postCode || shippingAddress?.postCode || '',
    country: shippingAddressRequest?.countryCode || shippingAddress?.country || DEFAULT_COUNTRY.value,
    phone: convertToString(shippingAddressRequest?.mobilePhone) || convertToString(shippingAddress?.mobilePhone),
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .required(t('form-inputs.first-name-required'))
      .matches(/^[a-zA-Z0-9\s']+$/, t('form-inputs.not-allowed-chars')),
    lastName: Yup.string()
      .trim()
      .required(t('form-inputs.last-name-required'))
      .matches(/^[a-zA-Z0-9\s']+$/, t('form-inputs.not-allowed-chars')),
    addressLine1: Yup.string().trim().required(t('form-inputs.address-required')),
    addressLine2: Yup.string().trim(),
    city: Yup.string().trim().required(t('form-inputs.city-required')),
    country: Yup.string().trim().required(t('form-inputs.country-required')),
    state: Yup.string().trim().required(t('form-inputs.state-required')),
    zip: Yup.string()
      .trim()
      .required(t('form-inputs.zip-required'))
      .min(MIN_POSTAL_CODE_LENGTH, t('form-inputs.zip-incomplete')),
    phone: Yup.string()
      .trim()
      .required(t('form-inputs.phone-required'))
      .min(MIN_PHONE_NUMBER_LENGTH, t('form-inputs.phone-too-short')),
  });

  const onSubmit = async (data: ShippingAddressFormData) => {
    const coords = await fetchCoordinates(data.zip);
    if (!coords) {
      zipNotLocated();
      return;
    }

    const request: UserShippingAddressRequestDto = {
      firstName: data.firstName.trim(),
      lastName: data.lastName.trim(),
      addressLine1: data.addressLine1.trim(),
      addressLine2: data.addressLine2.trim(),
      city: data.city.trim(),
      postCode: data.zip,
      state: data.state,
      countryCode: data.country,
      mobilePhone: convertToPhoneDto(data.phone.trim()),
      lat: coords.latitude,
      lon: coords.longitude,
      defaultAddress: shippingAddress ? shippingAddress.defaultAddress : true,
    };

    if (checkAddressAlreadyExists(request as UserShippingAddressDto, shippingAddresses)) {
      addressAlreadyExists();
      return;
    }

    shippingAddress ? updateShippingAddress(shippingAddress.id, request) : addShippingAddress(request);
  };

  return useFormik({ initialValues, validationSchema, onSubmit });
};
