import { useState } from 'react';
import styled from 'styled-components';

import EyeButton from 'components/buttons/EyeButton';

import TextInputField from './TextInputField';

const Container = styled.div`
  position: relative;
`;

const StyledTextInput = styled(TextInputField)`
  & input {
    padding-right: 50px;
  }
`;

const StyledEyeButton = styled(EyeButton)`
  position: absolute;
  right: 10px;
  top: 13px;
`;

interface Props {
  value: string;
  placeholder?: string;
  errorMessage?: string;
  successMessage?: string;
  className?: string;
  testId?: string;
  onChange: (value: string) => void;
}

const PasswordInputField = ({ className, testId, ...otherProps }: Props) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const inputType = passwordVisible ? 'text' : 'password';

  return (
    <Container data-testid={testId} className={className}>
      <StyledTextInput {...otherProps} inputType={inputType} />
      <StyledEyeButton
        testId={'show-password-button'}
        onClick={() => setPasswordVisible(!passwordVisible)}
        active={passwordVisible}
      />
    </Container>
  );
};

export default PasswordInputField;
