import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import defaultImagePlaceholder from 'assets/svg/profile-placeholder.svg';
import vipIcon from 'assets/svg/vip-icon.svg';
import { H5 } from 'components/typography/Headers';
import { useMyActivityStats } from 'features/my-profile/my-activity/hooks/useMyActivityStats';
import { useGlobalData } from 'providers/GlobalDataProvider';
import { extractUserName } from 'utils/extractUserName';
import { convertToThumbnail300 } from 'utils/thumbnails';

import { UserStatistics } from './UserStatistics';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 24px;

  ${theme.mq.tablet} {
    flex-direction: column;
  }
`;

const UserImage = styled.img`
  border-radius: 50px;
  width: 100px;
  height: 100px;

  ${theme.mq.desktop} {
    width: 80px;
    height: 80px;
  }
`;

const UserName = styled(H5)`
  font-weight: bold;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 9px;
  padding-right: 20px;
`;

const VipIcon = styled.img`
  width: 30px;
  height: 30px;
`;

const UserContainer = styled.div`
  display: flex;
  gap: 24px;
`;

export const UserDetails = () => {
  const { currentUser } = useGlobalData();
  const { myActivitySellingStats, myActivityBoughtSoldStats, campaignStats } = useMyActivityStats();

  return (
    <>
      {currentUser && (
        <Container>
          <UserContainer>
            <UserImage
              data-testid={'user-image'}
              src={currentUser?.imageUrl ? convertToThumbnail300(currentUser.imageUrl) : defaultImagePlaceholder}
            />
            <TextContainer>
              <UserName data-testid={'user-name'}>{extractUserName(currentUser)}</UserName>
              {currentUser.vip && <VipIcon data-testid={'vip-icon'} src={vipIcon} />}
            </TextContainer>
          </UserContainer>

          <UserStatistics
            sellingStats={myActivitySellingStats}
            boughtSoldStats={myActivityBoughtSoldStats}
            campaignStats={campaignStats}
          />
        </Container>
      )}
    </>
  );
};
