import { usePayment } from 'providers/PaymentProvider';

import { NewPaymentMethod } from './components/NewPaymentMethod';
import { PaymentMethodsList } from './components/PaymentMethodsList';

interface Props {
  header?: string;
}

export const PaymentModule = ({ header }: Props) => {
  const { displayMode } = usePayment();
  return (
    <>
      {displayMode === 'LIST' && <PaymentMethodsList header={header} />}
      {displayMode === 'NEW' && <NewPaymentMethod header={header} />}
    </>
  );
};
