import { useTranslation } from 'react-i18next';

import { PURCHASE_ACTIONS } from 'assets/constants/purchaseActions';
import RedButton from 'components/buttons/RedButton';
import { shipmentService } from 'services/Shipment/shipmentService';

import { TrackedItemComponentsProps } from './trackedItemUtils';

export const PrintLabelButton = ({ isBuyer, order, invalidRequest, onClick }: TrackedItemComponentsProps) => {
  const { t } = useTranslation('myProfile');
  const status = order?.status;
  const refundRequested = order?.refundRequested;
  const orderId = order?.id;

  const printLabel = () => {
    shipmentService
      .fetchShipments(orderId!!)
      .then(response => {
        onClick?.();
        const shipments = response.data;
        return shipmentService
          .downloadLabel(shipments[0].id)
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            const link = document.createElement('a');
            link.style.display = 'none';
            link.href = url;
            link.download = 'label.pdf';
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
          })
          .catch(invalidRequest);
      })
      .catch(invalidRequest);
  };

  const canPrintLabel = () => {
    return !isBuyer && !refundRequested && status && PURCHASE_ACTIONS[status].canPrintLabel;
  };

  if (!canPrintLabel()) return null;
  return (
    <RedButton data-testid={'print-label-btn'} onClick={() => printLabel()}>
      {t('tracked-item.print-label')}
    </RedButton>
  );
};
