import { useEffect, useState } from 'react';

import { campaignService } from './campaignService';
import { CampaignStatsDto } from './campaignService.dto';

const useCampaignStats = () => {
  const [campaignStats, setCampaignStats] = useState<CampaignStatsDto>();

  const refreshCampaignStats = async () => {
    const statsResponse = await campaignService.fetchMyCampaignStats();
    setCampaignStats(statsResponse.data);
  };

  useEffect(() => {
    refreshCampaignStats();
  }, []);

  return { campaignStats, refreshCampaignStats };
};

export default useCampaignStats;
