export type OrderStatus =
  | 'CREATED'
  | 'CONFIRMED'
  | 'AUTHORIZED'
  | 'CHARGED'
  | 'PAID'
  | 'PICKED_UP'
  | 'SHIPPABLE'
  | 'SHIPPED'
  | 'COMPLETED'
  | 'CANCELED'
  | 'FAILED';

export type OrderType = 'DONATION' | 'PURCHASE';

export const ORDER_INVALID_STATUSES = ['CREATED', 'CANCELED', 'FAILED'];

export interface OrderUserDto {
  email?: string;
  firstName?: string;
  lastName?: string;
}

export interface MySoldPublicStatsDto {
  soldCount: number;
}

export interface NewPurchaseDto {
  itemId: number;
  shipmentRequired: boolean;
  payForOffer: boolean;
  destinationAddressId?: number;
}

export interface PurchaseUpdateDto {
  status?: OrderStatus;
  addressFromId?: number;
  addressToId?: number;
  shipmentRequired?: boolean;
}

export interface PurchaseDto {
  id: number;
  shipmentPrice: number;
  transactionFee: number;
  transactionFeeAddedAmount: number;
  basePrice: number;
  amountTotal: number;
  orderType: OrderType;
  currency: string;
  shipmentRequired: boolean;
  payForOffer: boolean;
  status: OrderStatus;
  buyerId: number;
  ownerId: number;
  shipmentId?: number;
  itemId: number;
  supportedCampaignId: number;
  addressFrom?: PurchaseAddressDto;
  addressTo?: PurchaseAddressDto;
  refundRequested: boolean;
}

export interface PurchaseAddressDto {
  firstName: string;
  lastName: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  zip: string;
  mobilePhone: PurchasePhoneDto;
}

export interface PurchasePhoneDto {
  countryCode: string;
  areaCode: string;
  number: string;
}

export interface SafetySpotDto {
  id: number;
  name: string;
  lat: number;
  lon: number;
  zip: string;
  city: string;
  state: string;
  streetAddress: string;
  country: string;
}

export interface MeetupPointDto {
  id?: number;
  customMeetupPoint: boolean;
  name: string;
  streetAddress: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  lat: number;
  lon: number;
}

export interface BoughtItemDto {
  orderId: number;
  itemId: number;
  boughtPrice: number;
}

export interface SoldItemDto {
  itemId: number;
  soldPrice: number;
}
