import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import RadioButton from 'components/buttons/RadioButton';
import { Text2, Text2Bold } from 'components/typography/Texts';
import {
  formatCardNumber,
  formatCardType,
  formatExpirationDate,
} from 'features/my-profile/payment/utils/paymentMethodUtils';
import { usePayment } from 'providers/PaymentProvider';
import { PaymentMethodDto } from 'services/Payment/paymentService.dto';

const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;

  ${theme.mq.phone} {
    align-items: flex-start;
  }
`;

const StyledRadioButton = styled(RadioButton)`
  margin-right: 10px;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row;

  ${theme.mq.phone} {
    flex-direction: column;
  }
`;

const CardDetails = styled(Text2)`
  color: ${theme.color.darkerGray};
  margin-left: 10px;

  ${theme.mq.phone} {
    margin-left: 0;
  }
`;

interface Props {
  paymentMethod: PaymentMethodDto;
}

export const PaymentMethodDetails = ({ paymentMethod }: Props) => {
  const { t } = useTranslation('myProfile');
  const { updatePaymentMethod } = usePayment();

  const setDefault = () => {
    !paymentMethod.isDefault && updatePaymentMethod(paymentMethod.id, { isDefault: true });
  };

  return (
    <Container data-testid={'payment-method-details'}>
      <StyledRadioButton checked={paymentMethod.isDefault} onChange={setDefault} />
      <DetailsContainer>
        <Text2Bold data-testid={'payment-method-details-card-info'}>
          {formatCardType(paymentMethod.cardType)} {formatCardNumber(paymentMethod.cardNumber)}
        </Text2Bold>
        <CardDetails data-testid={'payment-method-details-expire-date'}>
          {t('payment.expire-date', { date: formatExpirationDate(paymentMethod.expirationDate) })}
        </CardDetails>
        <CardDetails data-testid={'payment-method-details-owner-name'}>
          {paymentMethod.firstName} {paymentMethod.lastName}
        </CardDetails>
      </DetailsContainer>
    </Container>
  );
};
