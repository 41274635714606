import { Route, Routes } from 'react-router-dom';

import { routesConfig } from './routesConfig';
import ScrollToTop from './ScrollToTop';
import TrackLastPublicRoute from './TrackLastPublicRoute';

const AppRoutes = () => {
  const routeComponents = routesConfig.map(({ path, element, children }, key) => {
    return (
      <Route path={path} element={element} key={key}>
        {children?.map((childRoute, childIndex) => (
          <Route key={childIndex} path={childRoute.path} element={childRoute.element} />
        ))}
      </Route>
    );
  });

  return (
    <>
      <ScrollToTop />
      <TrackLastPublicRoute />
      <Routes>{routeComponents}</Routes>
    </>
  );
};

export default AppRoutes;
