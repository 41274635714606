import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import readIcon from 'assets/svg/read-icon.svg';
import sentIcon from 'assets/svg/sent-icon.svg';
import { Caption } from 'components/typography/Texts';
import { InternalMessageDto, MessageInternalStatus } from 'services/Message/messageService.dto';
import { formatTime } from 'utils/dateFormatter';
import { isReceived } from 'utils/messagingUtils';

const DateText = styled(Caption)`
  color: ${theme.color.messageGray};
`;

const TimeTicksWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  margin-top: 6px;
`;

const ErrorTextContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 6px;
`;

const ErrorText = styled(Caption)`
  color: ${theme.color.red};
`;

interface Props {
  message: InternalMessageDto;
  isMessageOwner: boolean;
}

export const MessageDetails = ({ message, isMessageOwner }: Props) => {
  const { t } = useTranslation('message');
  const errorStatus =
    message.internalStatus === MessageInternalStatus.ERROR ||
    message.internalStatus === MessageInternalStatus.UPLOAD_ERROR;

  const renderErrorText = () => {
    return (
      <ErrorTextContainer>
        <ErrorText>{t('messenger.not-delivered')}</ErrorText>
      </ErrorTextContainer>
    );
  };

  const renderTick = () => {
    if (!isMessageOwner) return null;

    const received = isReceived(message);
    const icon = received ? readIcon : sentIcon;
    const altText = received ? 'read-icon' : 'sent-icon';

    return <img src={icon} alt={altText} data-testid={altText} />;
  };

  return (
    <>
      {errorStatus ? (
        renderErrorText()
      ) : (
        <TimeTicksWrapper>
          {renderTick()}
          <DateText data-testid={'message-created'}>{formatTime(message.createdAt)}</DateText>
        </TimeTicksWrapper>
      )}
    </>
  );
};
