import { useTranslation } from 'react-i18next';

import RedButton from 'components/buttons/RedButton';
import { CheckoutStatus } from 'features/checkout/item/checkoutStatus';

interface Props {
  checkoutStatus: CheckoutStatus;
  onClick: () => void;
}

const CreditCardButton = ({ checkoutStatus, onClick }: Props) => {
  const { t } = useTranslation('checkout');

  const getCardButtonText = () => {
    if (checkoutStatus === 'MissingPayment') {
      return t('summary.missing-payment-method');
    } else if (checkoutStatus === 'MissingAddress') {
      return t('summary.missing-shipment-address');
    } else {
      return t('summary.buy-now');
    }
  };

  return (
    <RedButton disabled={checkoutStatus !== 'Ready'} data-testid={'credit-card-button'} onClick={onClick}>
      {getCardButtonText()}
    </RedButton>
  );
};

export default CreditCardButton;
