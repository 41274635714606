import { useEffect } from 'react';
import { AxiosPromise } from 'axios';

import { useBackendData } from 'services/useBackendData';

import { orderService } from './orderService';
import { ORDER_INVALID_STATUSES, PurchaseDto } from './orderService.dto';

export const usePurchaseDetails = (itemId?: number, orderId?: number) => {
  const isBuyer = !!(itemId && orderId);

  const findValidOrder = (orders: PurchaseDto[]): PurchaseDto => {
    return orders.find(order => !ORDER_INVALID_STATUSES.includes(order.status))!;
  };

  const fetchPurchaseDetailsByItemId = (itemId: number): AxiosPromise<PurchaseDto> => {
    if (isBuyer) {
      return orderService.fetchMyPurchases(itemId).then(response => ({
        ...response,
        data: findValidOrder(response.data),
      }));
    } else {
      return orderService.fetchMySellings(itemId).then(response => ({
        ...response,
        data: findValidOrder(response.data),
      }));
    }
  };

  const fetchPurchaseDetailsByOrderId = (orderId: number): AxiosPromise<PurchaseDto> => {
    return orderService.fetchPurchaseDetails(orderId);
  };

  const {
    content: order,
    loading,
    error,
    refreshData,
  } = useBackendData(orderId ? fetchPurchaseDetailsByOrderId : fetchPurchaseDetailsByItemId, orderId || itemId);

  useEffect(() => {
    refreshData();
  }, [orderId, itemId]);

  return { order, loading, error, refreshData };
};
