import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import verifiedIcon from 'assets/svg/verified-icon.svg';
import { Caption } from 'components/typography/Texts';

import { OwnerFacebookLink } from './OwnerFacebookLink';

const Wrapper = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const ItemContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const SingleItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 35%;
  flex-direction: row;
  padding: 5px;
  gap: 5px;
`;

interface OwnerVerificationInfoProps {
  isVerifiedForPayments: boolean;
  isConfirmedPhone: boolean;
  isConfirmedFacebook: boolean;
  connectedPageId: string | undefined;
}

export const OwnerVerificationInfo = (props: OwnerVerificationInfoProps) => {
  const { isVerifiedForPayments, isConfirmedPhone, isConfirmedFacebook, connectedPageId } = props;
  const { t } = useTranslation('user');

  return (
    <Wrapper>
      <ItemContainer>
        {isVerifiedForPayments && (
          <SingleItem>
            <img src={verifiedIcon} alt={'verified-icon'} />
            <Caption>{t('owner.payment-verified')}</Caption>
          </SingleItem>
        )}

        {isConfirmedPhone && (
          <SingleItem>
            <img src={verifiedIcon} alt={'verified-icon'} />
            <Caption>{t('owner.phone-verified')}</Caption>
          </SingleItem>
        )}

        {isConfirmedFacebook && (
          <SingleItem>
            <img src={verifiedIcon} alt={'verified-icon'} />
            {connectedPageId ? (
              <OwnerFacebookLink connectedPageId={connectedPageId} />
            ) : (
              <Caption>{`${t('owner.facebook')} ${t('owner.confirmed')}`}</Caption>
            )}
          </SingleItem>
        )}
      </ItemContainer>
    </Wrapper>
  );
};
