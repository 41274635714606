import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { Text2 } from 'components/typography/Texts';

const Container = styled.div<{ padding?: number }>`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${props => props.padding || 0}px 0;
`;

const Spacer = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  border-top: 2px solid ${theme.color.darkGray};
`;

const Label = styled(Text2)`
  position: absolute;
  padding: 0 10px;
  color: ${theme.color.darkGray};
  background-color: ${theme.color.white};
`;

interface Props {
  label?: string;
  padding?: number;
}

export const HorizontalSpacer = ({ label, padding }: Props) => {
  return (
    <Container padding={padding}>
      <Spacer />
      <Label>{label}</Label>
    </Container>
  );
};
