import { shipmentService } from 'services/Shipment/shipmentService';
import { UserShippingAddressDto } from 'services/User/userService.dto';

export const MAX_TOTAL_NAME_LENGTH = 35;
export const MAX_SINGLE_NAME_LENGTH = 34;
export const MIN_PHONE_NUMBER_LENGTH = 11;
export const MAX_PHONE_NUMBER_LENGTH = 15;
export const MIN_POSTAL_CODE_LENGTH = 5;
export const MAX_POSTAL_CODE_LENGTH = 6;
export const MAX_ADDRESS_LENGTH = 35;
export const MAX_CITY_LENGTH = 30;

export const fetchCoordinates = async (postCode: string) => {
  if (postCode.length < MIN_POSTAL_CODE_LENGTH || postCode.length > MAX_POSTAL_CODE_LENGTH) return undefined;
  try {
    const { data } = await shipmentService.fetchGeocoding(postCode);
    return data.coordinates;
  } catch (e) {
    return undefined;
  }
};

export const checkAddressAlreadyExists = (
  address: UserShippingAddressDto,
  shippingAddresses: UserShippingAddressDto[]
) => {
  const shippingAddressesLowerCased = shippingAddresses.map(o => lowerCaseAddress(o));
  return shippingAddressesLowerCased.some(elem => {
    return JSON.stringify(elem) === JSON.stringify(lowerCaseAddress(address));
  });
};

const lowerCaseAddress = (address: UserShippingAddressDto) => {
  return {
    mobilePhone: {
      number: address.mobilePhone.number,
      areaCode: address.mobilePhone.areaCode,
      countryCode: address.mobilePhone.countryCode,
    },
    addressLine1: address.addressLine1.trim().toLowerCase(),
    addressLine2: address.addressLine2 ? address.addressLine2.trim().toLowerCase() : '',
    city: address.city.trim().toLowerCase(),
    postCode: address.postCode.trim().toLowerCase(),
    state: address.state.trim().toLowerCase(),
    firstName: address.firstName.trim().toLowerCase(),
    lastName: address.lastName.trim().toLowerCase(),
  } as UserShippingAddressDto;
};
