import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import ArrowBackSource from 'assets/svg/arrow-back.svg';
import { H6 } from 'components/typography/Headers';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  align-items: center;
  margin-bottom: 24px;
  cursor: pointer;

  ${theme.mq.phone} {
    margin-top: 12px;
  }
`;

const ArrowBackIcon = styled.img`
  width: 18px;
  height: 17px;
  margin-right: 10px;
`;

const ReturnText = styled(H6)`
  font-weight: 700;
`;

export const ReturnButton = () => {
  const { t } = useTranslation('myProfile');
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate('/my-profile/my-activity');
  };
  return (
    <Container data-testid={'go-back-activity'} onClick={() => handleBackClick()}>
      <ArrowBackIcon src={ArrowBackSource} />
      <ReturnText>{t('return-activity')}</ReturnText>
    </Container>
  );
};
