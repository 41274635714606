import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import ButtonBase from 'components/buttons/ButtonBase';
import { ItemFilter } from 'services/useMyItems';

const Container = styled.div`
  display: flex;
  gap: 24px;
`;

const TextButton = styled(ButtonBase)<{ isSelected: boolean }>`
  width: auto;
  background-color: transparent;
  color: ${({ isSelected }) => (isSelected ? theme.color.red : theme.color.black)};
  border: none;
  padding: 0;
  :hover {
    color: ${theme.color.red};
  }
`;

interface Props {
  selectedFilter: ItemFilter;
  handleFilterChange: (newFilter: ItemFilter) => void;
}

export const FilterButtons = ({ selectedFilter, handleFilterChange }: Props) => {
  const onButtonClick = (filter: ItemFilter) => {
    if (filter !== selectedFilter) {
      handleFilterChange(filter);
    }
  };

  return (
    <Container>
      <TextButton isSelected={selectedFilter === ItemFilter.SELLING} onClick={() => onButtonClick(ItemFilter.SELLING)}>
        Selling
      </TextButton>
      <TextButton isSelected={selectedFilter === ItemFilter.DONATED} onClick={() => onButtonClick(ItemFilter.DONATED)}>
        Donated
      </TextButton>
      <TextButton isSelected={selectedFilter === ItemFilter.BOUGHT} onClick={() => onButtonClick(ItemFilter.BOUGHT)}>
        Bought
      </TextButton>
    </Container>
  );
};
