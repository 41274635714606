import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import RadioButton from 'components/buttons/RadioButton';
import { Text1Bold } from 'components/typography/Texts';
import { OpacityOverlay } from 'components/ui-elements/OpacityOverlay';

import { DeliveryType } from './deliveryType';

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 15px;
`;

interface Props {
  deliveryType: DeliveryType;
  onDeliveryChanged: (delivery: DeliveryType) => void;
  isProcessing: boolean;
}

const DeliveryMethod = ({ deliveryType, onDeliveryChanged, isProcessing }: Props) => {
  const { t } = useTranslation('checkout');

  return (
    <Container data-testid={'delivery-method'}>
      <Text1Bold>{t('item.choose-delivery')}</Text1Bold>
      <ButtonsContainer>
        <RadioButton
          data-testid={'delivery-pick-up'}
          checked={deliveryType === 'PickUp'}
          onClick={() => onDeliveryChanged('PickUp')}
          readOnly={true}>
          {t('item.pick-up')}
        </RadioButton>
        <RadioButton
          data-testid={'delivery-shipment'}
          checked={deliveryType === 'Shipment'}
          onClick={() => onDeliveryChanged('Shipment')}
          readOnly={true}>
          {t('item.ship-it')}
        </RadioButton>
      </ButtonsContainer>
      {isProcessing && <OpacityOverlay data-testid={'opacity-overlay'} />}
    </Container>
  );
};

export default DeliveryMethod;
