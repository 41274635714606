import VerificationInput, { VerificationInputProps } from 'react-verification-input';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';

import InputMessage from './InputMessage';

const WrappedVerificationInput = (props: VerificationInputProps & { className?: string }) => (
  <VerificationInput
    classNames={{ container: props.className, character: 'character', characterSelected: 'character-selected' }}
    {...props}
  />
);

const StyledVerificationInput = styled(WrappedVerificationInput)`
  height: 70px;

  & .character {
    width: 63px;
    height: 70px;
    font-size: 40px;
    background: ${theme.color.lighterGray};
    border: 1px solid ${theme.color.lighterGray};
    color: ${theme.color.lightBlack};
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .character-selected {
    outline: 0;
    border: 1px solid ${theme.color.darkGray};
    color: ${theme.color.lightBlack};
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

interface Props {
  errorMessage?: string;
  successMessage?: string;
  disabled?: boolean;
  className?: string;
  testId?: string;
  onComplete: (value: string) => void;
}

const PinInputField = ({ errorMessage, successMessage, disabled, className, testId, onComplete }: Props) => {
  return (
    <Container data-testid={testId} className={className}>
      <StyledVerificationInput
        length={4}
        validChars={'0-9'}
        onComplete={onComplete}
        inputProps={{ disabled }}
        autoFocus={!disabled}
        placeholder={''}
      />
      {errorMessage && <InputMessage message={errorMessage} type={'error'} />}
      {successMessage && <InputMessage message={successMessage} type={'success'} />}
    </Container>
  );
};

export default PinInputField;
