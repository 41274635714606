import { useTranslation } from 'react-i18next';

import { PURCHASE_ACTIONS } from 'assets/constants/purchaseActions';
import WhiteButton from 'components/buttons/WhiteButton';

import { TrackedItemComponentsProps } from './trackedItemUtils';
import { useReturnAndRefundPopup } from './useReturnAndRefundPopup';

export const RefundButton = ({ isBuyer, order, onClick }: TrackedItemComponentsProps) => {
  const { t } = useTranslation('myProfile');
  const status = order?.status;
  const { showReturnAndRefundPopup } = useReturnAndRefundPopup();

  const canBeRefunded = () => {
    return isBuyer && status && PURCHASE_ACTIONS[status].canBeRefundedByBuyer;
  };

  const handleOnClick = () => {
    onClick?.();
    showReturnAndRefundPopup(order!!);
  };

  if (!canBeRefunded()) return null;
  return (
    <WhiteButton data-testid={'refund-btn'} onClick={handleOnClick}>
      {t('tracked-item.return-and-refund')}
    </WhiteButton>
  );
};
