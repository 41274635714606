import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { formatMoneyInteger } from 'utils/numberFormatter';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const BarWrapper = styled.div`
  border: solid 1px ${theme.color.gray};
  border-radius: 60px;
  overflow: hidden;
`;

const BarContent = styled.div<{ progress: number }>`
  background-color: ${theme.color.lightGreen};
  width: ${props => (props.progress > 100 ? 100 : props.progress)}%;
  height: 47px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  ${theme.mq.tablet} {
    height: 42px;
  }
`;

const BarText = styled.p`
  line-height: 22px;

  ${theme.mq.tablet} {
    line-height: 21px;
  }
`;

const BarGoalText = styled(BarText)`
  font-size: 27px;
  font-weight: 700;
  margin-right: 6px;
  padding-left: 16px;

  ${theme.mq.tablet} {
    font-size: 22px;
  }
`;

const BarPercentageText = styled(BarText)`
  font-size: 22px;

  ${theme.mq.tablet} {
    font-size: 17px;
  }
`;

const RemainingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RemainingText = styled.p`
  font-size: 17px;
  line-height: 22px;
  color: ${theme.color.lighterGray2};
  margin: 10px 0 0 0;
`;

const CollectedWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 16px;
`;

const CollectedText = styled.p`
  font-size: 35px;
  font-weight: 700;
  line-height: 17px;
  margin: 0;

  ${theme.mq.tablet} {
    padding-top: 5px;
    font-size: 24px;
  }
`;

interface Props {
  value: number;
  total?: number;
}

const ProgressBar = (props: Props) => {
  const { value, total } = props;
  const { t } = useTranslation();
  const calculateProgress = () => {
    if (!total) return 0;
    const progress = (value * 100) / total;
    if (progress < 0) return 0;
    return progress;
  };

  const progress = calculateProgress();
  const remaining = total ? total - value : 0;

  return (
    <Container>
      {!!total && (
        <>
          <BarWrapper data-testid={'progress-bar'}>
            <BarContent progress={progress}>
              <BarGoalText data-testid={'progress-bar-goal-collected'}>{`${formatMoneyInteger(value)}`}</BarGoalText>
              <BarPercentageText data-testid={'progress-bar-progress'}>{`(${progress.toFixed(2)}%)`}</BarPercentageText>
            </BarContent>
          </BarWrapper>
          <RemainingWrapper>
            {remaining > 0 && (
              <RemainingText data-testid={'progress-bar-missing'}>
                {`${t('progress-bar.missing')} ${formatMoneyInteger(remaining)}`}
              </RemainingText>
            )}
          </RemainingWrapper>
        </>
      )}
      {!total && (
        <CollectedWrapper>
          <CollectedText data-testid={'goal-collected'}>{`${formatMoneyInteger(value)}`}</CollectedText>
        </CollectedWrapper>
      )}
    </Container>
  );
};

export default ProgressBar;
