import styled from 'styled-components';

import { convertToThumbnail600 } from 'utils/thumbnails';

const Image = styled.img`
  object-fit: cover;
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 5px;
`;

interface Props {
  image: string;
}

const ItemPhoto = ({ image }: Props) => {
  return <Image src={convertToThumbnail600(image)} />;
};

export default ItemPhoto;
