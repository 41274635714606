import { usePopup } from 'hooks/usePopup';

import LoginWithEmailPopup from './LoginWithEmailPopup';

export const useLoginWithEmailPopup = () => {
  const { showPopup, hidePopup } = usePopup();

  const showLoginWithEmailPopup = () => showPopup(<LoginWithEmailPopup onLogged={hidePopup} />);

  return { showLoginWithEmailPopup };
};
