import { AuctionDto, BidDto } from 'services/Item/itemService.dto';
import { currentDate, parseBackendDate } from 'utils/backendDateParser';

export const isWinnerDetermined = (auction: AuctionDto) => {
  const { status, winningBidId } = auction;
  return (
    status === 'AWAITING_PAYMENT' ||
    status === 'CLOSED' ||
    status === 'COMPLETED' ||
    (isAuctionWaitingForEnd(auction) && !!winningBidId)
  );
};

export const findWinningBid = (auction: AuctionDto): BidDto | undefined => {
  return auction.bids.find(bid => bid.id === auction.winningBidId);
};

export const isAuctionExpired = (auction: AuctionDto) => {
  return auction.status !== 'ONGOING' || parseBackendDate(auction.endDateTime) < currentDate();
};

export const isAuctionWaitingForEnd = (auction: AuctionDto) =>
  auction.status === 'ONGOING' && parseBackendDate(auction.endDateTime) < currentDate();

export const extractItemPrice = (
  auction: AuctionDto | undefined
): { currentItemPrice: number | null; minBidPrice: number | null } => {
  if (!auction) {
    return { currentItemPrice: null, minBidPrice: null };
  } else if (auction.winningBidId) {
    const winningBid = findWinningBid(auction);
    const winningBidPrice = winningBid ? winningBid.amount : null;
    return { currentItemPrice: winningBidPrice, minBidPrice: winningBidPrice ? winningBidPrice + 1 : null };
  } else {
    const minimumPrice = auction.minimumPrice ?? null;
    return { currentItemPrice: minimumPrice, minBidPrice: minimumPrice };
  }
};
