import { PropsWithChildren } from 'react';

import { AuthProvider } from './AuthProvider';
import { GlobalDataProvider } from './GlobalDataProvider';
import { MessageProvider } from './MessageProvider';
import { MessagingStateProvider } from './MessagingStateProvider';
import { ModalProvider } from './ModalProvider';
import { NotificationProvider } from './NotificationProvider';
import { PopupManagerProvider } from './PopupManagerProvider';
import { UserProvider } from './UserProvider';

const AppProviders = ({ children }: PropsWithChildren) => {
  return (
    <AuthProvider>
      <MessagingStateProvider>
        <GlobalDataProvider>
          <UserProvider>
            <ModalProvider>
              <NotificationProvider>
                <MessageProvider>
                  <PopupManagerProvider>{children}</PopupManagerProvider>
                </MessageProvider>
              </NotificationProvider>
            </ModalProvider>
          </UserProvider>
        </GlobalDataProvider>
      </MessagingStateProvider>
    </AuthProvider>
  );
};

export default AppProviders;
