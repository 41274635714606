import { apiUrl } from 'services/utils/apiService';

export enum MessagePayloadType {
  MESSAGE = 'MESSAGE',
  CONFIRMATION = 'CONFIRMATION',
}

export const WEB_SOCKET_URL = `${apiUrl}/api/message/public/iga-message-websocket`;
export const STOMP_DESTINATION_URL = '/user/exchange/amq.direct/messages';
export const RECONNECT_TIMEOUT_MS = 20000;
