import { useTranslation } from 'react-i18next';
import { useMask } from '@react-input/mask';
import { FormikErrors } from 'formik';
import styled from 'styled-components';

import countries from 'assets/constants/countries';
import states from 'assets/constants/states';
import { theme } from 'assets/styles/theme';
import Spinner from 'components/ui-elements/Spinner';
import {
  DropdownInput,
  FormContainer,
  GrowingInput,
  InputsContainer,
  InputVariant,
  PhoneNumberInput,
  TextInput,
} from 'features/my-profile/components/FormContainers';
import { PaymentMethodFormData } from 'features/my-profile/payment/hooks/usePaymentForm';
import { MAX_CREDIT_CARD_LENGTH } from 'features/my-profile/payment/utils/paymentMethodUtils';
import { usePayment } from 'providers/PaymentProvider';

const CardNumberInput = styled(TextInput)`
  flex: 1.8;

  ${theme.mq.phone} {
    flex-basis: 100%;
  }
`;

interface Props {
  values: PaymentMethodFormData;
  setFieldValue: (field: string, value: string) => void;
  errors: FormikErrors<PaymentMethodFormData>;
  isEditMode: boolean;
}

export const PaymentForm = ({ values, setFieldValue, errors, isEditMode }: Props) => {
  const { t } = useTranslation('myProfile');
  const { isSaving } = usePayment();
  const variant: InputVariant = isEditMode ? 'UPDATE' : 'NEW';

  const expirationDateInputRef = useMask({ mask: '__/__', replacement: { _: /\d/ } });
  const cvvInputRef = useMask({ mask: '____', replacement: !isEditMode ? { _: /\d/ } : '_' });
  const cardInputRef = useMask({
    mask: '_'.repeat(MAX_CREDIT_CARD_LENGTH),
    replacement: !isEditMode ? { _: /\d/ } : '_',
  });
  const zipInputRef = useMask({ mask: '______', replacement: { _: /\d|-/ } });

  if (isSaving) return <Spinner />;
  return (
    <FormContainer data-testid={'payment-form'} variant={variant}>
      <InputsContainer>
        <TextInput
          testId={'first-name-field'}
          value={values.firstName}
          placeholder={t('form-inputs.first-name-placeholder')}
          onChange={value => setFieldValue('firstName', value)}
          errorMessage={errors.firstName}
          variant={variant}
        />
        <TextInput
          testId={'last-name-field'}
          value={values.lastName}
          placeholder={t('form-inputs.last-name-placeholder')}
          onChange={value => setFieldValue('lastName', value)}
          errorMessage={errors.lastName}
          variant={variant}
        />
      </InputsContainer>
      <InputsContainer>
        <CardNumberInput
          testId={'card-number-field'}
          inputRef={cardInputRef}
          value={values.cardNumber}
          placeholder={t('form-inputs.card-number-placeholder')}
          onChange={value => setFieldValue('cardNumber', value)}
          errorMessage={errors.cardNumber}
          variant={variant}
          disabled={isEditMode}
        />
        <TextInput
          inputRef={expirationDateInputRef}
          testId={'expiration-date-field'}
          value={values.expirationDate}
          placeholder={t('form-inputs.expiration-date-placeholder')}
          onChange={value => setFieldValue('expirationDate', value)}
          errorMessage={errors.expirationDate}
          variant={variant}
          disabled={isEditMode}
        />
        <TextInput
          testId={'card-code-field'}
          inputRef={cvvInputRef}
          value={values.cardCode}
          placeholder={t('form-inputs.card-code-placeholder')}
          onChange={value => setFieldValue('cardCode', value)}
          errorMessage={errors.cardCode}
          variant={variant}
          disabled={isEditMode}
        />
      </InputsContainer>
      <InputsContainer>
        <GrowingInput
          testId={'address-field'}
          value={values.address}
          placeholder={t('form-inputs.address-placeholder')}
          onChange={value => setFieldValue('address', value)}
          errorMessage={errors.address}
          variant={variant}
        />
        <TextInput
          testId={'city-field'}
          value={values.city}
          placeholder={t('form-inputs.city-placeholder')}
          onChange={value => setFieldValue('city', value)}
          errorMessage={errors.city}
          variant={variant}
        />
        <DropdownInput
          testId={'state-field'}
          value={values.state}
          options={states}
          placeholder={t('form-inputs.state-placeholder')}
          onChange={value => setFieldValue('state', value)}
          errorMessage={errors.state}
          variant={variant}
        />
      </InputsContainer>
      <InputsContainer>
        <TextInput
          testId={'zip-field'}
          inputRef={zipInputRef}
          value={values.zip}
          placeholder={t('form-inputs.zip-placeholder')}
          onChange={value => setFieldValue('zip', value)}
          errorMessage={errors.zip}
          variant={variant}
        />
        <DropdownInput
          testId={'country-field'}
          value={values.country}
          options={countries}
          placeholder={t('form-inputs.country-placeholder')}
          onChange={value => setFieldValue('country', value)}
          errorMessage={errors.country}
          variant={variant}
        />
        <PhoneNumberInput
          testId={'phone-field'}
          value={values.phone}
          defaultCountry={'US'}
          placeholder={t('form-inputs.phone-placeholder')}
          onChange={value => setFieldValue('phone', value)}
          errorMessage={errors.phone}
          variant={variant}
        />
      </InputsContainer>
    </FormContainer>
  );
};
