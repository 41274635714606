import styled from 'styled-components';

import AppleIconSource from 'assets/svg/login-apple.svg';
import FacebookIconSource from 'assets/svg/login-facebook.svg';
import GoogleIconSource from 'assets/svg/login-google.svg';

const Icon = styled.img`
  height: 24px;
  margin-right: 12px;
`;

export const FacebookIcon = () => <Icon src={FacebookIconSource} data-testid={'facebook-icon'} />;

export const GoogleIcon = () => <Icon src={GoogleIconSource} data-testid={'google-icon'} />;

export const AppleIcon = () => <Icon src={AppleIconSource} data-testid={'apple-icon'} />;
