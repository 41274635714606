import { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';

import { ScreenContainer } from 'components/containers/ScreenContainer';
import { ObjectNotFound } from 'components/ui-elements/ObjectNotFound';

import CheckoutSuccessContent from './CheckoutSuccessContent';
import { CheckoutSuccessState } from './checkoutSuccessState';

export const CheckoutSuccess = () => {
  const { state }: { state: CheckoutSuccessState } = useLocation();

  const renderWithContainer = (content: ReactElement) => (
    <ScreenContainer headerVariant={'LogoAndReturnOnly'} footerVariant={'Copyright'}>
      {content}
    </ScreenContainer>
  );

  if (!state?.transaction || !state?.purchase) return renderWithContainer(<ObjectNotFound testId={'state-error'} />);

  return renderWithContainer(<CheckoutSuccessContent purchase={state.purchase} transaction={state.transaction} />);
};
