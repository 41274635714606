import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Caption } from 'components/typography/Texts';

const CaptionUnderlined = styled(Caption)`
  text-decoration: underline;
`;

interface OwnerFacebookLinkProps {
  connectedPageId: string;
}

export const OwnerFacebookLink = ({ connectedPageId }: OwnerFacebookLinkProps) => {
  const { t } = useTranslation('user');
  const navigate = useNavigate();

  return (
    <>
      <CaptionUnderlined
        data-testid={'facebook-link'}
        onClick={() => navigate('https://www.facebook.com/' + connectedPageId)}>
        {t('owner.facebook')}
      </CaptionUnderlined>
      <Caption>{` ${t('owner.confirmed')}`}</Caption>
    </>
  );
};
