import { createContext, Dispatch, FC, ReactNode, useContext } from 'react';
import { flatMap, uniq } from 'lodash';
import { ConversationAction, ConversationActionTypes, ConversationsState } from 'store/reducers/conversations.reducer';
import { ConversationMessages, MessageAction, MessageActionTypes } from 'store/reducers/messages.reducer';

import { useMessengerAlert } from 'features/messenger/hooks/useMessengerAlert';
import { toLastMessage } from 'services/Message/lastMessageConverter';
import { messageService } from 'services/Message/messageService';
import {
  ConversationDto,
  ConversationMessagingContext,
  ConversationWithLastMessageDto,
  MessageDto,
  MessageType,
} from 'services/Message/messageService.dto';
import { PublicUserDto } from 'services/User/userService.dto';
import { imageUploadService } from 'services/utils/imageUploadService';

import { useGlobalData } from './GlobalDataProvider';
import { useMessages } from './MessageProvider';
import { useMessaging } from './MessagingStateProvider';
import { useUsers } from './UserProvider';
import { mapToInternalMessage } from './utils/conversationProviderUtils';

const CONVERSATION_PAGE_SIZE = 20;
const CONVERSATION_MESSAGE_PAGE_SIZE = 60;

interface ConversationContextDetailsType {
  context: ConversationMessagingContext;
  contextualObjectId?: number;
}

export interface NewMessageBundle {
  tmpMessageId: number;
  content: string;
  conversationId: number;
}

interface ConversationContextType {
  conversations: ConversationWithLastMessageDto[];
  isLoading: boolean;
  fetchConversations: () => void;
  createConversation: (
    conversationContextDetails: ConversationContextDetailsType,
    interlocutorId: number
  ) => Promise<number | undefined>;
  fetchConversation: (conversationId: number) => Promise<void>;
  removeConversation: (conversationId: number) => void;
  refreshConversationImages: (conversationId: number, messages: MessageDto[]) => void;
  closeSupportConversation: (conversationId: number) => void;
  resetConversations: () => void;
  addTextMessage: (message: NewMessageBundle) => void;
  addPhotoMessage: (message: NewMessageBundle) => void;
  resendTextMessage: (message: NewMessageBundle) => void;
  resendPhotoMessage: (message: NewMessageBundle) => void;
  fetchConversationMessages: (conversationId: number) => void;
  currentConversationId?: number;
  setCurrentConversationId: (conversationId?: number) => void;
}

export const ConversationContext = createContext<ConversationContextType>(null!);

const fetchInterlocutors = (
  conversations: ConversationDto[],
  users: PublicUserDto[],
  fetchUsers: (userIds: number[]) => void
) => {
  const interlocutorIds = uniq(
    flatMap(conversations, conversation => conversation.interlocutors.map(interlocutor => interlocutor.id))
  );
  if (interlocutorIds.length === 0) {
    return;
  }

  const existingUserIds = users.map(user => user.id);

  if (!interlocutorIds.every(interlocutorId => existingUserIds.includes(interlocutorId))) {
    fetchUsers(interlocutorIds);
  }
};

export const useConversationFetcher = () => {
  const fetchConversations = (
    conversationsState: ConversationsState,
    conversationDispatch: Dispatch<ConversationAction>,
    currentUserId?: number
  ) => {
    if (conversationsState.isLastPage || conversationsState.isLoading) {
      return;
    }
    conversationDispatch({ type: ConversationActionTypes.CONVERSATIONS_FETCH_REQUEST });
    return messageService
      .fetchConversations(CONVERSATION_PAGE_SIZE, conversationsState.pageNumber)
      .then(response => response.data)
      .then(conversations => {
        conversationDispatch({
          type: ConversationActionTypes.CONVERSATIONS_FETCH_SUCCESS,
          payload: { conversations, currentUserId },
        });
        return conversations.content;
      })
      .catch(e => {
        conversationDispatch({ type: ConversationActionTypes.CONVERSATIONS_FETCH_ERROR });
        throw e;
      });
  };

  const fetchConversationMessages = (
    conversationId: number,
    messages: ConversationMessages[],
    messageDispatch: Dispatch<MessageAction>
  ) => {
    messageDispatch({
      type: MessageActionTypes.MESSAGES_FETCH_MESSAGES_REQUEST,
      payload: { conversationId: conversationId },
    });
    const pageNumber = messages.find(it => it.conversationId === conversationId)?.pageNumber || 0;
    return messageService
      .fetchConversationMessages(conversationId, CONVERSATION_MESSAGE_PAGE_SIZE, pageNumber)
      .then(response => response.data)
      .then(messages => {
        messageDispatch({
          type: MessageActionTypes.MESSAGES_FETCH_MESSAGES_SUCCESS,
          payload: { conversationId: conversationId, messages: messages },
        });
        return messages;
      })
      .catch(e =>
        messageDispatch({
          type: MessageActionTypes.MESSAGES_FETCH_MESSAGES_ERROR,
          payload: { conversationId: conversationId },
        })
      );
  };

  return { fetchConversations, fetchConversationMessages };
};

const useConversationCreator = () => {
  const fetchExistingConversation = (location: string, providerDispatch: Dispatch<any>): Promise<number> => {
    const locationParts = location.split('/');
    const conversationId = Number(locationParts[locationParts.length - 1]);
    return messageService
      .fetchConversation(conversationId)
      .then(response => response.data)
      .then(conversation => {
        providerDispatch({ type: ConversationActionTypes.CONVERSATION_ADD, payload: { conversation } });
        return conversation.conversationId;
      });
  };

  const createConversation = (
    conversationContextDetails: ConversationContextDetailsType,
    interlocutorId: number,
    providerDispatch: Dispatch<any>
  ): Promise<number | undefined> => {
    return messageService
      .createConversation(
        conversationContextDetails.context,
        interlocutorId,
        conversationContextDetails?.contextualObjectId
      )
      .then(response => response.data)
      .then(conversation => {
        providerDispatch({ type: ConversationActionTypes.CONVERSATION_ADD, payload: { conversation } });
        return conversation.conversationId;
      })
      .catch(error => {
        if (error.response?.status === 303) {
          return fetchExistingConversation(error.response.headers.location, providerDispatch);
        }
      });
  };

  return { createConversation };
};

const filterExpiredPhotoMessageIds = (messages: MessageDto[]) => {
  return messages
    .filter(
      (message: MessageDto) =>
        message.id &&
        message.type === MessageType.PHOTO &&
        message.mediaUrlExpirationTime &&
        new Date().getTime() > new Date(message.mediaUrlExpirationTime).getTime()
    )
    .map((message: MessageDto) => message.id!);
};

const ConversationProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { handleMessengerActionError } = useMessengerAlert();
  const { currentUser } = useGlobalData();

  const { state, dispatchMessages, dispatchConversations } = useMessaging();
  const { resetUnreadMessageCount, confirmMessagesReceived } = useMessages();
  const { messagesState, conversationsState } = state;

  const { users, fetchUsers } = useUsers();

  const {
    fetchConversations: fetchConversationsAndUpdateState,
    fetchConversationMessages: fetchConversationMessagesAndUpdateState,
  } = useConversationFetcher();
  const { createConversation: createConversationAndUpdateState } = useConversationCreator();

  const fetchConversations = () => {
    fetchConversationsAndUpdateState(conversationsState, dispatchConversations, currentUser?.id)?.then(
      conversations => {
        if (!!conversations && conversations.length > 0) {
          fetchInterlocutors(conversations, users, fetchUsers);
        }
      }
    );
  };

  const fetchConversation = async (conversationId: number) => {
    return messageService
      .fetchConversation(conversationId)
      .then(response => response.data)
      .then(conversation => {
        dispatchConversations({ type: ConversationActionTypes.CONVERSATION_ADD, payload: { conversation } });
      });
  };

  const createConversation = (
    conversationContextDetails: ConversationContextDetailsType,
    interlocutorId: number
  ): Promise<number | undefined> => {
    return createConversationAndUpdateState(conversationContextDetails, interlocutorId, dispatchConversations);
  };

  const removeConversation = (conversationId: number) => {
    if (!conversationsState.conversations.map(it => it.conversation.conversationId).includes(conversationId)) {
      return;
    }
    messageService
      .removeConversation(conversationId)
      .then(() =>
        dispatchConversations({ type: ConversationActionTypes.CONVERSATION_REMOVE, payload: { conversationId } })
      )
      .then(() => resetUnreadMessageCount())
      .catch(e => handleMessengerActionError(e));
  };

  const refreshConversationImages = (conversationId: number, messages: MessageDto[]) => {
    const messageIds = filterExpiredPhotoMessageIds(messages);

    if (messageIds.length > 0) {
      messageService.refreshConversationImages(conversationId, messageIds).then(response =>
        dispatchMessages({
          type: MessageActionTypes.MESSAGES_REFRESH_IMAGES,
          payload: { conversationId: conversationId, messages: response.data },
        })
      );
    }
  };

  const closeSupportConversation = (conversationId: number) => {
    messageService.closeSupportConversation(conversationId).catch(e => handleMessengerActionError(e));
  };

  const resetConversations = () => {
    dispatchConversations({ type: ConversationActionTypes.CONVERSATIONS_RESET });
    resetUnreadMessageCount();
  };

  const addMessageSuccess = (message: MessageDto, tmpMessageId: number) => {
    // when adding message in conversation context we assume that conversation already exists
    dispatchMessages({
      type: MessageActionTypes.MESSAGES_ADD_MESSAGE_SUCCESS,
      payload: { conversationId: message.conversationId, message, tmpMessageId },
    });
    dispatchConversations({
      type: ConversationActionTypes.CONVERSATION_ADD_MESSAGE,
      payload: toLastMessage(message, currentUser?.id),
    });
  };

  const addMessage = (message: NewMessageBundle, type: MessageType) => {
    messageService
      .addMessage(message.conversationId, type, message.content)
      .then(response => response.data)
      .then(data => addMessageSuccess(data, message.tmpMessageId))
      .catch(e => {
        handleMessengerActionError(e);
        dispatchMessages({
          type: MessageActionTypes.MESSAGES_ADD_MESSAGE_ERROR,
          payload: { conversationId: message.conversationId, tmpMessageId: message.tmpMessageId },
        });
      });
  };

  const addTmpMessage = (message: NewMessageBundle, type: MessageType) => {
    dispatchMessages({
      type: MessageActionTypes.MESSAGES_ADD_TMP_MESSAGE,
      payload: {
        conversationId: message.conversationId,
        message: mapToInternalMessage(message, type, currentUser?.id),
      },
    });
  };

  const addTextMessage = (message: NewMessageBundle) => {
    addTmpMessage(message, MessageType.TEXT);
    addMessage(message, MessageType.TEXT);
  };

  const addTmpPhotoMessage = (message: NewMessageBundle) => {
    addTmpMessage(message, MessageType.PHOTO);
  };

  const addPhotoMessage = async (message: NewMessageBundle) => {
    addTmpPhotoMessage(message);
    await sendPhotoMessage(message);
  };

  const sendPhotoMessage = async (message: NewMessageBundle) => {
    try {
      const result = await imageUploadService.uploadImageFromSrc(message.content, message.conversationId);
      if (result?.imageUrl) {
        const messageUpdatedUrl = { ...message, content: result.imageUrl };
        addMessage(messageUpdatedUrl, MessageType.PHOTO);
      }
    } catch (error) {
      dispatchMessages({
        type: MessageActionTypes.MESSAGES_ADD_MESSAGE_ERROR,
        payload: { conversationId: message.conversationId, tmpMessageId: message.tmpMessageId },
      });
    }
  };

  const resendTextMessage = (message: NewMessageBundle) => {
    addMessage(message, MessageType.TEXT);
  };

  const resendPhotoMessage = (message: NewMessageBundle) => {
    sendPhotoMessage(message);
  };

  const updateConversationLastMessage = (message: MessageDto) => {
    dispatchConversations({
      type: ConversationActionTypes.CONVERSATION_LAST_MESSAGE_UPDATE,
      payload: toLastMessage(message),
    });
  };

  const fetchConversationMessages = (conversationId: number) => {
    fetchConversationMessagesAndUpdateState(conversationId, messagesState.messages, dispatchMessages)?.then(
      messages => {
        if (!!messages && !!messages.content && messages.content.length > 0) {
          const sortedMessages = messages.content.sort(
            (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
          const mostRecentMessage: MessageDto = sortedMessages[0];
          updateConversationLastMessage(mostRecentMessage);
          confirmConversationRead(conversationId, messages.content);
          refreshConversationImages(conversationId, messages.content);
          return messages;
        }
      }
    );
  };

  const confirmConversationRead = (conversationId: number, messages: MessageDto[]) => {
    const stateMessages = messagesState.messages.find(it => it.conversationId === conversationId)?.messageContent || [];
    const unconfirmedMessages: MessageDto[] =
      [...stateMessages, ...messages].filter(it => !it.deliveredToCurrentUser) || [];
    if (unconfirmedMessages.length > 0) {
      const messageIds = unconfirmedMessages.map(it => it.id!);
      messageService
        .confirmMessages(messageIds)
        .then(() => confirmMessagesReceived(messageIds))
        .then(() => resetUnreadMessageCount());
    }
    dispatchConversations({ type: ConversationActionTypes.CONVERSATION_READ, payload: { conversationId } });
  };

  const setCurrentConversationId = (conversationId?: number) => {
    dispatchConversations({ type: ConversationActionTypes.CONVERSATION_SET_CURRENT, payload: { conversationId } });
  };

  return (
    <ConversationContext.Provider
      value={{
        conversations: conversationsState.conversations,
        isLoading: conversationsState.isLoading,
        fetchConversations,
        createConversation,
        removeConversation,
        refreshConversationImages,
        closeSupportConversation,
        resetConversations,
        addTextMessage,
        addPhotoMessage,
        resendTextMessage,
        resendPhotoMessage,
        fetchConversationMessages,
        currentConversationId: conversationsState.currentConversationId,
        setCurrentConversationId,
        fetchConversation,
      }}>
      {children}
    </ConversationContext.Provider>
  );
};

const useConversations = () => useContext(ConversationContext);
export { ConversationProvider, useConversations };
