import { AxiosPromise } from 'axios';

import { DEFAULT_COUNTRY } from 'assets/constants/countries';
import { apiService, apiUrl } from 'services/utils/apiService';

import { GeocodingDto, ShipmentDto } from './shipmentService.dto';

class ShipmentService {
  static BASE_URL = `${apiUrl}/api/shipment`;

  fetchGeocoding(zipCode: string): AxiosPromise<GeocodingDto> {
    const url = `${ShipmentService.BASE_URL}/public/geolocation/geocoding`;
    return apiService.get(url, { zipCode: zipCode, countryCode: DEFAULT_COUNTRY.value });
  }

  downloadLabel(shipmentId: number) {
    const url = `${ShipmentService.BASE_URL}/shipments/${shipmentId}/label`;
    return apiService.get(url, {}, {}, 'blob');
  }

  fetchShipments(orderId: number): AxiosPromise<ShipmentDto[]> {
    const url = `${ShipmentService.BASE_URL}/shipments`;
    return apiService.get(url, { orderId }, {});
  }
}

const shipmentService = new ShipmentService();

export { shipmentService, ShipmentService };
