import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Text1 } from 'components/typography/Texts';
import { getFilterByOrderStatus } from 'features/my-profile/my-activity/components/tracked-item/trackedItemUtils';
import { ConversationProvider } from 'providers/ConversationProvider';
import { PurchaseDto } from 'services/Order/orderService.dto';
import { PublicUserDto } from 'services/User/userService.dto';

import { BuyerTile } from './BuyerTile';
import { BuyerListOrderStatus, OrderStatusFilter } from './OrderStatusFilter';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const HeaderContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  gap: 12px;
`;

const TilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 500px;
  padding-right: 2px;
`;

interface Props {
  orders: PurchaseDto[];
  users: PublicUserDto[];
  isNationwideShippingAvailable: boolean;
  isLocalPickupAvailable: boolean;
  unitsSold: number;
  unitsQuantity: number;
  onClick: () => void;
}

export const ListOfSoldBuyersList = ({
  orders,
  users,
  isNationwideShippingAvailable,
  isLocalPickupAvailable,
  unitsQuantity,
  unitsSold,
  onClick,
}: Props) => {
  const { t } = useTranslation('myProfile');
  const [selectedFilterId, setSelectedFilterId] = useState<number>(1);

  const availableFilterOptions = useMemo(
    () => getFilterByOrderStatus(isNationwideShippingAvailable, isLocalPickupAvailable),
    [isNationwideShippingAvailable, isLocalPickupAvailable]
  );
  const handleFilterChange = (selectedOption: { value: number; label: string } | null) => {
    if (selectedOption) {
      setSelectedFilterId(selectedOption.value);
    }
  };

  const selectedFilterOption = availableFilterOptions.find(option => option.id === selectedFilterId);

  const filteredOrders =
    orders && selectedFilterOption
      ? orders.filter(order => selectedFilterOption.type.includes(order.status as BuyerListOrderStatus))
      : orders;

  const findUserById = (users: PublicUserDto[], buyerId: number) => {
    return users.find(user => user.id === buyerId);
  };

  const renderBuyerTiles = () => {
    if (orders && users)
      return filteredOrders.map(order => {
        const user = findUserById(users, order.buyerId);
        return <BuyerTile key={order.id} order={order} user={user!!} onClick={onClick} />;
      });
  };

  return (
    <ConversationProvider>
      <Container>
        <HeaderContainer>
          <Text1 data-testid={'sold-info'}>
            {t('tracked-item.multiple-list-sold-info', { unitsSold: unitsSold, unitsQuantity: unitsQuantity })}
          </Text1>
          <OrderStatusFilter
            availableFilterOptions={availableFilterOptions}
            selectedFilterId={selectedFilterId}
            handleChange={handleFilterChange}
          />
        </HeaderContainer>
        <TilesContainer>{renderBuyerTiles()}</TilesContainer>
      </Container>
    </ConversationProvider>
  );
};
