import { usePopup } from 'hooks/usePopup';

import SignUpPopup from './SignUpPopup';

export const useSignUpPopup = () => {
  const { showPopup } = usePopup();

  const showSignUpPopup = (onSuccess?: () => void) => showPopup(<SignUpPopup onSuccess={onSuccess} />);

  return { showSignUpPopup };
};
