import { ScreenContainer } from 'components/containers/ScreenContainer';
import { ConversationProvider } from 'providers/ConversationProvider';

import { MessengerPanel } from './components/MessengerPanel';

export const Messenger = () => {
  return (
    <ScreenContainer footerVariant={'None'}>
      <ConversationProvider>
        <MessengerPanel />
      </ConversationProvider>
    </ScreenContainer>
  );
};
