import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useMyActivityNavigate } from 'features/my-profile/my-activity/utils/useMyActivityNavigate';
import { CampaignStatsDto } from 'services/Campaign/campaignService.dto';
import { MyActivityBoughtSoldStatsDto, MyActivitySellingStatsDto } from 'services/Item/itemService.dto';

import { StatisticCircle } from './StatisticCircle';

const CircleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

interface UserStatisticsProps {
  sellingStats?: MyActivitySellingStatsDto;
  boughtSoldStats?: MyActivityBoughtSoldStatsDto;
  campaignStats?: CampaignStatsDto;
}

export const UserStatistics = ({ sellingStats, boughtSoldStats, campaignStats }: UserStatisticsProps) => {
  const { t } = useTranslation('myProfile');
  const { goToCampaigns, goToSelling, goToDonated, goToBought } = useMyActivityNavigate();

  return (
    <CircleContainer>
      <StatisticCircle
        title={t('my-activity.campaigns')}
        value={campaignStats?.activeCount}
        isLoading={!campaignStats}
        onClick={goToCampaigns}
        testId={'statistics-campaigns'}
      />
      <StatisticCircle
        title={t('my-activity.selling')}
        value={sellingStats?.sellingCount}
        isLoading={!sellingStats}
        onClick={goToSelling}
        testId={'statistics-selling'}
      />
      <StatisticCircle
        title={t('my-activity.donated')}
        value={boughtSoldStats?.soldCount}
        isLoading={!boughtSoldStats}
        onClick={goToDonated}
        testId={'statistics-sold'}
      />
      <StatisticCircle
        title={t('my-activity.bought')}
        value={boughtSoldStats?.boughtCount}
        isLoading={!boughtSoldStats}
        onClick={goToBought}
        testId={'statistics-bought'}
      />
    </CircleContainer>
  );
};
