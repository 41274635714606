import { AxiosPromise } from 'axios';

import { ItemStatsDto } from 'services/Item/itemService.dto';
import PageableType from 'services/pageableType';
import { apiService, apiUrl } from 'services/utils/apiService';

import {
  BoughtItemDto,
  MeetupPointDto,
  MySoldPublicStatsDto,
  NewPurchaseDto,
  PurchaseDto,
  PurchaseUpdateDto,
  SafetySpotDto,
  SoldItemDto,
} from './orderService.dto';

class OrderService {
  static BASE_URL = `${apiUrl}/api/order`;

  fetchItemsSoldCount(userId: number): AxiosPromise<MySoldPublicStatsDto> {
    const url = `${OrderService.BASE_URL}/public/user/items/sold/stats`;
    return apiService.get(url, { userId: userId }, {});
  }

  createPurchase(newPurchase: NewPurchaseDto): AxiosPromise<PurchaseDto> {
    const url = `${OrderService.BASE_URL}/purchases`;
    return apiService.post(url, newPurchase);
  }

  updatePurchase(id: number, purchaseUpdate: PurchaseUpdateDto): AxiosPromise<PurchaseDto> {
    const url = `${OrderService.BASE_URL}/purchases/${id}`;
    return apiService.patch(url, purchaseUpdate);
  }

  fetchPurchaseDetails(orderId: number): AxiosPromise<PurchaseDto> {
    const url = `${OrderService.BASE_URL}/purchases/${orderId}`;
    return apiService.get(url, {}, {});
  }

  markAsPickedUp(orderId: number) {
    const url = `${OrderService.BASE_URL}/purchases/${orderId}`;
    return apiService.patch(url, { status: 'PICKED_UP' });
  }

  fetchSafetySpot(
    centerLatitude: number,
    centerLongitude: number,
    latitudeDelta: number,
    longitudeDelta: number
  ): AxiosPromise<SafetySpotDto[]> {
    const url = `${OrderService.BASE_URL}/safety-spots`;
    return apiService.get(url, { centerLatitude, centerLongitude, latitudeDelta, longitudeDelta });
  }

  fetchMyItemsStats(): AxiosPromise<ItemStatsDto> {
    const url = `${OrderService.BASE_URL}/user/items/stats`;
    return apiService.get(url, {}, {});
  }

  fetchMyBoughtItems(page: number, size: number, sort?: string): AxiosPromise<PageableType<BoughtItemDto>> {
    const url = `${OrderService.BASE_URL}/user/items/bought`;
    return apiService.get(url, { page: page, size: size, sort: sort ? sort : 'boughtDate,desc' }, {});
  }

  fetchMySoldItems(page: number, size: number, sort?: string): AxiosPromise<PageableType<SoldItemDto>> {
    const url = `${OrderService.BASE_URL}/user/items/sold`;
    return apiService.get(url, { page: page, size: size, sort: sort ? sort : 'soldDate,desc' }, {});
  }

  proposeMeetupPoint(orderId: number, meetupPoint: MeetupPointDto) {
    const payload = {
      orderId: orderId,
      safetySpotId: meetupPoint.id,
    };
    return apiService.post(`${OrderService.BASE_URL}/meetup-points`, payload);
  }

  fetchMyPurchases(itemId: number): AxiosPromise<PurchaseDto[]> {
    const url = `${OrderService.BASE_URL}/my-purchases`;
    return apiService.get(url, { itemId: itemId }, {});
  }

  fetchMySellings(itemId: number): AxiosPromise<PurchaseDto[]> {
    const url = `${OrderService.BASE_URL}/my-sellings`;
    return apiService.get(url, { itemId: itemId }, {});
  }

  cancelPurchase(id: number) {
    const url = `${OrderService.BASE_URL}/purchases/${id}`;
    return apiService.patch(url, { status: 'CANCELED' });
  }

  requestForRefund(orderId: number, reason: string) {
    const url = `${OrderService.BASE_URL}/refund-requests`;
    return apiService.post(url, { orderId, reason });
  }
}

const orderService = new OrderService();

export { orderService, OrderService };
