import { useTranslation } from 'react-i18next';

import { InfoSection, InfoSectionWrapper, StyledTitle } from 'components/containers/InfoSection';

interface Props {
  testId?: string;
}

export const ObjectNotFound = ({ testId }: Props) => {
  const { t } = useTranslation('error');
  return (
    <InfoSectionWrapper data-testid={testId}>
      <InfoSection onSuccess={false} />
      <StyledTitle>{t('no-page.link-invalid')}</StyledTitle>
    </InfoSectionWrapper>
  );
};
