import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import WhiteButton from 'components/buttons/WhiteButton';
import { usePopup } from 'hooks/usePopup';
import { AuctionDto } from 'services/Item/itemService.dto';
import { userService } from 'services/User/userService';
import { PublicUserDto } from 'services/User/userService.dto';

import BiddersList from './BiddersList';
import BiddersPopup from './BiddersPopup';
import { convertToBidders, findBids } from './biddersUtils';

const Container = styled.div`
  width: 100%;
`;

const BiddersListWrapper = styled.div`
  margin: 15px 0 5px 0;
`;

interface Props {
  auction: AuctionDto;
  onBidPlaced: () => void;
  isAuctionOwner: boolean;
}

const PREVIEW_SIZE = 4;
const BiddersPreview = ({ auction, onBidPlaced, isAuctionOwner }: Props) => {
  const { bids, hasMore } = findBids(auction, PREVIEW_SIZE);
  const bidderIds = bids.map(it => it.userId);
  const [bidders, setBidders] = useState<PublicUserDto[]>([]);

  useEffect(() => {
    if (bidderIds.length > 0 && bidders.length === 0) {
      userService.fetchBulkPublicUsers(bidderIds).then(response => setBidders(response.data));
    }
  }, [bidderIds, bidders]);

  const { showPopup } = usePopup();
  const { t } = useTranslation('item');

  const onShowAllPress = () =>
    showPopup(<BiddersPopup isAuctionOwner={isAuctionOwner} auction={auction} onBidPlaced={onBidPlaced} />, {
      paddingVariant: 'REDUCED',
    });

  return (
    <Container>
      <BiddersListWrapper data-testid={'bidders-list'}>
        <BiddersList bidders={convertToBidders(bids, bidders, auction)} />
      </BiddersListWrapper>
      {hasMore && (
        <WhiteButton data-testid={'show-all-bidders-button'} onClick={onShowAllPress}>
          {t('bidders-show-all')}
        </WhiteButton>
      )}
    </Container>
  );
};

export default BiddersPreview;
