import { useTranslation } from 'react-i18next';

import ContactButton from 'components/buttons/ContactButton';
import { useContact } from 'features/messenger/hooks/useContact';

import { TrackedItemComponentsProps } from './trackedItemUtils';

export const ContactUserButton = ({ isBuyer, order, onClick }: TrackedItemComponentsProps) => {
  const { t } = useTranslation('myProfile');
  const ownerId = order?.ownerId;
  const buyerId = order?.buyerId;
  const interlocutorId = isBuyer ? ownerId : buyerId;

  const { contactOwner } = useContact('ITEM', interlocutorId, order?.itemId);

  const handleOnClick = () => {
    onClick?.();
    contactOwner();
  };

  return (
    <ContactButton onClick={handleOnClick} data-testid={'contact-owner'}>
      {isBuyer ? t('tracked-item.contact-seller') : t('tracked-item.contact-buyer')}
    </ContactButton>
  );
};
