import styled from 'styled-components';

import { theme } from 'assets/styles/theme';

import ButtonBase from './ButtonBase';

const InfoButton = styled(ButtonBase)`
  height: 100%;
  width: 195px;
  border: 1px solid ${theme.color.white};
  background-color: ${theme.color.blue};
  color: ${theme.color.white};
  cursor: pointer;

  :hover {
    opacity: 0.5;
  }
`;

export default InfoButton;
