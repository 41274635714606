import { ConversationProvider } from 'providers/ConversationProvider';

import ContactSellerSection, { Props } from './ContactSellerSection';

const ConversationAwareContactSellerSection = (props: Props) => {
  return (
    <ConversationProvider>
      <ContactSellerSection {...props} />
    </ConversationProvider>
  );
};

export default ConversationAwareContactSellerSection;
