import { useState } from 'react';
import { useCollapse } from 'react-collapsed';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import ButtonBase from 'components/buttons/ButtonBase';
import { Text1 } from 'components/typography/Texts';

import 'components/limitedText/limitedTextStyle.scss';

const Container = styled.div`
  margin: 0 auto;
  padding-bottom: 24px;

  ${theme.mq.tablet} {
    margin-top: 24px;
    padding-bottom: 0;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ToggleTextButton = styled(ButtonBase)`
  margin-top: 12px;
  background-color: ${theme.color.white};
  color: ${theme.color.red};
  border: none;

  ${theme.mq.tablet} {
    margin-bottom: 0;
  }
`;

const Description = styled(Text1)`
  white-space: pre-wrap;
  text-align: center;
`;

const BreakableWord = styled(Text1)`
  word-break: break-all;
`;

const TEXT_LIMIT = 350;
const LONG_WORD_LIMIT = 100;

interface Props {
  description?: string;
}

const formatDescription = (text: string, limit: number) => {
  return text.split(' ').map((word, index) => {
    if (word.length > limit) {
      return <BreakableWord key={index}>{word}</BreakableWord>;
    }
    return word + ' ';
  });
};

export const LimitedText = ({ description }: Props) => {
  const [isExpanded, setExpanded] = useState(false);
  const { getCollapseProps, getToggleProps } = useCollapse({
    isExpanded,
    collapsedHeight: 100,
  });

  const { t } = useTranslation('common');

  if (!description) return null;

  const formattedDescription = formatDescription(description, LONG_WORD_LIMIT);

  return (
    <Container data-testid={'limited-text'}>
      {description.length < TEXT_LIMIT ? (
        <Description>{description}</Description>
      ) : (
        <>
          <Description {...getCollapseProps()}>{formattedDescription}</Description>
          <ButtonsContainer className={!isExpanded ? 'textShadow' : ''}>
            <ToggleTextButton
              {...getToggleProps({
                onClick: () => setExpanded(prevExpanded => !prevExpanded),
              })}>
              {isExpanded ? t('read-less') : t('read-more')}
            </ToggleTextButton>
          </ButtonsContainer>
        </>
      )}
    </Container>
  );
};
