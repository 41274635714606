import styled from 'styled-components';

import { OpacityOverlay } from './OpacityOverlay';
import Spinner from './Spinner';

const StyledSpinner = styled(Spinner)`
  & img {
    width: 50px;
    height: 50px;
  }
`;

interface Props {
  testId?: string;
}

const SpinnerOverlay = ({ testId }: Props) => {
  return (
    <OpacityOverlay data-testid={testId}>
      <StyledSpinner />
    </OpacityOverlay>
  );
};

export default SpinnerOverlay;
