import styled from 'styled-components';

import BidderItem from './bidder/BidderItem';
import { BidderDto } from './bidderType';

const Container = styled.div`
  width: 100%;
  max-height: 400px;
`;

interface Props {
  bidders: BidderDto[];
}

const BiddersList = ({ bidders }: Props) => {
  const renderBidders = () => {
    if (bidders.length > 0) {
      const items = bidders.map(bidder => <BidderItem key={bidder.bid.id} bidder={bidder} />);
      return <>{items}</>;
    } else {
      return <></>;
    }
  };

  return <Container>{renderBidders()}</Container>;
};

export default BiddersList;
