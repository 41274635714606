import { ButtonHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

import ContactButton from 'components/buttons/ContactButton';

const ContactOwnerButton = (props: ButtonHTMLAttributes<HTMLButtonElement>) => {
  const { t } = useTranslation('user');

  return <ContactButton {...props}>{t('owner.contact-btn')}</ContactButton>;
};

export default ContactOwnerButton;
