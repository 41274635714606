import { useTranslation } from 'react-i18next';

import { useEmailCodeRetry } from 'features/account/resend-code/useEmailCodeRetry';
import { useAlertPopup } from 'hooks/useAlertPopup';

export const useLoginError = () => {
  const { showAlertPopup } = useAlertPopup();
  const { retryVerification } = useEmailCodeRetry();
  const { t } = useTranslation('account');

  const wrongPassword = () =>
    showAlertPopup({
      header: t('login.account-not-found-title'),
      description: t('login.account-not-found-message'),
      firstButton: { label: t('login.account-not-found-button') },
      testId: 'wrong-password-alert',
    });

  const lockedAccount = () =>
    showAlertPopup({
      header: t('login.locked-account-title'),
      description: t('login.locked-account-message'),
      firstButton: { label: t('login.locked-account-button') },
      testId: 'locked-account-alert',
    });

  const blockedAccount = () =>
    showAlertPopup({
      header: t('login.blocked-account-title'),
      description: t('login.blocked-account-message'),
      firstButton: { label: t('login.blocked-account-button') },
      testId: 'blocked-account-alert',
    });

  const blockedDevice = () =>
    showAlertPopup({
      header: t('login.blocked-device-title'),
      description: t('login.blocked-device-message'),
      firstButton: { label: t('login.blocked-device-button') },
      testId: 'blocked-device-alert',
    });

  const inactiveAccount = (email: string) =>
    showAlertPopup({
      header: t('login.inactive-account-title'),
      description: t('login.inactive-account-message', { email }),
      firstButton: { label: t('login.inactive-account-cancel-button') },
      secondButton: { label: t('login.inactive-account-resend-button'), onClick: () => retryVerification(email) },
      testId: 'inactive-account-alert',
    });

  const missingData = () =>
    showAlertPopup({
      header: t('login.missing-data-title'),
      description: t('login.missing-data-message'),
      firstButton: { label: t('login.missing-data-button') },
      testId: 'missing-data-alert',
    });

  return { wrongPassword, lockedAccount, blockedAccount, blockedDevice, inactiveAccount, missingData };
};
