import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import chatBubbleIcon from 'assets/svg/chat-bubble.svg';
import { H4 } from 'components/typography/Headers';

import ProfilePhotoWrapper from './ProfilePhotoWrapper';

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: center;
  align-items: center;

  ${theme.mq.phone} {
    flex-direction: column;
    gap: 12px;
  }
`;

const ChatBubbleWrapper = styled.div`
  position: relative;
`;

const ChatBubble = styled.img`
  width: 100%;
`;

const StyledTitle = styled(H4)`
  position: absolute;
  right: 10%;
  left: 10%;
  top: 50%;
  transform: translateY(-50%);
`;

const MessageLink = styled(Link)`
  color: ${theme.color.red};
  text-decoration: underline;
`;

interface Props {
  authorName: string;
  authorPhoto?: string;
  authorIsVip: boolean;
}

const MessageCloud = ({ authorName, authorPhoto, authorIsVip }: Props) => {
  const { t } = useTranslation('message');
  return (
    <Wrapper>
      <ProfilePhotoWrapper isVip={authorIsVip} photo={authorPhoto} />
      <ChatBubbleWrapper>
        <ChatBubble src={chatBubbleIcon} alt='' />
        <StyledTitle>
          {t('title', { author: authorName })}
          <MessageLink to={'/messenger'}>{t('subtitle')}</MessageLink>
        </StyledTitle>
      </ChatBubbleWrapper>
    </Wrapper>
  );
};

export default MessageCloud;
