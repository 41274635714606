import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import verifiedIcon from 'assets/svg/verified-icon.svg';
import { Text1 } from 'components/typography/Texts';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 24px;
`;

const SingleItem = styled.div`
  display: flex;
  gap: 12px;
`;

const VerificationIcon = styled.img`
  width: 24px;
  height: 24px;
`;

interface UserVerificationInfoProps {
  isVerifiedForPayments: boolean;
  isConfirmedPhone: boolean;
  isConfirmedFacebook: boolean;
}

export const UserVerificationInfo = ({
  isVerifiedForPayments,
  isConfirmedPhone,
  isConfirmedFacebook,
}: UserVerificationInfoProps) => {
  const { t } = useTranslation('myProfile');
  return (
    <Container>
      {isVerifiedForPayments && (
        <SingleItem data-testid={'bank-verified'}>
          <VerificationIcon src={verifiedIcon} alt={'verified icon'} />
          <Text1>{t('verification-info.payment-verified')}</Text1>
        </SingleItem>
      )}

      {isConfirmedPhone && (
        <SingleItem data-testid={'phone-confirmed'}>
          <VerificationIcon src={verifiedIcon} alt={'verified icon'} />
          <Text1>{t('verification-info.phone-verified')}</Text1>
        </SingleItem>
      )}

      {isConfirmedFacebook && (
        <SingleItem data-testid={'facebook-confirmed'}>
          <VerificationIcon src={verifiedIcon} alt={'verified icon'} />
          <Text1>{t('verification-info.facebook-confirmed')}</Text1>
        </SingleItem>
      )}
    </Container>
  );
};
