import { LoginStatus, useFacebook } from 'react-facebook';
import { googleLogout } from '@react-oauth/google';

import { AuthType } from 'providers/AuthProvider';

export const useSocialMediaCleanup = () => {
  const { api } = useFacebook();

  const cleanupSocialMedia = async (authType: AuthType) => {
    try {
      if (authType === 'Facebook') {
        const currentStatus = await api!.getLoginStatus();
        if (currentStatus.status === LoginStatus.CONNECTED) {
          await api!.logout();
        }
      } else if (authType === 'Google') {
        googleLogout();
      }
    } catch (error) {
      // intentionally ignored
    }
  };

  return { cleanupSocialMedia };
};
