import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import RedButton from 'components/buttons/RedButton';
import PasswordInputField from 'components/inputs/PasswordInputField';
import TextInputField from 'components/inputs/TextInputField';
import { H4 } from 'components/typography/Headers';
import PrivacyAndTerms from 'features/account/privacy/PrivacyAndTerms';
import { useSignUpForm } from 'features/account/sign-up/useSignUpForm';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled(H4)`
  margin-bottom: 46px;
`;

const TextInput = styled(TextInputField)`
  margin-bottom: 12px;
`;

const PasswordInput = styled(PasswordInputField)`
  margin-bottom: 12px;
`;

const SignupButton = styled(RedButton)`
  margin-bottom: 24px;
`;

interface Props {
  onSuccess?: () => void;
}

const SignUpPopup = ({ onSuccess }: Props) => {
  const { values, handleChange, submitForm, errors, isValid, isSubmitting, dirty } = useSignUpForm({ onSuccess });
  const { t } = useTranslation('account');

  return (
    <Container data-testid={'sign-up-popup'}>
      <Header data-testid={'header'}>{t('sign-up.header')}</Header>
      <TextInput
        testId={'first-name-field'}
        value={values.firstName}
        placeholder={t('sign-up.first-name-placeholder')}
        onChange={handleChange('firstName')}
        errorMessage={errors.firstName}
      />
      <TextInput
        testId={'last-name-field'}
        value={values.lastName}
        placeholder={t('sign-up.last-name-placeholder')}
        onChange={handleChange('lastName')}
        errorMessage={errors.lastName}
      />
      <TextInput
        testId={'email-field'}
        value={values.email}
        placeholder={t('sign-up.email-placeholder')}
        inputType={'email'}
        onChange={handleChange('email')}
        errorMessage={errors.email}
      />
      <PasswordInput
        testId={'password-field'}
        value={values.password}
        placeholder={t('sign-up.password-placeholder')}
        onChange={handleChange('password')}
        errorMessage={errors.password}
      />
      <PasswordInput
        testId={'password-confirmation-field'}
        value={values.passwordConfirmation}
        placeholder={t('sign-up.password-confirmation-placeholder')}
        onChange={handleChange('passwordConfirmation')}
        errorMessage={errors.passwordConfirmation}
      />
      <SignupButton
        data-testid={'sign-up-button'}
        onClick={submitForm}
        disabled={!dirty || !isValid || isSubmitting}
        type={'submit'}>
        {t('sign-up.sign-up-button')}
      </SignupButton>
      <PrivacyAndTerms />
    </Container>
  );
};

export default SignUpPopup;
