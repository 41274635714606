import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { Text1 } from 'components/typography/Texts';

const SummaryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
`;

const SummarySection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
`;

const SummaryValue = styled(Text1)`
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;

  ${theme.mq.phone} {
    font-size: 17px;
  }
`;

const SummaryDescription = styled(Text1)`
  color: ${theme.color.darkerGray2};
  padding: 0 10px;
  font-size: 17px;
  line-height: 22px;

  ${theme.mq.phone} {
    font-size: 14px;
  }
`;

interface Props {
  supporters: number;
  daysLeft?: number;
}

const CampaignSummary = (props: Props) => {
  const { supporters, daysLeft } = props;
  const { t } = useTranslation('campaign');

  return (
    <SummaryWrapper>
      <SummarySection data-testid={'campaign-summary-supporters-section'}>
        <SummaryValue>{`${supporters}`}</SummaryValue>
        <SummaryDescription>{t('supporters', { count: supporters })}</SummaryDescription>
      </SummarySection>

      {daysLeft !== undefined && (
        <SummarySection data-testid={'campaign-summary-days-left-section'}>
          <SummaryValue>{`${daysLeft}`}</SummaryValue>
          <SummaryDescription>{t('days-left', { count: daysLeft })}</SummaryDescription>
        </SummarySection>
      )}
    </SummaryWrapper>
  );
};

export default CampaignSummary;
