import { AxiosPromise } from 'axios';

import { apiService, apiUrl } from 'services/utils/apiService';

import { AppAreaType, DeepLinkDto, PhoneDto } from './notificationService.dto';

class NotificationService {
  static BASE_URL = `${apiUrl}/api/notification`;

  sendAppLink(phone: PhoneDto, responseToken: string, appArea?: string, appAreaObjectId?: number): AxiosPromise {
    const url = `${NotificationService.BASE_URL}/public/app-link`;
    return apiService.post(url, {
      phone,
      appArea: appArea ? appArea : null,
      appAreaObjectId: appAreaObjectId ? appAreaObjectId : null,
      captchaResponseToken: responseToken,
    });
  }

  generateDeepLink(appArea?: AppAreaType, appAreaObjectId?: number): AxiosPromise<DeepLinkDto> {
    const url = `${NotificationService.BASE_URL}/public/deep-links`;
    return apiService.post(url, {
      appArea: appArea ? appArea : 'MARKETPLACE',
      appAreaObjectId: appAreaObjectId ? appAreaObjectId : null,
    });
  }

  unsubscribeEmail(userId: number, token: string): AxiosPromise {
    const url = `${NotificationService.BASE_URL}/public/communication-channels/unsubscribe`;
    return apiService.patch(url, {
      userId,
      token,
    });
  }

  markMeetupAsNo(orderId: number) {
    const url = `${NotificationService.BASE_URL}/order/${orderId}/meeting-answer-no`;
    return apiService.post(url);
  }
}

const notificationService = new NotificationService();

export { notificationService, NotificationService };
