import { usePopup } from 'hooks/usePopup';
import { ItemDto } from 'services/Item/itemService.dto';

import { ListOfSold } from './ListOfSold';

interface Props {
  item: ItemDto;
  onClose?: () => void;
}

export const useListOfSoldPopup = () => {
  const { showPopup, hidePopup } = usePopup();
  const showListOfSoldPopup = ({ item, onClose }: Props) => {
    showPopup(<ListOfSold item={item} onClick={hidePopup} />, { onClose });
  };

  return { showListOfSoldPopup };
};
