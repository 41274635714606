import { Marker } from '@react-google-maps/api';

import MapLocationSource from 'assets/svg/map-location.svg';

interface Props {
  position: { lat: number; lng: number };
}

const LocationMarker = ({ position }: Props) => {
  return (
    <Marker
      data-testid={'location-marker'}
      options={{ icon: MapLocationSource, anchorPoint: new google.maps.Point(0.5, 0.5) }}
      position={position}
    />
  );
};

export default LocationMarker;
