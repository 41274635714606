import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import ArrowButton from 'components/buttons/ArrowButton';
import { H5 } from 'components/typography/Headers';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 48px;
  align-items: center;

  ${theme.mq.huge} {
    margin-bottom: 24px;
  }

  ${theme.mq.phone} {
    margin-bottom: 12px;
  }
`;

const ButtonsWrapper = styled.div`
  width: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

interface Props {
  header: string;
  prevEnabled: boolean;
  nextEnabled: boolean;
  displayButtons: boolean;
  onPrevPress: () => void;
  onNextPress: () => void;
}

const NavigationHeader = ({ header, prevEnabled, nextEnabled, displayButtons, onPrevPress, onNextPress }: Props) => {
  return (
    <Container data-testid={'item-slider'}>
      <H5 data-testid={'item-slider-header'}>{header}</H5>
      {displayButtons && (
        <ButtonsWrapper>
          <ArrowButton testId={'item-slider-left'} enabled={prevEnabled} direction={'left'} onPress={onPrevPress} />
          <ArrowButton testId={'item-slider-right'} enabled={nextEnabled} direction={'right'} onPress={onNextPress} />
        </ButtonsWrapper>
      )}
    </Container>
  );
};

export default NavigationHeader;
