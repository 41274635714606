import { useState } from 'react';

const resolveFullScreenProps = (isFullScreen: boolean, setIsFullScreen: (isFullScreen: boolean) => void) => {
  return {
    isFullScreen,
    setFullScreen: () => setIsFullScreen(true),
    unsetFullScreen: () => setIsFullScreen(false),
  };
};

export const useFullScreen = () => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  return resolveFullScreenProps(isFullScreen, setIsFullScreen);
};
