import { createContext, FC, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SockJS from 'sockjs-client';
import Stomp, { Client, Frame, Message } from 'stompjs';
import { ConversationActionTypes, ConversationsState } from 'store/reducers/conversations.reducer';
import { MessageActionTypes, MessagesState } from 'store/reducers/messages.reducer';

import { toLastMessage } from 'services/Message/lastMessageConverter';
import { messageService } from 'services/Message/messageService';
import {
  InternalMessageDto,
  MessageDto,
  MessageInternalStatus,
  MessageType,
} from 'services/Message/messageService.dto';
import { getAuthToken } from 'utils/localStorageData';

import { useAuth } from './AuthProvider';
import { useMessaging } from './MessagingStateProvider';
import { useNotification } from './NotificationProvider';
import {
  MessagePayloadType,
  RECONNECT_TIMEOUT_MS,
  STOMP_DESTINATION_URL,
  WEB_SOCKET_URL,
} from './utils/webSocketUtils';

interface MessageContextType {
  unreadMessageCount: number;
  fetchUnreadMessageCount: () => void;
  resetUnreadMessageCount: () => void;
  // TODO: cleanup if unused IGA-12224
  addMessageFromNotification: (message: MessageDto) => void;
  getConversationMessages: (conversationId?: number) => InternalMessageDto[];
  confirmMessagesReceived: (messageIds: number[]) => void;
  isLoading: (conversationId: number) => boolean;
}

export const MessageContext = createContext<MessageContextType>(null!);

interface UnreadMessageCountState {
  messageCount: number;
  isLoading: boolean;
}

const initialState: UnreadMessageCountState = {
  messageCount: 0,
  isLoading: true,
};

const MessageProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { logged } = useAuth();
  const { showNotification, createInfoNotificationFromPayload } = useNotification();
  const navigate = useNavigate();
  const { state, dispatchMessages, dispatchConversations } = useMessaging();
  const [unreadMessageCount, setUnreadMessageCount] = useState<UnreadMessageCountState>(initialState);
  const [webSocketConnecting, setWebSocketConnecting] = useState(false);
  const [stompClient, setStompClient] = useState<Client>();
  const conversationsState = useRef<ConversationsState>(state.conversationsState);
  const messagesState = useRef<MessagesState>(state.messagesState);

  useEffect(() => {
    conversationsState.current = state.conversationsState;
    messagesState.current = state.messagesState;
  }, [state]);

  useEffect(() => {
    if (unreadMessageCount.isLoading && logged) {
      messageService
        .fetchUnreadMessagesCount()
        .then(response => response.data)
        .then(unreadMessageCountDto =>
          setUnreadMessageCount({ messageCount: unreadMessageCountDto.messagesCount, isLoading: false })
        )
        .catch(e => setUnreadMessageCount({ ...unreadMessageCount, isLoading: false }));
    }
  }, [unreadMessageCount.isLoading, logged]);

  const fetchUnreadMessageCount = () => {
    setUnreadMessageCount({ ...unreadMessageCount, isLoading: true });
  };

  const resetUnreadMessageCount = () => {
    setUnreadMessageCount(initialState);
  };

  const getConversationMessages = (conversationId?: number): InternalMessageDto[] => {
    if (!conversationId) return [];
    const conversationMessages: InternalMessageDto[] = messagesState.current.messages
      .filter(it => it.conversationId === conversationId)
      .flatMap(it => it.messageContent);
    return conversationMessages.filter(it => it.internalStatus !== MessageInternalStatus.ERROR);
  };

  const addMessageSuccess = (message: MessageDto) => {
    const conversationId = message.conversationId;
    const existingConversation = conversationsState.current.conversations.find(
      it => it.conversation.conversationId === conversationId
    );
    if (!existingConversation) {
      messageService
        .fetchConversation(conversationId)
        .then(response => response.data)
        .then(conversation => {
          dispatchConversations({ type: ConversationActionTypes.CONVERSATION_ADD, payload: { conversation } });
        });
    } else {
      dispatchConversations({
        type: ConversationActionTypes.CONVERSATION_LAST_MESSAGE_UPDATE,
        payload: toLastMessage(message),
      });
    }
    dispatchMessages({
      type: MessageActionTypes.MESSAGES_ADD_MESSAGE_SUCCESS,
      payload: { conversationId: message.conversationId, message },
    });
    if (conversationsState.current.currentConversationId === message.conversationId) {
      confirmMessageReadAndReceived(message.conversationId, message.id!);
    }
  };

  const connectCallback = (frame?: Frame) => {
    setWebSocketConnecting(false);
    if (!!stompClient) stompClient.subscribe(STOMP_DESTINATION_URL, messageCallback);
    dispatchConversations({ type: ConversationActionTypes.CONVERSATIONS_RESET });
    resetUnreadMessageCount();
  };

  const errorCallback = (frame: string | Frame) => {
    setWebSocketConnecting(false);
    if (!isConnectionOpen()) {
      // try to reconnect if error occurred
      setTimeout(() => setWebSocketConnecting(true), RECONNECT_TIMEOUT_MS);
    }
  };

  const isConnectionOpen = () => {
    return (!!stompClient && stompClient.connected) || webSocketConnecting;
  };

  useEffect(() => {
    if (webSocketConnecting) {
      const socket = new SockJS(WEB_SOCKET_URL);
      setStompClient(Stomp.over(socket));
    }
  }, [webSocketConnecting]);

  useEffect(() => {
    if (!!stompClient) {
      const authToken = getAuthToken();
      stompClient.connect({ token: authToken }, connectCallback, errorCallback);
    }
  }, [stompClient]);

  useEffect(() => {
    if (logged) {
      if (!isConnectionOpen()) {
        setWebSocketConnecting(true);
        resetUnreadMessageCount();
      }
    } else {
      closeWebSocket();
      setUnreadMessageCount({ ...initialState, isLoading: false });
    }
  }, [logged]);

  const isLoading = (conversationId: number) => {
    const conversationMessages = messagesState.current.messages.filter(it => it.conversationId === conversationId);
    if (!conversationMessages || conversationMessages.length === 0) return false;
    return conversationMessages[0].isLoading;
  };

  const closeWebSocket = () => {
    stompClient?.ws.close();
    setStompClient(undefined);
    setWebSocketConnecting(false);
  };

  const confirmMessageReadAndReceived = (conversationId: number, messageId: number) => {
    const messageIds = [messageId];
    messageService
      .confirmMessages(messageIds)
      .then(() => confirmMessagesReceived(messageIds))
      .then(() => resetUnreadMessageCount());
    dispatchConversations({ type: ConversationActionTypes.CONVERSATION_READ, payload: { conversationId } });
  };

  const confirmMessagesReceived = (messageIds: number[]) => {
    dispatchMessages({ type: MessageActionTypes.MESSAGES_CONFIRM_MESSAGES, payload: { messageIds: messageIds } });
  };

  const messageCallback = (message: Message) => {
    const payload = JSON.parse(message.body);
    if (payload.type === MessagePayloadType.MESSAGE) {
      processMessagePayload(payload);
    } else if (payload.type === MessagePayloadType.CONFIRMATION) {
      confirmMessagesReceived(payload.content);
    }
  };

  const processMessagePayload = (payload: { content: MessageDto }) => {
    const message = payload.content;
    addMessageSuccess(message);
    resetUnreadMessageCount();
    displayMessageAlert(message);
  };

  const messageAlertCallback = (message: MessageDto) => {
    return () => {
      navigate('/messenger/' + message.conversationId);
    };
  };

  const displayMessageAlert = (message: MessageDto) => {
    if (message.type === MessageType.NOTIFICATION) {
      showNotification(
        createInfoNotificationFromPayload(
          message.payload.type,
          message.payload.attributes,
          messageAlertCallback(message)
        )
      );
    } else if (message.type === MessageType.TEXT) {
      showNotification(
        createInfoNotificationFromPayload(
          'PRIVATE_MESSAGE_TEXT',
          { content: message.content, author: message.authorName },
          messageAlertCallback(message)
        )
      );
    } else if (message.type === MessageType.PHOTO) {
      showNotification(
        createInfoNotificationFromPayload(
          'PRIVATE_MESSAGE_PHOTO',
          { author: message.authorName },
          messageAlertCallback(message)
        )
      );
    }
  };

  return (
    <MessageContext.Provider
      value={{
        unreadMessageCount: unreadMessageCount.messageCount,
        fetchUnreadMessageCount,
        resetUnreadMessageCount,
        getConversationMessages,
        addMessageFromNotification: addMessageSuccess,
        confirmMessagesReceived,
        isLoading,
      }}>
      {children}
    </MessageContext.Provider>
  );
};

const useMessages = () => useContext(MessageContext);
export { MessageProvider, useMessages };
