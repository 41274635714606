import { useEffect, useState } from 'react';

import { itemService } from 'services/Item/itemService';
import { ItemWithAuctionDto, withAuctionsForBulkItems } from 'services/Item/withAuctionsData';

import { searchService } from './searchService';
import { SearchMarketplaceItemsParams } from './searchService.dto';
import { sortByIds } from './searchSorting';

const useSearchItems = (searchParams: SearchMarketplaceItemsParams) => {
  const [items, setItems] = useState<ItemWithAuctionDto[]>([]);

  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(true);

  useEffect(() => {
    if (page === 0) {
      fetchNextPage();
    }
  }, [page]);

  const loadData = async () => {
    const searchResult = await searchService.searchItems({ ...searchParams, page });

    const { last, content } = searchResult.data;
    const ids = content?.map(item => item.id);

    if (!!ids && ids.length > 0) {
      const itemDetails = await withAuctionsForBulkItems(() => itemService.fetchBulkItemsDetails(ids));
      const sortedItemDetails = sortByIds(ids, itemDetails.data);

      return { data: sortedItemDetails, last };
    } else {
      return { data: [], last };
    }
  };

  const fetchNextPage = async () => {
    setLoading(true);

    try {
      const { data, last } = await loadData();

      setItems(current => [...current, ...data]);
      setPage(current => current + 1);
      setHasNextPage(!last);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const refresh = () => {
    setPage(0);
    setItems([]);
    setLoading(false);
    setError(false);
    setHasNextPage(true);
  };

  return { fetchNextPage, items, hasNextPage, refresh, loading, error };
};

export { useSearchItems };
