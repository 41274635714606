import { useEffect, useState } from 'react';

import { itemService } from './itemService';
import { MyActivitySellingStatsDto } from './itemService.dto';

const useSellingStats = () => {
  const [myActivitySellingStats, setMyActivitySellingStats] = useState<MyActivitySellingStatsDto>();

  const refreshSellingStats = async () => {
    const statsResponse = await itemService.fetchMyItemsStats();
    setMyActivitySellingStats(statsResponse.data);
  };

  useEffect(() => {
    refreshSellingStats();
  }, []);

  return { myActivitySellingStats, refreshSellingStats };
};

export default useSellingStats;
