import useCampaignStats from 'services/Campaign/useCampaignStats';
import useSellingStats from 'services/Item/useSellingStats';
import useBoughtSoldStats from 'services/Order/useBoughtSoldStats';

export const useMyActivityStats = () => {
  const { myActivitySellingStats, refreshSellingStats: refreshMyActivitySellingStats } = useSellingStats();

  const { myActivityBoughtSoldStats, refreshBoughtSoldStats: refreshMyActivityBoughtSoldStats } = useBoughtSoldStats();

  const { campaignStats, refreshCampaignStats } = useCampaignStats();

  const refresh = () => {
    refreshMyActivitySellingStats();
    refreshMyActivityBoughtSoldStats();
    refreshCampaignStats();
  };

  return {
    myActivitySellingStats,
    myActivityBoughtSoldStats,
    campaignStats,
    refresh,
  };
};
