import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { H6 } from 'components/typography/Headers';
import { Text0, Text1 } from 'components/typography/Texts';
import SpinnerOverlay from 'components/ui-elements/SpinnerOverlay';
import { DeliveryType } from 'features/checkout/delivery/deliveryType';
import { CheckoutStatus } from 'features/checkout/item/checkoutStatus';
import { CheckoutResult } from 'features/checkout/payment/checkoutResult.types';
import { PaymentTokenData } from 'features/checkout/social-media/socialMedia.types';
import { PurchaseDto } from 'services/Order/orderService.dto';
import { formatMoneyDecimal, formatMoneyInteger } from 'utils/numberFormatter';

import FormSeparator from './FormSeparator';
import PaymentButtons from './PaymentButtons';
import PrivacyAndTerms from './PrivacyAndTerms';
import { calculateServiceFee } from './serviceFee';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${theme.color.gray};
  padding: 42px;
  gap: 15px;

  ${theme.mq.phone} {
    padding: 25px;
  }
`;

const Header = styled(H6)`
  margin-bottom: 5px;
`;

const SummaryLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const SummaryType = styled(Text1)`
  color: ${theme.color.darkerGray2};
`;

interface Props {
  deliveryType: DeliveryType;
  isProcessing: boolean;
  checkoutCardStatus: CheckoutStatus;
  checkoutTokenStatus: CheckoutStatus;
  purchase?: PurchaseDto;
  confirmCheckoutWithCard: () => void;
  confirmCheckoutWithToken: (data: PaymentTokenData) => Promise<CheckoutResult>;
}

const OrderSummary = (props: Props) => {
  const { deliveryType, isProcessing, purchase, ...buttonProps } = props;

  const { t } = useTranslation('checkout');

  return (
    <>
      <Container data-testid={'order-summary'}>
        <SummaryLine>
          <Header data-testid={'header'}>{t('summary.header')}</Header>
        </SummaryLine>

        <SummaryLine>
          <SummaryType data-testid={'base-price-description'}>{t('summary.item-price')}</SummaryType>
          <Text0 data-testid={'base-price'}>{purchase ? formatMoneyInteger(purchase.basePrice) : ''}</Text0>
        </SummaryLine>

        {deliveryType === 'Shipment' && (
          <SummaryLine>
            <SummaryType data-testid={'shipment-price-description'}>{t('summary.shipping')}</SummaryType>
            <Text0 data-testid={'shipment-price'}>{purchase ? formatMoneyDecimal(purchase.shipmentPrice) : ''}</Text0>
          </SummaryLine>
        )}

        <SummaryLine>
          <SummaryType data-testid={'service-fee-description'}>{t('summary.service-fee')}</SummaryType>
          <Text0 data-testid={'service-fee'}>{purchase ? formatMoneyDecimal(calculateServiceFee(purchase)) : ''}</Text0>
        </SummaryLine>

        <FormSeparator />

        <SummaryLine>
          <Header data-testid={'total-price-description'}>{t('summary.total')}</Header>
          <Text0 data-testid={'total-price'}>{purchase ? formatMoneyDecimal(purchase.amountTotal) : ''}</Text0>
        </SummaryLine>

        <PaymentButtons purchase={purchase} {...buttonProps} />

        <PrivacyAndTerms />
      </Container>

      {isProcessing && <SpinnerOverlay testId={'spinner-overlay'} />}
    </>
  );
};

export default OrderSummary;
