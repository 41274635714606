import { useNavigate } from 'react-router-dom';

import { useLoginPopup } from 'features/account/login/useLoginPopup';
import { useSocialMediaCleanup } from 'features/account/social-media/useSocialMediaCleanup';
import { useGlobalError } from 'hooks/useGlobalError';
import { useAuth } from 'providers/AuthProvider';
import { authService } from 'services/Auth/authService';
import { getAuthToken, getAuthType, getLastPublicRoute } from 'utils/localStorageData';

export const useLogout = () => {
  const { appLogout } = useAuth();
  const { unknownError } = useGlobalError();
  const { showLoginPopup } = useLoginPopup();
  const { cleanupSocialMedia } = useSocialMediaCleanup();

  const navigate = useNavigate();

  const logout = async () => {
    const authToken = getAuthToken();
    const authProvider = getAuthType();

    if (authToken) {
      try {
        await authService.logout(authToken);
        appLogout();

        navigate(getLastPublicRoute() || '/');
        showLoginPopup();

        await cleanupSocialMedia(authProvider);
      } catch (error) {
        unknownError();
      }
    }
  };

  return { logout };
};
