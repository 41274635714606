import { PropsWithChildren } from 'react';
import styled from 'styled-components';

import CopyrightFooter from 'components/nav/CopyrightFooter';
import DefaultHeader from 'components/nav/DefaultHeader';
import LogoOnlyHeader from 'components/nav/LogoOnlyHeader';
import { FooterVariant, HeaderVariant } from 'components/nav/navigationVariant';
import { NotificationContainer } from 'features/notifications/NotificationContainer';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  margin: 0 auto;
  width: 100%;
`;

interface Props extends PropsWithChildren {
  headerVariant?: HeaderVariant;
  footerVariant?: FooterVariant;
}

export const ScreenContainer = ({ headerVariant = 'Default', footerVariant = 'Default', children }: Props) => {
  const isOnlyLogo = () => {
    return headerVariant === 'LogoOnly' || headerVariant === 'LogoAndReturnOnly';
  };
  return (
    <Wrapper>
      {headerVariant === 'Default' && <DefaultHeader />}
      {headerVariant === 'LogoOnly' && <LogoOnlyHeader isOnlyLogo={isOnlyLogo()} showBackButton={false} />}
      {headerVariant === 'LogoAndReturnOnly' && <LogoOnlyHeader isOnlyLogo={isOnlyLogo()} showBackButton={true} />}
      <NotificationContainer isOnlyLogoHeader={isOnlyLogo()} />
      <Container>{children}</Container>
      {footerVariant === 'Copyright' && <CopyrightFooter />}
    </Wrapper>
  );
};
