import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import RadioButton from 'components/buttons/RadioButton';
import { Text2, Text2Bold } from 'components/typography/Texts';
import { UserShippingAddressDto } from 'services/User/userService.dto';

const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;

  ${theme.mq.phone} {
    align-items: flex-start;
  }
`;

const StyledRadioButton = styled(RadioButton)`
  margin-right: 10px;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;

  ${theme.mq.phone} {
    flex-direction: column;
    gap: 0;
  }
`;

const StyledText = styled(Text2)`
  color: ${theme.color.darkerGray};
`;

interface Props {
  shippingAddress: UserShippingAddressDto;
  isSelected: boolean;
  onSelected: () => void;
}

export const ShippingAddressDetails = ({ shippingAddress, isSelected, onSelected }: Props) => {
  return (
    <Container data-testid={'shipping-address-details'}>
      <StyledRadioButton checked={isSelected} onChange={onSelected} />
      <DetailsContainer>
        <Text2Bold>
          {shippingAddress.firstName} {shippingAddress.lastName}
        </Text2Bold>
        <StyledText>
          {shippingAddress.addressLine1}
          {shippingAddress.addressLine2 ? ` ${shippingAddress.addressLine2}` : ''},
        </StyledText>
        <StyledText>
          {shippingAddress.city}, {shippingAddress.state} {shippingAddress.postCode}
        </StyledText>
      </DetailsContainer>
    </Container>
  );
};
