import { ConversationProvider } from 'providers/ConversationProvider';

import { Owner, OwnerProps } from './Owner';

const ConversationAwareOwner = (props: OwnerProps) => {
  return (
    <ConversationProvider>
      <Owner {...props} />
    </ConversationProvider>
  );
};

export default ConversationAwareOwner;
