import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import RedButton from 'components/buttons/RedButton';
import PinInputField from 'components/inputs/PinInputField';
import { H4 } from 'components/typography/Headers';
import { Text1 } from 'components/typography/Texts';
import ResendSection from 'features/account/resend-code/ResendSection';
import { useVerifyEmail } from 'features/account/verify-email/useVerifyEmail';

const Container = styled.div`
  max-width: 415px;
  display: flex;
  flex-direction: column;
`;

const Header = styled(H4)`
  margin-bottom: 18px;
`;

const Description = styled(Text1)`
  margin-bottom: 30px;
`;

const PinInput = styled(PinInputField)`
  margin-bottom: 30px;
  align-self: center;
`;

const ConfirmButton = styled(RedButton)`
  margin-bottom: 24px;
`;

interface Props {
  credentials: { email: string; password?: string };
  onConfirmed: () => void;
}

const VerifyEmailPopup = ({ credentials, onConfirmed }: Props) => {
  const { t } = useTranslation('account');
  const { status, verifyEmail, resendCode, confirmVerification } = useVerifyEmail(credentials, onConfirmed);

  return (
    <Container data-testid={'verify-email-popup'}>
      <Header data-testid={'header'}>{t('verify-email.header')}</Header>
      <Description data-testid={'description'}>
        {t('verify-email.description', { email: credentials.email })}
      </Description>
      <PinInput
        testId={'pin-field'}
        onComplete={verifyEmail}
        disabled={status === 'inProgress' || status === 'completed'}
        successMessage={status === 'completed' ? t('verify-email.code-correct') : ''}
        errorMessage={status === 'failed' ? t('verify-email.code-incorrect') : ''}
      />
      <ConfirmButton
        data-testid={'confirm-button'}
        disabled={status !== 'completed'}
        onClick={confirmVerification}
        type={'submit'}>
        {t('verify-email.confirm-button')}
      </ConfirmButton>
      {status !== 'completed' && <ResendSection onResendClick={resendCode} />}
    </Container>
  );
};

export default VerifyEmailPopup;
