import { ButtonHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

import ContactButton from 'components/buttons/ContactButton';

const ContactSellerButton = (props: ButtonHTMLAttributes<HTMLButtonElement>) => {
  const { t } = useTranslation('checkout');

  return (
    <ContactButton data-testid={'contact-seller-button'} {...props}>
      {t('success.contact-seller-button')}
    </ContactButton>
  );
};

export default ContactSellerButton;
