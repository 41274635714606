import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { LoadingIndicator } from 'components/ui-elements/LoadingIndicator';
import { useConversationSelect } from 'features/messenger/hooks/useConversationSelect';
import { useConversations } from 'providers/ConversationProvider';
import { SafetySpotsProvider } from 'providers/SafetySpotProvider';

import { ConversationsList } from './ConversationsList';
import { MessageChat } from './MessageChat';
import MessageLocationMap from './MessageLocationMap';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  max-width: ${theme.maxWidthContainer}px;
  width: 100%;
  height: calc(100vh - 105px);
  margin: 0 auto;

  ${theme.mq.desktop} {
    height: calc(100vh - 165px);
  }
  ${theme.mq.phone} {
    height: calc(100vh - 125px);
  }
`;

const ConversationListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
  width: 20px;
  height: 20px;
  align-self: center;
  margin-top: 8px;
`;

export const MessengerPanel = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { setConversationId, conversationMessages, selectedConversation, currentConversations } =
    useConversationSelect();

  const { currentConversationId, fetchConversations, isLoading: isConversationListLoading } = useConversations();

  const [isConversationListBottom, setIsConversationListBottom] = useState(false);

  useEffect(() => {
    if (isConversationListBottom && !isConversationListLoading) {
      fetchConversations();
    }
  }, [isConversationListBottom]);

  const handleScroll = (e: any) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    const isBottom = scrollHeight - scrollTop === clientHeight;
    setIsConversationListBottom(isBottom);
  };

  return (
    <Container>
      <ConversationListContainer>
        <ConversationsList
          onScroll={handleScroll}
          conversations={currentConversations}
          onConversationClick={setConversationId}
          selectedId={currentConversationId}
        />
        {isConversationListBottom && isConversationListLoading && <StyledLoadingIndicator />}
      </ConversationListContainer>
      {!searchParams.has('meetupOrderId') && (
        <MessageChat conversation={selectedConversation!!} messages={conversationMessages} />
      )}
      {searchParams.has('meetupOrderId') && (
        <SafetySpotsProvider>
          <MessageLocationMap
            perspective={searchParams.get('perspective')}
            onClose={() => setSearchParams()}
            orderId={Number(searchParams.get('meetupOrderId'))}
          />
        </SafetySpotsProvider>
      )}
    </Container>
  );
};
