import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { PURCHASE_ACTIONS } from 'assets/constants/purchaseActions';
import { theme } from 'assets/styles/theme';
import EditIcon from 'assets/svg/edit-icon.svg';
import { H6 } from 'components/typography/Headers';
import { Text2Bold } from 'components/typography/Texts';

import { TrackedItemComponentsProps } from './trackedItemUtils';

const AddressChangeContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const EditContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
`;

const EditText = styled(Text2Bold)`
  color: ${theme.color.red};
`;

const ChangeAddressText = styled(H6)`
  font-weight: bold;
`;

export const AddressChange = ({ order, onClick }: TrackedItemComponentsProps) => {
  const { t } = useTranslation('myProfile');
  const navigate = useNavigate();
  const isShippable = order?.shipmentRequired;
  const status = order?.status;

  const canAddressChange = () => {
    return isShippable && status && PURCHASE_ACTIONS[status].canAddressChange;
  };

  const onEditClick = () => {
    onClick?.();
    navigate('/my-profile/shipping-address', {
      state: { from: 'TrackedItem' },
    });
  };

  if (!canAddressChange()) return null;
  return (
    <AddressChangeContainer>
      <ChangeAddressText data-testid={'address-header'}>{t('tracked-item.my-address')}</ChangeAddressText>
      <EditContainer data-testid={'edit-btn'} onClick={() => onEditClick()}>
        <img src={EditIcon} alt={'edit icon'} />
        <EditText>{t('tracked-item.edit')}</EditText>
      </EditContainer>
    </AddressChangeContainer>
  );
};
