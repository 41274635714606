import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import WhiteButton from 'components/buttons/WhiteButton';
import { H4 } from 'components/typography/Headers';
import { Text1 } from 'components/typography/Texts';
import { useGlobalData } from 'providers/GlobalDataProvider';
import { useItemData } from 'services/Item/useItemData';
import { orderService } from 'services/Order/orderService';
import { PurchaseDto } from 'services/Order/orderService.dto';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 24px;
  flex: 1;
`;

interface Props {
  order: PurchaseDto;
  hidePopup: () => void;
  onInvalidRequest: () => void;
  onOrderCancelled: () => void;
}

export const CancelOrder = ({ order, hidePopup, onInvalidRequest, onOrderCancelled }: Props) => {
  const { t } = useTranslation('myProfile');
  const { currentUser } = useGlobalData();
  const navigate = useNavigate();
  const { content: item } = useItemData(Number(order.itemId));

  const isMultipleItem = item?.sellingMethod === 'MULTIPLE';
  const isBuyer = order.buyerId === currentUser?.id;

  const cancelOrder = () => {
    orderService
      .cancelPurchase(order.id)
      .then(() => {
        hidePopup();
        onOrderCancelled();
        if (!isMultipleItem || (isMultipleItem && isBuyer)) {
          navigate('/my-profile/my-activity');
        }
      })
      .catch(onInvalidRequest);
  };

  return (
    <Container data-testid={'cancel-order-popup'}>
      <H4 data-testid={'header'}>{t('tracked-item.cancel')}</H4>
      <Text1 data-testid={'title'}>{t('tracked-item.cancel-title')}</Text1>
      <ButtonsContainer>
        <WhiteButton data-testid={'yes-btn'} onClick={() => cancelOrder()}>
          {t('tracked-item.cancel-yes')}
        </WhiteButton>
        <WhiteButton data-testid={'no-btn'} onClick={hidePopup}>
          {t('tracked-item.cancel-no')}
        </WhiteButton>
      </ButtonsContainer>
    </Container>
  );
};
