import { AxiosPromise } from 'axios';

import { itemService } from './itemService';
import { AuctionDto, ItemDto } from './itemService.dto';

export interface ItemWithAuctionDto extends ItemDto {
  auction?: AuctionDto;
}

type BulkProvider = () => AxiosPromise<ItemDto[]>;
type BulkResult = AxiosPromise<ItemWithAuctionDto[]>;

type AuctionResult = Promise<{ data: AuctionDto[] }>;

export const withAuctionsForBulkItems = async (provider: BulkProvider): BulkResult => {
  const response = await provider();
  const { data: items } = response;
  const { data: auctions } = await fetchAuctions(items);

  const newData = items.map(item => ({ ...item, auction: auctions.find(auction => auction.itemId === item.id) }));

  return { ...response, data: newData };
};

const fetchAuctions = async (itemData: ItemDto[]): AuctionResult => {
  const auctionItemIds = itemData.filter(item => item.sellingMethod === 'AUCTION').map(item => item.id);
  if (auctionItemIds.length > 0) {
    return await itemService.fetchBulkAuctions(auctionItemIds);
  } else {
    return { data: [] };
  }
};
