import styled from 'styled-components';

import { Text1 } from 'components/typography/Texts';
import { formatMobilePhone } from 'utils/phoneFormatter';

import { TrackedItemComponentsProps } from './trackedItemUtils';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const AddressDetails = ({ isBuyer, order }: TrackedItemComponentsProps) => {
  const isShippable = order?.shipmentRequired;
  const address = isBuyer ? order?.addressTo : order?.addressFrom;
  const canRenderAddress = isShippable && address?.addressLine1 && address.zip && address.state;

  if (!canRenderAddress) return null;
  return (
    <Container data-testid={'address-details'}>
      <Text1 data-testid={'name'}>{`${address.firstName} ${address.lastName}`}</Text1>
      <Text1 data-testid={'address-line-1'}>{address.addressLine1}</Text1>
      {address.addressLine2 ? <Text1 data-testid={'address-line-2'}>{address.addressLine2}</Text1> : null}
      <Text1 data-testid={'city'}>{address.city}</Text1>
      <Text1 data-testid={'zip-state'}>
        {address.zip} {address.state}
      </Text1>
      <Text1 data-testid={'phone'}>{formatMobilePhone(address?.mobilePhone)}</Text1>
    </Container>
  );
};
