import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import WhiteButton from 'components/buttons/WhiteButton';

const StyledButton = styled(WhiteButton)`
  max-width: 240px;
`;

const BackButton = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goToHomepage = () => navigate('/');

  return <StyledButton onClick={goToHomepage}>{t('navbar.back-to-homepage')}</StyledButton>;
};

export default BackButton;
