import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'qrcode.react';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { H2 } from 'components/typography/Headers';
import { AppAreaType } from 'services/Notification/notificationService.dto';
import { generateDeepLink } from 'services/utils/shareUtils';

export type QRSizeVariant = 'SMALL' | 'STANDARD';

const qrSmallBorderStyle = {
  border: `1px solid ${theme.color.darkGray}`,
  borderRadius: '5px',
  padding: '10px',
  backgroundColor: `${theme.color.white}`,
};

const qrStandardBorderStyle = {
  marginTop: '30px',
};

const Container = styled.div<{ variant: QRSizeVariant }>`
  display: flex;
  flex-direction: column;
  width: 70%;
  height: ${props => (props.variant === 'SMALL' ? 280 : 300)}px;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: center;
`;

interface Props {
  context?: AppAreaType;
  objectId?: number;
  hasAnnotatedDescription?: boolean;
  variant?: QRSizeVariant;
}

const QRCodeComponent = ({ context, objectId, hasAnnotatedDescription, variant }: Props) => {
  const { t } = useTranslation();
  const [url, setUrl] = useState<string>();
  const qrVariant = variant || 'STANDARD';

  useEffect(() => {
    generateDeepLink({ context, objectId }).then(deepLinkUrl => setUrl(deepLinkUrl));
  }, [context, objectId]);

  return (
    <Container variant={qrVariant}>
      {hasAnnotatedDescription && <H2>{t('qr-description')}</H2>}
      {url && (
        <QRCode
          data-testid={'qr-code'}
          renderAs={'svg'}
          value={url}
          size={qrVariant === 'SMALL' ? 170 : 190}
          style={qrVariant === 'SMALL' ? qrSmallBorderStyle : qrStandardBorderStyle}
        />
      )}
    </Container>
  );
};

export default QRCodeComponent;
