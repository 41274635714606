import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { H5 } from 'components/typography/Headers';
import { Text1 } from 'components/typography/Texts';
import { formatNullableMoneyInteger } from 'utils/numberFormatter';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px;
  gap: 24px;
`;
const SingleItem = styled.div`
  display: flex;
  flex-direction: column;
`;

interface UserDonationInfoProps {
  fundraised?: number;
  donations?: number;
  itemCount: number;
}

export const UserDonationInfo = ({ fundraised, donations, itemCount }: UserDonationInfoProps) => {
  const { t } = useTranslation('myProfile');
  return (
    <Container>
      <SingleItem data-testid={'fundraised'}>
        <H5 data-testid={'value'}>{formatNullableMoneyInteger(fundraised)}</H5>
        <Text1 data-testid={'label'}>{t('donation-info.fundraised')}</Text1>
      </SingleItem>

      <SingleItem data-testid={'donations'}>
        <H5 data-testid={'value'}>{formatNullableMoneyInteger(donations)}</H5>
        <Text1 data-testid={'label'}>{t('donation-info.donations')}</Text1>
      </SingleItem>

      <SingleItem data-testid={'items-donated'}>
        <H5 data-testid={'value'}>{t('donation-info.item-count', { count: itemCount })}</H5>
        <Text1 data-testid={'label'}>{t('donation-info.donated')}</Text1>
      </SingleItem>
    </Container>
  );
};
