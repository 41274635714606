import { Trans } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { ExternalLinkButton } from 'components/buttons/LinkButton';
import { Text2 } from 'components/typography/Texts';
import { resolvePrivacyPolicyUrl, resolveTermsUrl } from 'utils/webRedirections';

const PrivacyTermsText = styled(Text2)`
  width: 70%;
  margin: 0 auto;
  color: ${theme.color.darkerGray2};
`;

const PrivacyAndTerms = () => {
  return (
    <PrivacyTermsText data-testid={'privacy-and-terms'}>
      <Trans
        ns={'account'}
        i18nKey='terms-and-privacy'
        components={[
          <ExternalLinkButton key={0} href={resolveTermsUrl()} target='_blank' />,
          <ExternalLinkButton key={1} href={resolvePrivacyPolicyUrl()} target='_blank' />,
        ]}
      />
    </PrivacyTermsText>
  );
};

export default PrivacyAndTerms;
