import { useNavigate } from 'react-router-dom';

import { ItemFilter } from 'services/useMyItems';

export const useMyItemNavigate = () => {
  const navigate = useNavigate();

  const goToMyItem = (filter: ItemFilter, itemId: number, orderId?: number) => {
    if (filter === ItemFilter.SELLING) {
      navigate(`/items/${itemId}`);
    } else if (filter === ItemFilter.DONATED) {
      navigate(`/my-profile/my-activity/donated/${itemId}`);
    } else if (filter === ItemFilter.BOUGHT) {
      navigate(`/my-profile/my-activity/bought/${itemId}/${orderId}`);
    }
  };

  return { goToMyItem };
};
