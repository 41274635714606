import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { PURCHASE_ACTIONS } from 'assets/constants/purchaseActions';
import { theme } from 'assets/styles/theme';
import WhiteButton from 'components/buttons/WhiteButton';

import { TrackedItemComponentsProps } from './trackedItemUtils';
import { useCancelPopup } from './useCancelPopup';

const Spacer = styled.div`
  margin: 12px 0;
  border-top: 1px solid ${theme.color.borderGray};

  ${theme.mq.tablet} {
    margin: 2px 0;
  }
`;

export const CancelButton = ({ isBuyer, order, onClick }: TrackedItemComponentsProps) => {
  const { t } = useTranslation('myProfile');
  const status = order?.status;
  const { showCancelPopup } = useCancelPopup();

  const canBeCanceled = () => {
    if (isBuyer) {
      return status && PURCHASE_ACTIONS[status].canBeCanceledByBuyer;
    } else {
      return status && PURCHASE_ACTIONS[status].canBeCanceledBySeller;
    }
  };

  const handleOnClick = () => {
    onClick?.();
    showCancelPopup(order!!);
  };

  if (!canBeCanceled()) return null;
  return (
    <>
      <Spacer data-testid={'spacer'} />
      <WhiteButton data-testid={'cancel-btn'} onClick={handleOnClick}>
        {t('tracked-item.cancel')}
      </WhiteButton>
    </>
  );
};
