import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useConversations } from 'providers/ConversationProvider';
import { useMessages } from 'providers/MessageProvider';
import { ConversationDto, ConversationWithLastMessageDto } from 'services/Message/messageService.dto';

export const useConversationSelect = () => {
  const { conversationId, context } = useParams();
  const navigate = useNavigate();

  const [param, setParam] = useState<string | undefined>(conversationId || context);
  const [topConversationId, setTopConversationId] = useState<number | undefined>();
  const [selectedConversation, setSelectedConversation] = useState<ConversationDto>();
  const [currentConversations, setCurrentConversations] = useState<ConversationWithLastMessageDto[]>([]);
  const {
    conversations,
    resetConversations,
    fetchConversationMessages,
    setCurrentConversationId,
    currentConversationId,
    fetchConversations,
    fetchConversation,
  } = useConversations();
  const { getConversationMessages } = useMessages();

  const conversationMessages = !!selectedConversation
    ? getConversationMessages(selectedConversation.conversationId)
    : [];

  const setConversationId = (id: number) => {
    const conversation = conversations.find(conv => conv.conversation.conversationId === id);
    setSelectedConversation(conversation?.conversation);
  };

  useEffect(() => {
    resetConversations();
  }, []);

  useEffect(() => {
    if (!!param && !selectedConversation && currentConversations.length > 0) {
      if (!!conversationId) {
        const conversation = conversations.find(conv => conv.conversation.conversationId === Number(conversationId));
        setTopConversationId(Number(conversationId));
        setSelectedConversation(conversation?.conversation);
      } else if (context === 'SUPPORT') {
        const conversation = conversations.find(conv => conv.conversation.context === context);
        setTopConversationId(conversation?.conversation.conversationId);
        setSelectedConversation(conversation?.conversation);
      }
    }
  }, [param, currentConversations, selectedConversation]);

  useEffect(() => {
    if (!!selectedConversation) {
      fetchConversationMessages(selectedConversation.conversationId);
      setCurrentConversationId(selectedConversation.conversationId);
      setParam(undefined);
      navigate('/messenger', { replace: true });
    }
  }, [selectedConversation]);

  useEffect(() => {
    if (!currentConversationId) {
      setSelectedConversation(undefined);
    }
  }, [currentConversationId]);

  const handleTopConversation = () => {
    const topConversation = conversations.find(it => it.conversation.conversationId === topConversationId);
    if (!!topConversation) {
      const otherConversations = conversations.filter(it => it.conversation.conversationId !== topConversationId);
      setCurrentConversations([topConversation, ...otherConversations]);
    } else {
      fetchConversation(topConversationId!).catch(e => setCurrentConversations(conversations));
    }
  };

  useEffect(() => {
    if (conversations.length > 0) {
      if (!!topConversationId) {
        handleTopConversation();
      } else {
        setCurrentConversations(conversations);
      }
    } else {
      fetchConversations();
    }
  }, [conversations, topConversationId]);

  return { setConversationId, conversationMessages, selectedConversation, currentConversations };
};
