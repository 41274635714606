import styled from 'styled-components';

import { theme } from 'assets/styles/theme';

export const NavbarText = styled.p`
  margin: 0;
  font-family: ${theme.font.family.text};
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;

  ${theme.mq.tablet} {
    font-size: 16px;
  }
`;
