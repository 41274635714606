import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { H3 } from 'components/typography/Headers';
import { Text1Bold } from 'components/typography/Texts';
import { ItemDto } from 'services/Item/itemService.dto';
import { convertToThumbnail300 } from 'utils/thumbnails';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 42px;

  ${theme.mq.phone} {
    flex-direction: column;
    margin-bottom: 24px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
`;

const Image = styled.img`
  object-fit: cover;
  width: 216px;
  aspect-ratio: 1/1;
  margin-right: 24px;

  ${theme.mq.phone} {
    width: 100%;
    aspect-ratio: 1.46/1;
    margin-right: 0;
    margin-bottom: 24px;
  }
`;

interface Props {
  item: ItemDto;
}

const ItemDetails = ({ item }: Props) => {
  const { t } = useTranslation('checkout');

  return (
    <Container data-testid={'item-details'}>
      <Image data-testid={'item-photo'} src={convertToThumbnail300(item.imageUrlList[0])} />
      <TitleContainer>
        <Text1Bold data-testid={'item-header'}>{t('item.you-are-buying')}</Text1Bold>
        <H3 data-testid={'item-title'}>{item.title}</H3>
      </TitleContainer>
    </Container>
  );
};

export default ItemDetails;
