import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FacebookMessengerShareButton, FacebookShareButton, TwitterShareButton } from 'react-share';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import copyIcon from 'assets/svg/copy-icon.svg';
import facebookIcon from 'assets/svg/share-facebook-icon.svg';
import messengerIcon from 'assets/svg/share-messenger-icon.svg';
import xIcon from 'assets/svg/share-x-icon.svg';
import { H4 } from 'components/typography/Headers';
import { Caption, Text1, Text2 } from 'components/typography/Texts';
import { ShareType } from 'services/utils/shareUtils';
import { resolveLandingUrl } from 'utils/webRedirections';

import LinkCopied from './LinkCopied';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
`;

const Header = styled(H4)`
  margin-top: 25px;
`;

const Description = styled(Text1)`
  margin: 18px 0 24px 0;
  white-space: pre-line;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

const IconContainer = styled.div`
  padding: 0 12px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const IconDescription = styled(Caption)`
  font-family: ${theme.font.family.text};
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  margin-top: 6px;
`;

const Icon = styled.img`
  width: 50px;
  height: 50px;
`;

const LinkDescriptionContainer = styled(Text2)`
  margin: 24px 0;
`;

const LinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${theme.color.lighterGray};
  border-radius: 5px;
  padding: 10px 12px;
`;

const Link = styled(Text2)`
  color: ${theme.color.placeholderGrey};
  margin-right: 22px;
  ${theme.mq.phone} {
    margin-right: 16px;
  }
`;

const CopyIconContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const CopyIcon = styled.img`
  cursor: pointer;
  height: 20px;
  width: 20px;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.7;
  }
`;

interface Props {
  shareType: ShareType;
  url?: string;
  campaignTitle?: string;
  itemTitle?: string;
}

export const SharePanel = ({ shareType, url, campaignTitle, itemTitle }: Props) => {
  const { t } = useTranslation('share');
  const [copyActive, setCopyActive] = useState(false);

  const header = t(`menu.title.${shareType}`);
  const description = t(`menu.description.${shareType}`);

  const shareTitle = t(`${shareType}.title`, { campaignTitle, itemTitle });

  const copyLinkToClipboard = (url: string) => {
    setCopyActive(true);
    navigator.clipboard.writeText(url).then(() => {
      setTimeout(() => {
        setCopyActive(false);
      }, 300);
    });
  };

  const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID || '';
  const landingUrl = resolveLandingUrl();

  return (
    <Container>
      <Header data-testid={'share-header'}>{header}</Header>
      <Description data-testid={'share-description'}>{description}</Description>
      {url && (
        <>
          <ButtonContainer>
            <FacebookShareButton url={url}>
              <IconContainer>
                <Icon src={facebookIcon} />
                <IconDescription>{t('menu.icon.facebook')}</IconDescription>
              </IconContainer>
            </FacebookShareButton>
            <FacebookMessengerShareButton
              disabled={!facebookAppId || !landingUrl}
              url={url}
              appId={facebookAppId}
              redirectUri={landingUrl}>
              <IconContainer>
                <Icon src={messengerIcon} />
                <IconDescription>{t('menu.icon.messenger')}</IconDescription>
              </IconContainer>
            </FacebookMessengerShareButton>
            <TwitterShareButton url={url} title={shareTitle}>
              <IconContainer>
                <Icon src={xIcon} />
                <IconDescription>{t('menu.icon.x')}</IconDescription>
              </IconContainer>
            </TwitterShareButton>
          </ButtonContainer>
          <LinkDescriptionContainer>{t('menu.share-link')}</LinkDescriptionContainer>
          <LinkContainer onClick={() => copyLinkToClipboard(url)}>
            <Link data-testid={'share-link'}>{url}</Link>
            <CopyIconContainer>
              <LinkCopied visible={copyActive} />
              <CopyIcon src={copyIcon} />
            </CopyIconContainer>
          </LinkContainer>
        </>
      )}
    </Container>
  );
};
