import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { H6 } from 'components/typography/Headers';
import { Text1 } from 'components/typography/Texts';
import { LoadingIndicator } from 'components/ui-elements/LoadingIndicator';

const Circle = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 25px;
  margin: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.color.gray};
`;

const CircleValue = styled(H6)`
  font-weight: bold;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

interface CircleProfileProps {
  title: string;
  value?: number;
  isLoading: boolean;
  onClick: () => void;
  testId?: string;
}

export const StatisticCircle = ({ title, value, isLoading, onClick, testId }: CircleProfileProps) => {
  return (
    <Container onClick={onClick} data-testid={testId}>
      <Circle>
        {!isLoading && <CircleValue data-testid={'circle-value'}>{value}</CircleValue>}
        {isLoading && <LoadingIndicator />}
      </Circle>
      <Text1 data-testid={'circle-label'}>{title}</Text1>
    </Container>
  );
};
