import { isAndroid, isChrome, isIOS, isIOS13 } from 'react-device-detect';

export const isIOSDevice = () => {
  return isIOS || isIOS13;
};

export const getMobileStoreLink = () => {
  if (isIOSDevice()) {
    return appStoreLink();
  } else if (isAndroid) {
    return googlePlayLink();
  }
  return '/';
};

export const appStoreLink = () => {
  const appId = process.env.REACT_APP_APP_STORE_ID;
  return isChrome && isIOSDevice() // fix for AppStore redirect issue on Chrome iOS
    ? 'itms-apps://apps.apple.com/us/app/giveo/id' + appId
    : 'https://apps.apple.com/us/app/giveo/id' + appId;
};

export const googlePlayLink = () => {
  return process.env.REACT_APP_GOOGLE_PLAY_URL || '';
};
