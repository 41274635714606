import styled from 'styled-components';

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const IconWrapper = styled.img`
  cursor: pointer;
`;

export const LoadingContainer = styled.div`
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
