import styled, { keyframes } from 'styled-components';

import loadingIndicator from 'assets/svg/loading-indicator.svg';

const rotate = keyframes`
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
`;

interface Props {
  className?: string;
}

const LoadingIcon = styled.img`
  animation: ${rotate} 1s linear infinite;
`;

export const LoadingIndicator = ({ className }: Props) => {
  return <LoadingIcon className={className} src={loadingIndicator} data-testid={'loading-indicator'} />;
};
