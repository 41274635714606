import { useVerifyEmailPopup } from 'features/account/verify-email/useVerifyEmailPopup';
import { getLastSignUpEmail } from 'utils/localStorageData';

export const useContinueSignUp = () => {
  const { showVerifyEmailPopup } = useVerifyEmailPopup();

  const lastSignUpEmail = getLastSignUpEmail();
  const hasLastSignUpEmail = !!lastSignUpEmail;

  const continueVerification = () => {
    if (hasLastSignUpEmail) {
      showVerifyEmailPopup(lastSignUpEmail);
    }
  };

  return { hasLastSignUpEmail, continueVerification };
};
