import styled from 'styled-components';

import arrowDownIcon from 'assets/svg/arrow-down.svg';
import flagIcon from 'assets/svg/flag-us.svg';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;

const FlagIcon = styled.img`
  margin-right: 6px;
`;

const LanguagePicker = () => {
  return (
    <Container>
      <FlagIcon src={flagIcon} />
      <img src={arrowDownIcon} alt={''} />
    </Container>
  );
};

export default LanguagePicker;
