import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import banIcon from 'assets/svg/ban.svg';
import deletedIcon from 'assets/svg/deleted.svg';
import { H5 } from 'components/typography/Headers';

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Icon = styled.img`
  width: 40px;
  height: 40px;
`;
const Text = styled(H5)`
  text-align: center;
  color: ${theme.color.white};
  text-shadow: -2px -2px 0 ${theme.color.black}, 2px -2px 0 ${theme.color.black}, -2px 2px 0 ${theme.color.black},
    2px 2px 0 ${theme.color.black};
`;

interface Props {
  isHidden: boolean;
  campaignStatus: string;
}

const CampaignStatusIcon = ({ isHidden, campaignStatus }: Props) => {
  const { t } = useTranslation('common');

  const renderStatusIcon = (icon: string, label: string, testId: string, iconTestId: string) => {
    return (
      <Container>
        <Icon data-testid={iconTestId} src={icon} alt={'status icon'} />
        <Text data-testid={testId}>{label}</Text>
      </Container>
    );
  };

  return (
    <>
      {campaignStatus === 'CLOSED' &&
        !isHidden &&
        renderStatusIcon(deletedIcon, t('campaign-closed'), 'campaign-closed', 'campaign-closed-icon')}
      {isHidden &&
        renderStatusIcon(banIcon, t('inappropriate-content'), 'inappropriate-content', 'inappropriate-content-icon')}
    </>
  );
};

export default CampaignStatusIcon;
