import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import CircleActive from 'assets/svg/circle-active.svg';
import CircleInactive from 'assets/svg/circle-inactive.svg';
import { H6 } from 'components/typography/Headers';

const Container = styled.div`
  height: 48px;
  margin: 5px;
  cursor: pointer;
`;

const ItemWrapper = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${theme.color.red};
  transition: all 0.3s;
  background-color: ${props => (props.selected ? theme.color.red : theme.color.white)};
  color: ${theme.color.red};
  border-radius: 5px;
  padding: 10px;
`;

const ItemIconWrapper = styled.img`
  margin-right: 8px;
`;

const ItemText = styled(H6)<{ selected: boolean }>`
  color: ${props => (props.selected ? theme.color.white : theme.color.red)};
  font-weight: 700;
  line-height: 22px;
`;

interface Props {
  name: string;
  isActive: boolean;
  onSelect: (name: string) => void;
  onUnselect: (name: string) => void;
}

const ReportReason = (props: Props) => {
  const { name, isActive, onSelect, onUnselect } = props;
  return (
    <Container onClick={() => (isActive ? onUnselect(name) : onSelect(name))}>
      <ItemWrapper selected={isActive}>
        <ItemIconWrapper src={isActive ? CircleActive : CircleInactive} />
        <ItemText selected={isActive}>{name}</ItemText>
      </ItemWrapper>
    </Container>
  );
};

export default ReportReason;
