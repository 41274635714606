import { useLoginError } from 'features/account/login/useLoginError';
import { useGlobalError } from 'hooks/useGlobalError';
import { AuthType, useAuth } from 'providers/AuthProvider';
import { authService } from 'services/Auth/authService';
import { LoggedWithSocialMediaDto } from 'services/Auth/authService.dto';
import { clearLastSignUpEmail } from 'utils/localStorageData';

export const useSocialMediaLogin = (onLogged: () => void) => {
  const { appLogin } = useAuth();
  const { lockedAccount, blockedAccount, blockedDevice, missingData } = useLoginError();
  const { unknownError } = useGlobalError();

  const loginWithFacebook = (accessToken: string) =>
    authService
      .loginFacebook(accessToken)
      .then(({ data }) => handleLogin(data, 'Facebook'))
      .catch(error => handleError(error));

  const loginWithGoogle = (accessToken: string) =>
    authService
      .loginGoogle(accessToken)
      .then(({ data }) => handleLogin(data, 'Google'))
      .catch(error => handleError(error));

  const handleLogin = (loggedData: LoggedWithSocialMediaDto, authType: AuthType) => {
    appLogin(loggedData.authToken, authType);
    clearLastSignUpEmail();
    onLogged();
  };

  const handleError = (error: any) => {
    if (error.response?.status === 403) {
      blockedAccount();
    } else if (error.response?.status === 429) {
      lockedAccount();
    } else if (error.response?.status === 423) {
      blockedDevice();
    } else if (error.response?.status === 422) {
      missingData();
    } else {
      unknownError();
    }
  };

  return { loginWithFacebook, loginWithGoogle };
};
