import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { Text2 } from 'components/typography/Texts';

import FormSeparator from './FormSeparator';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Text = styled(Text2)`
  color: ${theme.color.darkGray};
  padding: 0 10px;
  text-align: center;
`;

const ButtonSeparator = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <FormSeparator />
      <Text>{t('or')}</Text>
      <FormSeparator />
    </Container>
  );
};

export default ButtonSeparator;
