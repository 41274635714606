import i18n from 'i18next';

import { BuyerListOrderStatus } from 'features/my-profile/my-activity/components/list-of-sold/OrderStatusFilter';
import { PurchaseDto } from 'services/Order/orderService.dto';

export interface TrackedItemComponentsProps {
  isBuyer?: boolean;
  order?: PurchaseDto;
  invalidRequest?: () => void;
  onClick?: () => void;
}

export const getFilterByOrderStatus = (isNationwideShippingAvailable: boolean, isLocalPickupAvailable: boolean) => {
  const allStatuses = Object.values(BuyerListOrderStatus);

  return [
    {
      id: 1,
      title: i18n.t('myProfile:tracked-item.all'),
      type: allStatuses,
      isVisible: isNationwideShippingAvailable || isLocalPickupAvailable,
    },
    {
      id: 2,
      title: i18n.t('myProfile:tracked-item.awaiting-payment'),
      type: [BuyerListOrderStatus.CONFIRMED],
      isVisible: isNationwideShippingAvailable || isLocalPickupAvailable,
    },
    {
      id: 3,
      title: i18n.t('myProfile:tracked-item.not-delivered'),
      type: [BuyerListOrderStatus.AUTHORIZED, BuyerListOrderStatus.CHARGED],
      isVisible: isLocalPickupAvailable,
    },
    {
      id: 4,
      title: i18n.t('myProfile:tracked-item.delivered'),
      type: [BuyerListOrderStatus.PICKED_UP, BuyerListOrderStatus.COMPLETED],
      isVisible: isNationwideShippingAvailable || isLocalPickupAvailable,
    },
    {
      id: 5,
      title: i18n.t('myProfile:tracked-item.paid'),
      type: [BuyerListOrderStatus.PAID],
      isVisible: isNationwideShippingAvailable,
    },
    {
      id: 6,
      title: i18n.t('myProfile:tracked-item.waiting-for-shipment'),
      type: [BuyerListOrderStatus.SHIPPABLE],
      isVisible: isNationwideShippingAvailable,
    },
    {
      id: 7,
      title: i18n.t('myProfile:tracked-item.shipped'),
      type: [BuyerListOrderStatus.SHIPPED],
      isVisible: isNationwideShippingAvailable,
    },
  ].filter(option => option.isVisible);
};

export const buyerTileStatusLabel: { [key: string]: string } = {
  [BuyerListOrderStatus.CONFIRMED]: i18n.t('myProfile:tracked-item.awaiting-payment'),
  [BuyerListOrderStatus.AUTHORIZED]: i18n.t('myProfile:tracked-item.not-delivered'),
  [BuyerListOrderStatus.CHARGED]: i18n.t('myProfile:tracked-item.not-delivered'),
  [BuyerListOrderStatus.PICKED_UP]: i18n.t('myProfile:tracked-item.delivered'),
  [BuyerListOrderStatus.COMPLETED]: i18n.t('myProfile:tracked-item.delivered'),
  [BuyerListOrderStatus.PAID]: i18n.t('myProfile:tracked-item.paid'),
  [BuyerListOrderStatus.SHIPPABLE]: i18n.t('myProfile:tracked-item.waiting-for-shipment'),
  [BuyerListOrderStatus.SHIPPED]: i18n.t('myProfile:tracked-item.shipped'),
};
