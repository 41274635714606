export type AppAreaType =
  | 'ITEM'
  | 'MARKETPLACE'
  | 'MESSENGER'
  | 'FAILED_PAYMENT'
  | 'ADD_ITEM'
  | 'SOLD_ITEMS'
  | 'PHONE_CONFIRMATION'
  | 'ADD_CAMPAIGN'
  | 'LOGIN'
  | 'CAMPAIGN'
  | 'SUPPORTING_ITEMS'
  | 'SAVED_ITEMS'
  | 'CAMPAIGN_LIST'
  | 'BID_LIST'
  | 'CAMPAIGN_SUPPORTERS'
  | 'DEFAULT_APP_AREA';

export const DEFAULT_APP_AREA = 'DEFAULT_APP_AREA';
export interface PhoneDto {
  areaCode?: string;
  countryCode?: string;
  number?: string;
}

export interface DeepLinkDto {
  url: string;
}
