import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import closeIcon from 'assets/svg/close-red.svg';
import confirmedIcon from 'assets/svg/dolar-circle.svg';
import defaultImagePlaceholder from 'assets/svg/profile-placeholder.svg';
import trashIconGray from 'assets/svg/trash-icon-gray.svg';
import vipIcon from 'assets/svg/vip-icon.svg';
import { Text1 } from 'components/typography/Texts';
import { useResponsive } from 'hooks/useResponsive';
import { useConversations } from 'providers/ConversationProvider';
import { ConversationDto } from 'services/Message/messageService.dto';
import { convertToThumbnail300 } from 'utils/thumbnails';

import { TrashIconWrapper } from './TrashIconWrapper';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 24px 60px;
  border-bottom: 1px solid ${theme.color.darkGray};

  ${theme.mq.desktop} {
    padding: 24px;
  }

  ${theme.mq.phone} {
    padding: 6px 12px;
  }
`;

const SenderImageContainer = styled.div`
  position: relative;
`;

const SenderImage = styled.img`
  border-radius: 30px;
  width: 56px;
  height: 56px;
  object-fit: cover;
`;

const SenderName = styled(Text1)`
  font-weight: bold;

  ${theme.mq.tablet} {
    font-size: 14px;
    line-height: 21px;
  }
`;

const StyledConfirmedIcon = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
`;

const CategoryImage = styled.img`
  border-radius: 8px;
  width: 56px;
  height: 56px;
  cursor: pointer;
  object-fit: cover;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 210px;
  gap: 6px;
  padding-right: 20px;
  ${theme.mq.tablet} {
    max-width: 136px;
  }
`;

const VipIcon = styled.img`
  width: 16px;
  height: 16px;
`;

const CloseButton = styled.img`
  cursor: pointer;
  padding: 10px;
`;

const TrashIcon = styled.img`
  cursor: pointer;
  padding: 10px;
`;

const SenderContainer = styled.div`
  display: flex;
  gap: 12px;
`;

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
`;

interface ConversationCategory {
  imageUrl: string;
  webUrl: string;
}

interface ConversationProps {
  conversation: ConversationDto;
}

export const MessageThreadHeader = ({ conversation }: ConversationProps) => {
  const { isMobile } = useResponsive();
  const navigate = useNavigate();
  const { setCurrentConversationId } = useConversations();
  const sender = conversation.interlocutors[0];

  const resolveCategoryImage = (): ConversationCategory | undefined => {
    if (conversation.item) {
      return { imageUrl: conversation.item.imageUrl, webUrl: '/items/' + conversation.item.id };
    } else if (conversation.campaign) {
      return { imageUrl: conversation.campaign.imageUrl, webUrl: '/campaigns/' + conversation.campaign.id };
    } else {
      return undefined;
    }
  };

  const conversationCategory = resolveCategoryImage();
  const onCloseConversation = () => {
    setCurrentConversationId(undefined);
  };

  return (
    <Container>
      <SenderContainer>
        <SenderImageContainer>
          <SenderImage
            data-testid={'sender-image'}
            src={sender.profileImageUrl ? convertToThumbnail300(sender.profileImageUrl) : defaultImagePlaceholder}
          />
          {sender.fundraiserVerificationCompleted && (
            <StyledConfirmedIcon data-testid={'confirmed-icon'} src={confirmedIcon} />
          )}
        </SenderImageContainer>
        <TextContainer>
          <SenderName data-testid={'sender-name'}>{sender.name}</SenderName>
          {sender.vip && <VipIcon data-testid={'vip-icon'} src={vipIcon} />}
        </TextContainer>

        {!isMobile && !!conversationCategory && (
          <CategoryImage
            data-testid={'category-image'}
            src={convertToThumbnail300(conversationCategory.imageUrl)}
            onClick={() => navigate(conversationCategory.webUrl)}
          />
        )}
      </SenderContainer>
      <ActionContainer>
        {isMobile && (
          <TrashIconWrapper conversation={conversation}>
            <TrashIcon data-testid={'trash-button'} src={trashIconGray} />
          </TrashIconWrapper>
        )}
        <CloseButton data-testid={'close-button'} src={closeIcon} alt={'close'} onClick={onCloseConversation} />
      </ActionContainer>
    </Container>
  );
};
