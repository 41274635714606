import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import appStoreBanner from 'assets/svg/app-store.svg';
import googlePlayBanner from 'assets/svg/google-play.svg';
import { H1, H5, H6 } from 'components/typography/Headers';
import QRCodeComponent from 'components/ui-elements/QRCodeComponent';
import StoreBanner from 'components/ui-elements/StoreBanner';
import { AppAreaType, DEFAULT_APP_AREA } from 'services/Notification/notificationService.dto';
import { appStoreLink, googlePlayLink, isIOSDevice } from 'utils/mobileStoreRedirections';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: ${theme.zIndex.modal};
`;

const Header = styled(H5)`
  margin-top: 25px;
  color: ${theme.color.white};
`;

const Description = styled(H1)`
  color: ${theme.color.white};
  margin-top: 18px;
  white-space: pre-line;
`;

const Info = styled(H6)`
  color: ${theme.color.white};
  margin-top: 36px;
  font-size: 20px;
`;

const InfoBold = styled(H5)`
  color: ${theme.color.white};
  font-weight: 800;
`;

const StoreBannerContainer = styled.div`
  margin-top: 39px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  ${theme.mq.phone} {
    flex-direction: column;
  }
`;

interface Props {
  appAreaType?: AppAreaType;
  objectId?: number;
  header?: string;
  description?: string;
}

export const GetApp = (props: Props) => {
  const { t } = useTranslation();
  const { description, header, appAreaType, objectId } = props;
  const context = appAreaType || DEFAULT_APP_AREA;

  const canDisplayGoogleBanner = () => {
    return !isMobile || !isIOSDevice();
  };

  const canDisplayAppleBanner = () => {
    return !isMobile || isIOSDevice();
  };

  return (
    <Container data-testid={'get-app-popup'}>
      <Header data-testid={'get-app-header'}>{header || t('get-app.header')}</Header>
      <Description data-testid={'get-app-description'}>{description || t(`${context}.description`)}</Description>
      <>
        <Info>{t('get-app.call-to-action')}</Info>
        <InfoBold>{t('get-app.download-app')}</InfoBold>
      </>
      <StoreBannerContainer>
        {canDisplayAppleBanner() && (
          <StoreBanner link={appStoreLink()} imgPath={appStoreBanner} alt={t('navbar.apple')} />
        )}
        {canDisplayGoogleBanner() && (
          <StoreBanner link={googlePlayLink()} imgPath={googlePlayBanner} alt={t('navbar.google')} />
        )}
      </StoreBannerContainer>
      <QRCodeComponent context={context} objectId={objectId} variant={'SMALL'} />
    </Container>
  );
};
