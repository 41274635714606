import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const MIN_PASSWORD_LENGTH = 8;
const ascii = /^[ -~\t\n\r]+$/;

export const useSetPassword = () => {
  const { t } = useTranslation('account');
  const [password, setPassword] = useState('');
  const [passwordConfirmed, setPasswordConfirmed] = useState('');
  const [passwordError, setPasswordError] = useState<string | undefined>();
  const [passwordConfirmedError, setPasswordConfirmedError] = useState<string | undefined>();
  const hasErrors = !password || !passwordConfirmed || !!passwordError || !!passwordConfirmedError;

  useEffect(() => {
    if (!password) {
      setPasswordError(undefined);
    } else if (password !== '' && !ascii.test(password)) {
      setPasswordError(t('password.invalid-characters'));
    } else if (password !== '' && (password.startsWith(' ') || password.endsWith(' '))) {
      setPasswordError(t('password.contains-space'));
    } else if (password !== '' && password.length < MIN_PASSWORD_LENGTH) {
      setPasswordError(t('password.too-short'));
    } else {
      setPasswordError(undefined);
    }
  }, [password]);

  useEffect(() => {
    if (passwordConfirmed !== '' && password !== passwordConfirmed) {
      setPasswordConfirmedError(t('password.dont-match'));
    } else {
      setPasswordConfirmedError(undefined);
    }
  }, [password, passwordConfirmed]);

  return {
    password,
    setPassword,
    passwordConfirmed,
    setPasswordConfirmed,
    passwordError,
    passwordConfirmedError,
    hasErrors,
  };
};
