import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { makeTransparentColor } from 'assets/styles/transparentColor';

export const OpacityOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background-color: ${makeTransparentColor(theme.color.white, 0.3)};
`;
