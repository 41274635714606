import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { H5 } from 'components/typography/Headers';
import { TextButton1 } from 'components/typography/TextButtons';
import { useShippingAddress } from 'providers/ShippingAddressProvider';

const FormHeader = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  justify-content: flex-end;
`;

const Header = styled(H5)`
  flex: 1;
`;

interface Props {
  onSave?: () => void;
  onAddNew?: () => void;
  onCancel?: () => void;
  isFormValid?: boolean;
  header?: string;
}

export const ShippingAddressFormHeader = ({ onSave, onAddNew, onCancel, isFormValid, header }: Props) => {
  const { isLoading, isSaving, shippingAddresses } = useShippingAddress();
  const { t } = useTranslation('myProfile');
  const canCancel = shippingAddresses.length > 0;

  return (
    <FormHeader>
      <Header data-testid={'shipping-address-form-header'}>{header ?? t('shipping-address.form-header')}</Header>
      {!isLoading && !isSaving && (
        <>
          {onCancel && (
            <TextButton1 data-testid={'cancel-btn'} onClick={onCancel} disabled={!canCancel}>
              {t('common:cancel')}
            </TextButton1>
          )}
          {onSave && (
            <TextButton1 data-testid={'save-btn'} onClick={onSave} disabled={!isFormValid}>
              {t('common:save')}
            </TextButton1>
          )}
          {onAddNew && (
            <TextButton1 data-testid={'add-new-btn'} onClick={onAddNew}>
              {t('shipping-address.add-new')}
            </TextButton1>
          )}
        </>
      )}
    </FormHeader>
  );
};
