import { ConversationLastMessageDto, MessageDto } from './messageService.dto';

export const toLastMessage = (message?: MessageDto, currentUserId?: number): ConversationLastMessageDto => {
  return {
    conversationId: message?.conversationId,
    lastMessageContent: message?.content,
    lastMessageCreated: message?.createdAt,
    type: message?.type,
    authorId: currentUserId || message?.authorId,
    payload: message?.payload,
  };
};
