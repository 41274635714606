import { PaymentTokenData } from 'features/checkout/social-media/socialMedia.types';
import { OrderUserDto } from 'services/Order/orderService.dto';
import { BillingDataDto } from 'services/Payment/paymentService.dto';

const toToken = (payment: ApplePayJS.ApplePayPayment) => {
  return JSON.stringify(payment);
};

const toUser = (payment: ApplePayJS.ApplePayPayment): OrderUserDto => {
  const shippingContact = payment.shippingContact as ApplePayJS.ApplePayPaymentContact;
  const billingContact = payment.billingContact as ApplePayJS.ApplePayPaymentContact;

  return {
    email: shippingContact.emailAddress,
    firstName: billingContact.givenName,
    lastName: billingContact.familyName,
  };
};

const toBillingData = (payment: ApplePayJS.ApplePayPayment): BillingDataDto => {
  const shippingContact = payment.shippingContact as ApplePayJS.ApplePayPaymentContact;
  const billingContact = payment.billingContact as ApplePayJS.ApplePayPaymentContact;

  const firstName = billingContact.givenName;
  const lastName = billingContact.familyName;
  const city = billingContact.locality;
  const postcode = billingContact.postalCode;
  const state = billingContact.administrativeArea;
  const mobilePhone = shippingContact.phoneNumber;
  const country = billingContact.countryCode;
  const address = billingContact.addressLines?.join(' ');

  return { firstName, lastName, address, state, city, country, mobilePhone, postcode };
};

const preparePaymentRequest = (payment: ApplePayJS.ApplePayPayment, paymentId: number): PaymentTokenData => ({
  payment: { paymentId, token: toToken(payment) },
  user: toUser(payment),
  billingData: toBillingData(payment),
});

export { preparePaymentRequest };
