import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { H6 } from 'components/typography/Headers';

const Name = styled(H6)`
  margin: 3px 0 5px 0;
  overflow: hidden;
  word-break: break-word;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  padding: 0;

  ${theme.mq.phone} {
    -webkit-line-clamp: 3;
  }
`;

interface Props {
  title: string;
}

const ItemName = ({ title }: Props) => {
  return (
    <Name data-testid={'tile-item-title'} className={'itemTileName'}>
      {title}
    </Name>
  );
};

export default ItemName;
