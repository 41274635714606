import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { Caption } from 'components/typography/Texts';
import { parseBackendDate } from 'utils/backendDateParser';
import { formatDateLong } from 'utils/dateFormatter';

const Container = styled.div`
  color: ${theme.color.messageGray};
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
  margin-bottom: 12px;
`;

interface Props {
  messageCreatedAt: string;
}

export const DaySeparator = ({ messageCreatedAt }: Props) => {
  const parsedDate = parseBackendDate(messageCreatedAt);

  return (
    <Container>
      <Caption data-testid={'first-message-in-day'}>{formatDateLong(parsedDate)}</Caption>
    </Container>
  );
};
