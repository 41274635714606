import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { ItemWithAuctionDto } from 'services/Item/withAuctionsData';

import ItemDetails from './ItemDetails';
import ItemTitle from './ItemName';
import ItemPhoto from './ItemPhoto';
import { HeartIcon } from './tileIcons';

const BorderContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  width: 100%;
  aspect-ratio: 1/1.4;
  border: 1px solid ${theme.color.borderGray};
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  overflow: hidden;

  ${theme.mq.phone} {
    aspect-ratio: 1/1.6;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  position: relative;
`;

interface Props {
  item: ItemWithAuctionDto;
}

const ItemTile = ({ item }: Props) => {
  return (
    <BorderContainer to={`/items/${item.id}`} data-testid={'item-tile'}>
      <ImageContainer>
        <ItemPhoto image={item.imageUrlList[0]} />
        <HeartIcon />
      </ImageContainer>
      <ItemTitle title={item.title} />
      <ItemDetails item={item} />
    </BorderContainer>
  );
};

export default ItemTile;
