import { ReactNode } from 'react';
import styled from 'styled-components';

import VipIcon from 'assets/svg/vip-icon.svg';

const Container = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

interface UserDisplayNameProps {
  isVip: boolean;
  children: ReactNode;
  iconTopMargin?: number;
  iconLeftMargin?: number;
}

const UserDisplayName = (props: UserDisplayNameProps) => {
  return (
    <Container>
      {props.children}
      {props.isVip && <VipIcon data-testid={'vip'} />}
    </Container>
  );
};

export default UserDisplayName;
