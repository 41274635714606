import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import errorIcon from 'assets/svg/notification-error.svg';
import { IconWrapper } from 'components/containers/Containers';
import { H5 } from 'components/typography/Headers';
import { Text1 } from 'components/typography/Texts';

const Container = styled.div`
  width: 100%;
  height: 60px;
  background-color: ${theme.color.red};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const ContentContainer = styled.div`
  max-width: ${theme.maxWidthContainer}px;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  padding: 24px;

  ${theme.mq.phone} {
    padding: 12px;
    gap: 12px;
  }
`;

const Title = styled(H5)`
  color: ${theme.color.white};
`;

const Description = styled(Text1)`
  color: ${theme.color.white};
`;

interface Props {
  title: string;
  description?: string;
}

export const ClosedInfo = ({ title, description }: Props) => {
  return (
    <Container>
      <ContentContainer>
        <IconWrapper src={errorIcon} />
        <Title>{title}</Title>
        {description && <Description>{description}</Description>}
      </ContentContainer>
    </Container>
  );
};
