import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';

import { theme } from 'assets/styles/theme';
import ItemTile from 'components/item/ItemTile/ItemTile';
import { ItemWithAuctionDto } from 'services/Item/withAuctionsData';

import NavigationHeader from './NavigationHeader';

import 'swiper/css';

const Container = styled.div`
  width: 100%;
`;

interface BreakPointConfigType {
  [width: number]: { slidesPerView: number; spaceBetween: number };
}

const breakpointsConfig = (): BreakPointConfigType => {
  const breakpoints = [];
  breakpoints[theme.breakpoint.min] = { slidesPerView: 2, spaceBetween: 12 };
  breakpoints[theme.breakpoint.phone] = { slidesPerView: 3, spaceBetween: 12 };
  breakpoints[theme.breakpoint.tablet] = { slidesPerView: 4, spaceBetween: 12 };
  breakpoints[theme.breakpoint.desktop] = { slidesPerView: 5, spaceBetween: 12 };
  breakpoints[theme.breakpoint.huge] = { slidesPerView: 6, spaceBetween: 24 };
  return breakpoints;
};

interface Props {
  header: string;
  items: ItemWithAuctionDto[];
}

const ItemTileSlider = ({ header, items }: Props) => {
  const swiperRef = useRef<SwiperClass>();
  const [prevEnabled, setPrevEnabled] = useState(true);
  const [nextEnabled, setNextEnabled] = useState(true);
  const [displayButtons, setDisplayButtons] = useState(true);

  useEffect(() => {
    recalculateState();
  }, [items.length]);

  const onSwiper = (swiper: SwiperClass) => {
    swiperRef.current = swiper;
    recalculateState();
  };

  const recalculateState = () => {
    setPrevEnabled(swiperRef.current?.progress > 0);
    setNextEnabled(swiperRef.current?.progress < 1);
    setDisplayButtons(swiperRef.current?.slidesPerViewDynamic() < items.length);
  };

  const onPrevPress = () => swiperRef.current?.slidePrev();

  const onNextPress = () => swiperRef.current?.slideNext();

  const onBreakpoint = () => recalculateState();

  return (
    <Container>
      <NavigationHeader
        header={header}
        prevEnabled={prevEnabled}
        nextEnabled={nextEnabled}
        onPrevPress={onPrevPress}
        onNextPress={onNextPress}
        displayButtons={displayButtons}
      />
      <Swiper
        breakpoints={breakpointsConfig()}
        onSwiper={onSwiper}
        onSlideChange={recalculateState}
        onBreakpoint={onBreakpoint}>
        {items.map(item => (
          <SwiperSlide key={item.id.toString()}>
            <ItemTile item={item} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Container>
  );
};

export default ItemTileSlider;
