import { v4 as uuid } from 'uuid';

import { NewMessageBundle } from 'providers/ConversationProvider';
import { InternalMessageDto, MessageInternalStatus, MessageType } from 'services/Message/messageService.dto';

export const generateTmpMessageId = () => {
  return parseInt(uuid().replace(/-/g, ''), 16);
};

export const mapToInternalMessage = (
  message: NewMessageBundle,
  type: MessageType,
  userId?: number
): InternalMessageDto => {
  const msgDate = formatUTCDate();
  return {
    mediaUrl: message.content,
    content: message.content,
    authorId: userId,
    conversationId: message.conversationId,
    createdAt: msgDate,
    updatedAt: msgDate,
    internalId: message.tmpMessageId,
    type: type,
    internalStatus: MessageInternalStatus.UPLOADING,
    confirmationReceived: false,
  };
};

const formatUTCDate = () => {
  return new Date().toUTCString().replace('GMT', '').replace(',', '').trim();
};
