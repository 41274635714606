import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { Text2 } from 'components/typography/Texts';

import { Container, InnerContainer } from './footerContainers';

const CopyrightText = styled(Text2)`
  color: ${theme.color.darkerGray};
  align-self: flex-start;
`;

const StyledInnerContainer = styled(InnerContainer)`
  margin: 0;
  padding: 21px 48px;

  ${theme.mq.phone} {
    padding: 12px 12px;
  }
`;

const CopyrightFooter = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <StyledInnerContainer>
        <CopyrightText>{t('footer.copyright')}</CopyrightText>
      </StyledInnerContainer>
    </Container>
  );
};

export default CopyrightFooter;
