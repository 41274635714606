import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { ConversationWithLastMessageDto } from 'services/Message/messageService.dto';

import { Conversation } from './Conversation';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 100%;
  overflow-y: auto;
  padding: 20px;

  ${theme.mq.phone} {
    gap: 6px;
  }
`;

interface Props {
  conversations: ConversationWithLastMessageDto[];
  onConversationClick: (id: number) => void;
  selectedId?: number;
  onScroll?: (e: any) => void;
}

export const ConversationsList = ({ conversations, onConversationClick, selectedId, onScroll }: Props) => {
  return (
    <Container data-testid={'conversations-list'} onScroll={onScroll}>
      {conversations &&
        conversations.map(conversation => (
          <Conversation
            lastMessage={conversation.lastMessage}
            conversation={conversation.conversation}
            key={conversation.conversation.conversationId}
            isSelected={conversation.conversation.conversationId === selectedId}
            onClick={() => onConversationClick(conversation.conversation.conversationId)}
          />
        ))}
    </Container>
  );
};
