import { createContext, useContext, useEffect, useState } from 'react';

import { itemService } from 'services/Item/itemService';
import { ItemConditionDto } from 'services/Item/itemService.dto';
import { paymentService } from 'services/Payment/paymentService';
import { PaymentConfigDto } from 'services/Payment/paymentService.dto';
import { reportService } from 'services/Report/reportService';
import { ReasonDto } from 'services/Report/reportService.dto';
import { userService } from 'services/User/userService';
import { CurrentUserDto } from 'services/User/userService.dto';

import { useAuth } from './AuthProvider';

interface GlobalDataContextType {
  itemConditions: ItemConditionDto[];
  reportReasons: ReasonDto[];
  currentUser: CurrentUserDto | null;
  paymentConfig: PaymentConfigDto | undefined;
}

const GlobalDataContext = createContext<GlobalDataContextType>(null!);

function GlobalDataProvider({ children }: any) {
  const { logged } = useAuth();

  const [itemConditions, setItemConditions] = useState<ItemConditionDto[]>([]);
  const [reportReasons, setReportReasons] = useState<ReasonDto[]>([]);
  const [currentUser, setCurrentUser] = useState<CurrentUserDto | null>(null);
  const [paymentConfig, setPaymentConfig] = useState<PaymentConfigDto | undefined>(undefined);

  useEffect(() => {
    fetchItemConditions();
    fetchReportReasons();
    fetchPaymentConfig();
  }, []);

  useEffect(() => {
    if (logged) {
      fetchCurrentUser();
    } else {
      setCurrentUser(null);
    }
  }, [logged]);

  const fetchCurrentUser = () => {
    userService
      .fetchCurrentUser()
      .then(({ data }) => setCurrentUser(data))
      .catch(_ => setCurrentUser(null)); // TODO: how to handle errors
  };

  const fetchItemConditions = () => {
    itemService
      .fetchConditions()
      .then(response => setItemConditions(response.data))
      .catch(e => setItemConditions([]));
  };

  const fetchReportReasons = () => {
    reportService
      .fetchReasons()
      .then(response => setReportReasons(response.data))
      .catch(e => setReportReasons([]));
  };

  const fetchPaymentConfig = () => {
    paymentService
      .fetchClientConfiguration('USD')
      .then(response => setPaymentConfig(response.data))
      .catch(e => setPaymentConfig(undefined));
  };

  return (
    <GlobalDataContext.Provider value={{ itemConditions, reportReasons, currentUser, paymentConfig }}>
      {children}
    </GlobalDataContext.Provider>
  );
}

const useGlobalData = () => useContext(GlobalDataContext);

export { GlobalDataProvider, useGlobalData };
