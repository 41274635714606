import { useState } from 'react';

import { isPaymentTokenData, PaymentTokenData } from 'features/checkout/social-media/socialMedia.types';
import { useGlobalError } from 'hooks/useGlobalError';
import { PurchaseDto } from 'services/Order/orderService.dto';
import { paymentService } from 'services/Payment/paymentService';
import {
  NewCardTransactionDto,
  NewTokenTransactionDto,
  PaymentMethodDto,
  TransactionDto,
} from 'services/Payment/paymentService.dto';

import { CheckoutResult } from './checkoutResult.types';
import { isPaymentDeclined, isPaymentFailed } from './paymentErrorUtils';
import { orderConfirmationError, paymentError, paymentSuccess } from './paymentResult';
import { usePurchaseError } from './usePurchaseError';

export const usePurchasePayment = (
  confirmPurchase: () => Promise<PurchaseDto | undefined>,
  resetPurchase: () => void,
  onPurchasePaid: (purchase: PurchaseDto, transaction: TransactionDto) => void
) => {
  const { paymentDeclined, orderDeclined } = usePurchaseError();
  const { unknownError } = useGlobalError();

  const [isProcessingPayment, setProcessingPayment] = useState(false);

  const confirmCheckout = async (paymentData: PaymentMethodDto | PaymentTokenData): Promise<CheckoutResult> => {
    try {
      setProcessingPayment(true);

      const confirmedPurchase = await confirmPurchase();
      if (!confirmedPurchase) {
        return orderConfirmationError();
      } else {
        const transaction = await payForPurchase(confirmedPurchase, paymentData);
        onPurchasePaid(confirmedPurchase, transaction);
        return paymentSuccess(transaction);
      }
    } catch (error) {
      handleTransactionError(error);
      return paymentError(error);
    } finally {
      setProcessingPayment(false);
    }
  };

  const payForPurchase = async (purchase: PurchaseDto, data: PaymentMethodDto | PaymentTokenData) => {
    if (isPaymentTokenData(data)) {
      return payWithToken(purchase, data);
    } else {
      return payWithCard(purchase, data);
    }
  };

  const payWithCard = async (purchase: PurchaseDto, paymentMethod: PaymentMethodDto) => {
    const transactionRequest: NewCardTransactionDto = { orderId: purchase.id, paymentMethodId: paymentMethod.id };
    const { data: transaction } = await paymentService.createCardTransaction(transactionRequest);
    return transaction;
  };

  const payWithToken = async (purchase: PurchaseDto, tokenData: PaymentTokenData) => {
    const { payment, billingData } = tokenData;
    const transactionRequest: NewTokenTransactionDto = { orderId: purchase.id, payment, billingData };
    const { data: transaction } = await paymentService.createTokenTransaction(transactionRequest);
    return transaction;
  };

  const handleTransactionError = (error: any) => {
    if (isPaymentDeclined(error)) {
      paymentDeclined();
      resetPurchase();
    } else if (isPaymentFailed(error)) {
      orderDeclined();
      resetPurchase();
    } else {
      unknownError();
    }
  };

  return { confirmCheckout, isProcessingPayment };
};
