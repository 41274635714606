import { useEffect, useState } from 'react';

import { campaignService } from './campaignService';
import { CampaignDto } from './campaignService.dto';

type CampaignState = {
  campaigns: CampaignDto[];
  page: number;
  loading: boolean;
  error: boolean;
  hasNextPage: boolean;
};

const initialState: CampaignState = {
  campaigns: [],
  page: 0,
  loading: false,
  error: false,
  hasNextPage: true,
};

interface Options {
  pageSize?: number;
}

const DEFAULT_PAGE_SIZE = 10;

const useMyCampaigns = (options?: Options) => {
  const pageSize = options?.pageSize ?? DEFAULT_PAGE_SIZE;
  const [state, setState] = useState<CampaignState>(initialState);

  useEffect(() => {
    if (state === initialState) {
      fetchNextPage();
    }
  }, [state]);

  const loadData = async () => {
    const response = await campaignService.fetchMyCampaigns(state.page, pageSize);
    const { content, last } = response.data;
    return { data: content || [], last };
  };

  const fetchNextPage = async () => {
    if (!state.hasNextPage || state.loading) return;

    setState(prevState => ({ ...prevState, loading: true }));

    try {
      const { data, last } = await loadData();
      setState(prevState => ({
        ...prevState,
        campaigns: prevState.page === 0 ? data : [...prevState.campaigns, ...data],
        hasNextPage: !last,
        page: prevState.page + 1,
      }));
    } catch (error) {
      setState(prevState => ({ ...prevState, error: true }));
    } finally {
      setState(prevState => ({ ...prevState, loading: false }));
    }
  };

  const refresh = () => {
    setState(initialState);
  };

  return {
    fetchNextPage,
    state,
    refresh,
  };
};

export default useMyCampaigns;
