import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { PageWrapper } from 'features/my-profile/components/PageWrapper';

import { CampaignsSection } from './components/CampaignsSection';
import { HotDealsSection } from './components/HotDealsSection';
import { UserDetails } from './components/UserDetails';
import { UserSummary } from './components/UserSummary';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 48px;

  ${theme.mq.huge} {
    gap: 24px;
  }

  ${theme.mq.phone} {
    flex-direction: column;
  }
`;

const ContentContainer = styled.div`
  flex: 1;
`;

const RightContainer = styled.div`
  width: 100%;
  max-width: 360px;

  ${theme.mq.phone} {
    max-width: 100%;
  }
`;

export const MyActivityPage = () => {
  return (
    <PageWrapper testId={'my-profile-my-activity'}>
      <Container>
        <ContentContainer>
          <UserDetails />
          <CampaignsSection />
          <HotDealsSection />
        </ContentContainer>
        <RightContainer>
          <UserSummary />
        </RightContainer>
      </Container>
    </PageWrapper>
  );
};
