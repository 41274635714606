import { Marker } from '@react-google-maps/api';

import { theme } from 'assets/styles/theme';

interface Props {
  position: { lat: number; lng: number };
  icon?: string;
  label?: string;
  onClick?: () => void;
  clusterer?: any;
}

const MessageClusteredMarker = ({ position, icon, label, onClick, clusterer }: Props) => {
  return (
    <Marker
      clusterer={clusterer}
      onClick={onClick}
      position={position}
      options={{
        icon: icon,
        ...(label ? { label: { color: `${theme.color.white}`, text: label, fontWeight: 'bold' } } : {}),
      }}
    />
  );
};

export default MessageClusteredMarker;
