import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import GrayButton from 'components/buttons/GrayButton';
import WhiteButton from 'components/buttons/WhiteButton';
import { H4 } from 'components/typography/Headers';
import { TextButton2 } from 'components/typography/TextButtons';
import { useLoginWithEmailPopup } from 'features/account/login-email/useLoginWithEmailPopup';
import PrivacyAndTerms from 'features/account/privacy/PrivacyAndTerms';
import { useContinueSignUp } from 'features/account/sign-up/useContinueSignUp';
import { useSignUpPopup } from 'features/account/sign-up/useSignUpPopup';
import FacebookLoginButton from 'features/account/social-media/FacebookLoginButton';
import GoogleLoginButton from 'features/account/social-media/GoogleLoginButton';
import { useSocialMediaLogin } from 'features/account/social-media/useSocialMediaLogin';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 24px;
`;

const Header = styled(H4)`
  margin-bottom: 46px;
`;

const EmailCodeButton = styled(TextButton2)`
  padding: 12px 0;
`;

interface Props {
  onLogged: () => void;
}

const LoginPopup = ({ onLogged }: Props) => {
  const { t } = useTranslation('account');
  const { showLoginWithEmailPopup } = useLoginWithEmailPopup();
  const { hasLastSignUpEmail, continueVerification } = useContinueSignUp();
  const { loginWithFacebook, loginWithGoogle } = useSocialMediaLogin(onLogged);
  const { showSignUpPopup } = useSignUpPopup();

  return (
    <Container data-testid={'login-popup'}>
      <Header data-testid={'header'}>{t('login.header')}</Header>
      <ButtonsContainer>
        <FacebookLoginButton onAccessToken={loginWithFacebook} />
        <GoogleLoginButton onAccessToken={loginWithGoogle} />
        <WhiteButton data-testid={'email-button'} onClick={showLoginWithEmailPopup}>
          {t('login.email-button')}
        </WhiteButton>
        {hasLastSignUpEmail && (
          <EmailCodeButton data-testid={'email-code-button'} onClick={continueVerification}>
            {t('login.email-code-button')}
          </EmailCodeButton>
        )}
        <GrayButton data-testid={'sign-up-button'} onClick={() => showSignUpPopup(onLogged)}>
          {t('login.sign-up-button')}
        </GrayButton>
      </ButtonsContainer>
      <PrivacyAndTerms />
    </Container>
  );
};

export default LoginPopup;
