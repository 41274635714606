import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Caption } from 'components/typography/Texts';
import calculateDaysLeft from 'utils/daysLeftUtils';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StatsContainer = styled.div`
  display: flex;
  gap: 5px;
`;

const ValueText = styled(Caption)`
  font-weight: bold;
`;

const SupportersContainer = styled(StatsContainer)`
  margin-left: auto;
`;

interface Props {
  timelineFrom: string;
  timelineTo?: string;
  supportersNumber: number;
  status: string;
}

const CampaignStats = ({ timelineFrom, timelineTo, supportersNumber, status }: Props) => {
  const { t } = useTranslation('campaign');
  const days = calculateDaysLeft(timelineFrom, timelineTo);
  const displayDays = days != null && status === 'ACTIVE';
  const daysText = displayDays && t('days-left', { count: days });

  const displaySupporters = supportersNumber != null;
  const supportersText = displaySupporters ? t('supporters', { count: supportersNumber }) : '';

  return (
    <Container>
      {displayDays && (
        <StatsContainer data-testid={'campaign-days-left'}>
          <ValueText data-testid={'value'}>{days}</ValueText>
          <Caption>{daysText}</Caption>
        </StatsContainer>
      )}
      {supportersText && (
        <SupportersContainer data-testid={'campaign-supporters'}>
          <ValueText data-testid={'value'}>{supportersNumber}</ValueText>
          <Caption>{supportersText}</Caption>
        </SupportersContainer>
      )}
    </Container>
  );
};

export default CampaignStats;
