import styled from 'styled-components';

import profilePhotoPlaceholder from 'assets/svg/profile-placeholder.svg';
import vipIcon from 'assets/svg/vip-icon.svg';
import { convertToThumbnail300 } from 'utils/thumbnails';

const ProfilePhoto = styled.img`
  overflow: hidden;
  height: 20%;
  width: 20%;
  aspect-ratio: 1;
  border-radius: 50%;
  object-fit: cover;
`;

const VipIconContainer = styled.img`
  position: absolute;
  left: 18%;
  top: 5%;
`;

interface ProfilePhotoWrapperProps {
  photo?: string;
  isVip: boolean;
}

const ProfilePhotoWrapper = (props: ProfilePhotoWrapperProps) => {
  const { photo, isVip } = props;

  return (
    <>
      <ProfilePhoto src={photo ? convertToThumbnail300(photo) : profilePhotoPlaceholder} />
      {isVip && <VipIconContainer src={vipIcon} />}
    </>
  );
};

export default ProfilePhotoWrapper;
