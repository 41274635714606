import styled from 'styled-components';

import LikedIconSource from 'assets/svg/liked.svg';
import UnlikedIconSource from 'assets/svg/unliked.svg';

const Image = styled.img`
  width: 22px;
  height: 22px;
`;

interface Props {
  selected: boolean;
}

const HeartButton = ({ selected }: Props) => {
  return <Image src={selected ? LikedIconSource : UnlikedIconSource} />;
};

export default HeartButton;
