import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import arrowDownIcon from 'assets/svg/arrow-down.svg';
import searchIcon from 'assets/svg/search.svg';
import { RowContainer } from 'components/containers/Containers';
import { Text2 } from 'components/typography/Texts';
import { useModal } from 'providers/ModalProvider';

const Container = styled(RowContainer)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  padding: 0 20px;
  border-radius: 25px;
  background-color: ${theme.color.lighterGray};
  cursor: pointer;
`;

const SearchIcon = styled.img`
  margin-right: 15px;
  width: 13px;
`;

const SelectedAreaText = styled(Text2)`
  line-height: 30px;
  padding: 0 20px;
  border-color: ${theme.color.darkGray};
  border-left: 1px solid ${theme.color.darkGray};
`;

const SearchBox = () => {
  const { t } = useTranslation();
  const { showGetApp } = useModal();

  const displayGetApp = () => {
    showGetApp({ description: t('navbar.search') });
  };

  return (
    <Container onClick={displayGetApp}>
      <RowContainer>
        <SearchIcon src={searchIcon} />
        <Text2>{t('navbar.search')}</Text2>
      </RowContainer>
      <RowContainer>
        <SelectedAreaText>{t('navbar.campaigns')}</SelectedAreaText>
        <img src={arrowDownIcon} alt={''} />
      </RowContainer>
    </Container>
  );
};

export default SearchBox;
