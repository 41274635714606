import styled from 'styled-components';

import RedButton from 'components/buttons/RedButton';
import { H4 } from 'components/typography/Headers';
import { Text1, Text1Bold } from 'components/typography/Texts';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled(H4)`
  margin-bottom: 18px;
`;

const Description = styled(Text1)`
  margin-bottom: 36px;
`;

const Footer = styled(Text1Bold)`
  margin-bottom: 36px;
`;

const Button = styled(RedButton)`
  width: 200px;
  margin: 0 12px;
`;

const ButtonsWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

interface Props {
  header: string;
  description?: string;
  footer?: string;
  firstButton: { label: string; onClick: () => void };
  secondButton?: { label: string; onClick: () => void };
  testId?: string;
}

const AlertPopup = ({ header, description, footer, firstButton, secondButton, testId }: Props) => {
  return (
    <Container data-testid={testId}>
      <Header data-testid={'alert-header'}>{header}</Header>
      {!!description && <Description data-testid={'alert-description'}>{description}</Description>}
      {!!footer && <Footer data-testid={'alert-footer'}>{footer}</Footer>}
      <ButtonsWrapper>
        <Button data-testid={'alert-first-button'} onClick={firstButton.onClick}>
          {firstButton.label}
        </Button>
        {secondButton && (
          <Button data-testid={'alert-second-button'} onClick={secondButton.onClick}>
            {secondButton.label}
          </Button>
        )}
      </ButtonsWrapper>
    </Container>
  );
};

export default AlertPopup;
