import { useEffect, useState } from 'react';

import { itemService } from './Item/itemService';
import { TrackedItemDto } from './Item/itemService.dto';
import { withAuctionsForBulkItems } from './Item/withAuctionsData';
import { orderService } from './Order/orderService';
import { sortByIds } from './Search/searchSorting';

type ItemState = {
  items: TrackedItemDto[];
  page: number;
  loading: boolean;
  error: boolean;
  hasNextPage: boolean;
};

const initialState: ItemState = {
  items: [],
  page: 0,
  loading: false,
  error: false,
  hasNextPage: true,
};

export enum ItemFilter {
  BOUGHT = 'bought',
  SELLING = 'selling',
  DONATED = 'donated',
}

interface Options {
  filter: ItemFilter;
  pageSize?: number;
}

const DEFAULT_PAGE_SIZE = 20;
const DEFAULT_ITEM_FILTER = ItemFilter.SELLING;

const useMyItems = (options?: Options) => {
  const pageSize = options?.pageSize ?? DEFAULT_PAGE_SIZE;
  const filter = options?.filter ?? DEFAULT_ITEM_FILTER;

  const [state, setState] = useState<ItemState>(initialState);

  useEffect(() => {
    if (state === initialState) {
      fetchNextPage();
    }
  }, [state]);

  useEffect(() => {
    if (state !== initialState) {
      refresh();
    }
  }, [filter]);

  const loadData = async () => {
    if (filter === 'bought') {
      const response = await orderService.fetchMyBoughtItems(state.page, pageSize);
      const { content, last } = response.data;
      const itemIds = content?.map(item => item.itemId);
      const itemDetails = await withAuctionsForBulkItems(() => itemService.fetchBulkItemsDetails(itemIds!!));
      const sortedItemDetails = sortByIds(itemIds!!, itemDetails.data);

      const itemsWithOrderId: TrackedItemDto[] = [];
      content?.forEach(orderItem => {
        const itemDetail = sortedItemDetails.find(item => item.id === orderItem.itemId);
        if (itemDetail) {
          itemsWithOrderId.push({ ...itemDetail, orderId: orderItem.orderId });
        }
      });

      return { data: itemsWithOrderId || [], last };
    } else if (filter === 'selling') {
      const response = await itemService.fetchMySelling(state.page, pageSize);
      const { content, last } = response.data;
      return { data: content || [], last };
    } else if (filter === 'donated') {
      const response = await orderService.fetchMySoldItems(state.page, pageSize);
      const { content, last } = response.data;
      const itemIds = content?.map(item => item.itemId);
      const itemDetails = await withAuctionsForBulkItems(() => itemService.fetchBulkItemsDetails(itemIds!!));
      const sortedItemDetails = sortByIds(itemIds!!, itemDetails.data);
      return { data: sortedItemDetails || [], last };
    } else {
      return { data: [], last: true };
    }
  };

  const fetchNextPage = async () => {
    if (!state.hasNextPage || state.loading) return;

    setState(prevState => ({ ...prevState, loading: true }));

    try {
      const { data, last } = await loadData();
      setState(prevState => ({
        ...prevState,
        items: state.page === 0 ? data : [...prevState.items, ...data],
        hasNextPage: !last,
        page: prevState.page + 1, // Increment page number
      }));
    } catch (error) {
      setState(prevState => ({ ...prevState, error: true }));
    } finally {
      setState(prevState => ({ ...prevState, loading: false }));
    }
  };

  const refresh = () => {
    setState(initialState);
  };

  return {
    fetchNextPage,
    state,
    refresh,
  };
};

export default useMyItems;
