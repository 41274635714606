import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { useContact } from 'features/messenger/hooks/useContact';
import { usePublicUserData } from 'services/usePublicUserData';

import ContactSellerButton from './ContactSellerButton';
import SellerInfo from './SellerInfo';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  border: 1px solid ${theme.color.darkGray};
  background-color: ${theme.color.white};
  padding: 24px;
  border-radius: 5px;
  gap: 24px;
`;

export interface Props {
  sellerId: number;
  itemId: number;
}

const ContactSellerSection = ({ sellerId, itemId }: Props) => {
  const { content: seller } = usePublicUserData(sellerId);
  const { contactOwner } = useContact('ITEM', sellerId, itemId);

  return (
    <Container>
      <SellerInfo seller={seller} />
      <ContactSellerButton onClick={contactOwner} />
    </Container>
  );
};

export default ContactSellerSection;
