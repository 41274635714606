import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { PhoneInputSection } from 'components/containers/PhoneInputSection';
import { ScreenContainer } from 'components/containers/ScreenContainer';
import { H2 } from 'components/typography/Headers';
import QRCodeComponent from 'components/ui-elements/QRCodeComponent';

const Container = styled.div`
  margin: 30px auto;
  width: 70%;
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  padding-bottom: 30px;
  align-items: center;
  border: solid 1px ${theme.color.borderGray};
  border-radius: 5px;
`;

export const GivePhone = () => {
  const { t } = useTranslation('user');
  return (
    <ScreenContainer>
      <Container>
        <H2>{t('give-phone.title')}</H2>
        <QRCodeComponent hasAnnotatedDescription={true} />
        <PhoneInputSection />
      </Container>
    </ScreenContainer>
  );
};
