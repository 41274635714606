import { useFullScreen } from 'hooks/useFullScreen';

import { CarouselProps } from './carousel.types';
import PhotoDisplay from './PhotoDisplay';
import SlidingCarousel from './SlidingCarousel';
import { resolveSlidesLength } from './utils/carouselUtils';

const Carousel = (props: CarouselProps) => {
  const slidesLength = resolveSlidesLength(props);

  const fullScreenProps = useFullScreen();

  return (
    <>
      {slidesLength > 1 ? (
        <SlidingCarousel {...props} {...fullScreenProps} />
      ) : (
        <PhotoDisplay {...props} {...fullScreenProps} />
      )}
    </>
  );
};

export default Carousel;
