import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAlertPopup } from 'hooks/useAlertPopup';

export const usePurchaseError = () => {
  const { showAlertPopup } = useAlertPopup();
  const navigate = useNavigate();
  const { t } = useTranslation('checkout');

  const itemNotAvailable = () =>
    showAlertPopup({
      header: t('error.item-not-available-header'),
      description: t('error.item-not-available-message'),
      firstButton: { label: t('error.item-not-available-button'), onClick: goToMarketplace },
      testId: 'item-not-available-alert',
    });

  const orderDeclined = () =>
    showAlertPopup({
      header: t('error.order-declined-header'),
      description: t('error.order-declined-message'),
      firstButton: { label: t('error.order-declined-button') },
      testId: 'order-declined-alert',
    });

  const paymentDeclined = () =>
    showAlertPopup({
      header: t('error.payment-declined-header'),
      description: t('error.payment-declined-message'),
      firstButton: { label: t('error.payment-declined-button') },
      testId: 'payment-declined-alert',
    });

  const goToMarketplace = () => navigate('/');

  return { itemNotAvailable, orderDeclined, paymentDeclined };
};
