import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';

import { useAlertPopup } from 'hooks/useAlertPopup';
import { useGlobalError } from 'hooks/useGlobalError';
import { useNotification } from 'providers/NotificationProvider';

export const useMessengerAlert = () => {
  const { unknownError } = useGlobalError();
  const { showAlertPopup } = useAlertPopup();
  const { showNotification } = useNotification();
  const { t } = useTranslation('notifications');

  const handleMessengerActionError = (error: AxiosError) => {
    if (error.response?.status === 400) {
      invalidRequest();
    } else {
      unknownError();
    }
  };

  const invalidRequest = () => {
    showNotification({
      title: t('invalid-action'),
      type: 'error',
    });
  };

  const handleMessengerAlert = (
    header: string,
    firstButton: { label: string; onClick?: () => void },
    secondButton?: { label: string; onClick?: () => void },
    description?: string
  ) => {
    showAlertPopup({
      header: header,
      description: description,
      firstButton: firstButton,
      secondButton: secondButton,
      testId: 'custom-request-messenger-alert',
    });
  };

  return { handleMessengerActionError, handleMessengerAlert };
};
