import { createContext, FC, ReactNode, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { MessageDialogButton } from 'features/messenger/components/NotificationMessage';
import { NotificationInputType } from 'providers/utils/notifications/notificationInputType';

import { useModal } from './ModalProvider';
import {
  createButtonLabel,
  createDescription,
  createPlaceholder,
  createTitle,
  notificationActions,
  notificationRefs,
} from './utils/notifications/notificationActions';
import { NotificationType, SYSTEM_NOTIFICATION_TYPES } from './utils/notifications/notificationType';

export interface NotificationContent {
  title: string;
  description?: string;
  type: NotificationType;
  autoHide?: boolean;
  firstButton?: MessageDialogButton;
  secondButton?: MessageDialogButton;
  inputType?: NotificationInputType;
  priceInputPlaceholder?: string;
  callback?: () => void;
  attributes?: any;
  messengerOnly?: boolean;
}

export interface NotificationData {
  id: string;
  content: NotificationContent;
}

interface NotificationContextType {
  notifications: NotificationData[];
  showNotification: (content: NotificationContent) => void;
  onClose: (idToRemove: string) => void;
  createInfoNotificationFromPayload: (
    notificationPayloadType: string,
    attributes?: any,
    callback?: (data?: any) => void
  ) => NotificationContent;
  isCustomNotification: (notificationPayloadType: string) => boolean;
}
export const NotificationContext = createContext<NotificationContextType>(null!);

const SHOW_NOTIFICATION_DISABLED_ROUTES = ['/messenger'];

const NotificationProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [notifications, setNotifications] = useState<NotificationData[]>([]);
  const { showGetApp, showShare } = useModal();
  const navigate = useNavigate();

  const { t } = useTranslation('notifications');

  const canShowNotification = (notification: NotificationData) => {
    const isDisabledRoute = SHOW_NOTIFICATION_DISABLED_ROUTES.some(route => window.location.pathname.startsWith(route));
    return SYSTEM_NOTIFICATION_TYPES.includes(notification.content.type) || !isDisabledRoute;
  };

  const setSingleNonSystemNotification = (notification: NotificationData) => {
    setNotifications((prevNotifications: NotificationData[]) => [
      ...prevNotifications.filter(it => SYSTEM_NOTIFICATION_TYPES.includes(it.content.type)),
      notification,
    ]);
  };

  const addNotification = (notification: NotificationData) => {
    if (!canShowNotification(notification)) return;

    if (!SYSTEM_NOTIFICATION_TYPES.includes(notification.content.type)) {
      setSingleNonSystemNotification(notification);
    } else {
      setNotifications((prevNotifications: NotificationData[]) => [...prevNotifications, notification]);
    }
  };

  const showNotification = (content: NotificationContent) => {
    if (content?.messengerOnly) return;
    const notification: NotificationData = {
      id: uuid(),
      content: { autoHide: true, ...content },
    };
    addNotification(notification);
  };

  const showError = (title?: string, description?: string) => {
    const notification: NotificationData = {
      id: uuid(),
      content: { autoHide: true, title: title || t('invalid-action'), description, type: 'error' },
    };
    addNotification(notification);
  };

  const showSuccess = (title?: string, description?: string) => {
    const notification: NotificationData = {
      id: uuid(),
      content: { autoHide: true, title: title || t('success-action'), description, type: 'success' },
    };
    addNotification(notification);
  };

  const actions = notificationActions({
    showGetApp,
    showError,
    showSuccess,
    navigate,
    showShare,
  });

  const isCustomNotification = (notificationPayloadType: string) => {
    const notificationDefinition = notificationRefs().get(notificationPayloadType);
    return !!notificationDefinition && !!notificationDefinition.customWarning;
  };
  const createInfoNotification = (
    notificationPayloadType: string,
    attributes?: any,
    callback?: (data?: any) => void
  ): NotificationContent => {
    const notificationDefinition = notificationRefs().get(notificationPayloadType);

    if (!notificationDefinition) return createInfoNotification('DEFAULT');

    const buttons: MessageDialogButton[] = notificationDefinition.actions.map((action, index) => ({
      label: createButtonLabel(notificationPayloadType, index),
      action: actions.get(action)!,
    }));

    const title = createTitle(notificationPayloadType, attributes);
    const description = createDescription(notificationPayloadType, attributes);
    const placeholder = createPlaceholder(notificationPayloadType);
    return {
      title,
      description,
      firstButton: buttons.length > 0 ? buttons[0] : undefined,
      secondButton: buttons.length > 1 ? buttons[1] : undefined,
      type: 'info',
      inputType: notificationDefinition.inputType || 'Text',
      callback,
      attributes,
      messengerOnly: notificationDefinition.messengerOnly,
      priceInputPlaceholder: placeholder,
    };
  };

  const onClose = (idToRemove: string) => {
    setNotifications(prevNotifications => prevNotifications.filter(notification => notification.id !== idToRemove));
  };

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        showNotification,
        onClose,
        createInfoNotificationFromPayload: createInfoNotification,
        isCustomNotification,
      }}>
      {children}
    </NotificationContext.Provider>
  );
};

const useNotification = () => useContext(NotificationContext);

export { NotificationProvider, useNotification };
