import AlertPopup from 'components/popups/AlertPopup';

import { usePopup } from './usePopup';

interface AlertPopupData {
  header: string;
  description?: string;
  footer?: string;
  firstButton: { label: string; onClick?: () => void };
  secondButton?: { label: string; onClick?: () => void };
  testId?: string;
}

export const useAlertPopup = () => {
  const { showPopup, hidePopup } = usePopup();

  const showAlertPopup = ({ header, description, footer, firstButton, secondButton, testId }: AlertPopupData) =>
    showPopup(
      <AlertPopup
        header={header}
        description={description}
        footer={footer}
        firstButton={adjustButton(firstButton)}
        secondButton={!!secondButton ? adjustButton(secondButton) : secondButton}
        testId={testId}
      />,
      { keepPopups: true, hideClose: true }
    );

  const adjustButton = (button: { label: string; onClick?: () => void }) => ({
    label: button.label,
    onClick: () => {
      hidePopup();
      button.onClick?.();
    },
  });

  return { showAlertPopup };
};
