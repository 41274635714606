import moment from 'moment';

// timelineTo comes as UTC date from backend
const calculateDaysLeft = (timelineFrom: string | undefined, timelineTo: string | undefined): number | undefined => {
  if (timelineTo) {
    const startDate = moment.utc(timelineFrom); // start of day
    const endDate = moment.utc(timelineTo); // start of day
    const now = moment().startOf('day');

    const begin = now.isAfter(startDate) ? now : startDate;

    const daysDiff = endDate.diff(begin, 'days') + 1;

    if (isNaN(daysDiff) || daysDiff <= 0) {
      return 0;
    } else {
      return daysDiff;
    }
  }
};

export default calculateDaysLeft;
