import { useTranslation } from 'react-i18next';

import { useNotification } from 'providers/NotificationProvider';

export const usePlaceBidNotifications = () => {
  const { t } = useTranslation('item');
  const { showNotification } = useNotification();

  const bidAdded = () => {
    showNotification({
      title: t('place-bid-notification.bid-added'),
      type: 'success',
    });
  };

  const amountTooLow = () => {
    showNotification({
      title: t('place-bid-notification.amount-too-low-title'),
      description: t('place-bid-notification.amount-too-low-description'),
      type: 'error',
    });
  };

  const auctionExpired = () => {
    showNotification({
      title: t('place-bid-notification.auction-expired-title'),
      description: t('place-bid-notification.auction-expired-description'),
      type: 'error',
    });
  };

  const amountOverLimit = () => {
    showNotification({
      title: t('place-bid-notification.amount-over-limit-title'),
      description: t('place-bid-notification.amount-over-limit-description'),
      type: 'error',
    });
  };

  const unknownError = () => {
    showNotification({
      title: t('unknown-error-title'),
      description: t('unknown-error-description'),
      type: 'error',
    });
  };

  return { bidAdded, amountTooLow, auctionExpired, amountOverLimit, unknownError };
};
