import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useVerifyEmailPopup } from 'features/account/verify-email/useVerifyEmailPopup';
import { useGlobalError } from 'hooks/useGlobalError';
import { userService } from 'services/User/userService';
import { storeLastSignUpEmail } from 'utils/localStorageData';

import { useSignUpError } from './useSignUpError';

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordConfirmation: string;
}

interface Props {
  onSuccess?: () => void;
}

export const useSignUpForm = ({ onSuccess }: Props = {}) => {
  const { t } = useTranslation('account');
  const { accountExists, inactiveAccount, restrictedName } = useSignUpError();
  const { unknownError } = useGlobalError();
  const { showVerifyEmailPopup } = useVerifyEmailPopup();

  const initialValues: FormData = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    passwordConfirmation: '',
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .required(t('sign-up.first-name-required'))
      .matches(/^[a-zA-Z0-9\s']+$/, t('sign-up.unallowed-chars')),
    lastName: Yup.string()
      .trim()
      .required(t('sign-up.last-name-required'))
      .matches(/^[a-zA-Z0-9\s']+$/, t('sign-up.unallowed-chars')),
    email: Yup.string().trim().required(t('sign-up.email-required')).email(t('sign-up.email-invalid')),
    password: Yup.string()
      .ensure()
      .required(t('sign-up.password-required'))
      .min(8, t('sign-up.password-too-short'))
      .test('test ascii', t('sign-up.password-invalid-chars'), pass => {
        return ![...pass].some((char: string) => char.charCodeAt(0) > 127);
      })
      .test('test trailing spaces', t('sign-up.password-trailing-space'), pass => {
        return !pass.startsWith(' ') && !pass.endsWith(' ');
      }),
    passwordConfirmation: Yup.string()
      .required(t('sign-up.password-required'))
      .oneOf([Yup.ref('password')], t('sign-up.password-no-match')),
  });

  const onSubmit = async (data: FormData) => {
    const firstName = data.firstName.trim();
    const lastName = data.lastName.trim();
    const email = data.email.trim().toLowerCase();
    const password = data.password.trim();

    try {
      await userService.signUp({ firstName, lastName, email, password });
      storeLastSignUpEmail(email);
      showVerifyEmailPopup(email, password, onSuccess);
    } catch (error) {
      handleError(email, error);
    }
  };

  const handleError = (email: string, error: any) => {
    if (error.response?.status === 409 && error.response.data.userConfirmed) {
      accountExists();
    } else if (error.response?.status === 409 && !error.response.data.userConfirmed) {
      inactiveAccount(email);
    } else if (error.response?.status === 422 && error.response.data.code === 'NAME') {
      restrictedName();
    } else {
      unknownError();
    }
  };

  return useFormik({ initialValues, validationSchema, onSubmit });
};
