import { useEffect, useRef } from 'react';
import GooglePayButtonReact, { ReadyToPayChangeResponse } from '@google-pay/button-react';
import styled from 'styled-components';

import { PaymentAuthorizedHandler, PaymentButtonProps } from 'features/checkout/social-media/socialMedia.types';

import { preparePaymentRequest } from './paymentRequest';
import { convertPaymentResult } from './paymentResultConverter';
import { useAuthorizationRequest } from './useAuthorizationRequest';

const Container = styled.div`
  position: relative;
`;

const StyledGooglePayButtonReact = styled(GooglePayButtonReact)`
  width: 100%;
  height: 52px;

  & .gpay-button .gpay-button.donate {
    padding: 0 !important;
  }

  & .gpay-button .gpay-button.black {
    padding: 0 !important;
  }

  & .gpay-button-fill {
    padding: 0 !important;
  }

  & .gpay-button {
    padding: 11px 22px !important;
  }
`;

const environment = process.env.REACT_APP_GOOGLE_PAY_ENVIRONMENT as google.payments.api.Environment;

const GooglePayButton = ({ amount, currency, paymentId, onStateChange, onPaymentAuthorized }: PaymentButtonProps) => {
  const authorizationRequest = useAuthorizationRequest(amount, currency);
  const onPaymentAuthorizedRef = useRef<PaymentAuthorizedHandler>(onPaymentAuthorized);

  useEffect(() => {
    onPaymentAuthorizedRef.current = onPaymentAuthorized;
  }, [onPaymentAuthorized]);

  const handleAuthorization = async (paymentData: google.payments.api.PaymentData) => {
    const paymentRequest = preparePaymentRequest(paymentData, paymentId);
    const paymentResult = await onPaymentAuthorizedRef.current(paymentRequest);
    return convertPaymentResult(paymentResult);
  };

  const handleStateChange = (state: ReadyToPayChangeResponse) => {
    onStateChange(state.isButtonVisible ? 'ready' : 'notAvailable');
  };

  return (
    <Container>
      <StyledGooglePayButtonReact
        environment={environment}
        buttonSizeMode='fill'
        buttonType='buy'
        buttonColor='black'
        buttonRadius={5}
        paymentRequest={authorizationRequest}
        onPaymentAuthorized={handleAuthorization}
        onReadyToPayChange={handleStateChange}
        buttonLocale={'en'}
      />
    </Container>
  );
};

export default GooglePayButton;
