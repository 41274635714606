import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import GrayButton from 'components/buttons/GrayButton';
import RedButton from 'components/buttons/RedButton';
import PasswordInputField from 'components/inputs/PasswordInputField';
import TextInputField from 'components/inputs/TextInputField';
import { H4 } from 'components/typography/Headers';
import { useLoginWithEmailForm } from 'features/account/login-email/useLoginWithEmailForm';
import PrivacyAndTerms from 'features/account/privacy/PrivacyAndTerms';
import { useSignUpPopup } from 'features/account/sign-up/useSignUpPopup';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled(H4)`
  margin-bottom: 46px;
`;

const EmailInput = styled(TextInputField)`
  margin-bottom: 12px;
`;

const PasswordInput = styled(PasswordInputField)`
  margin-bottom: 12px;
`;

const LoginButton = styled(RedButton)`
  margin-bottom: 24px;
`;

const SignupButton = styled(GrayButton)`
  margin-bottom: 24px;
`;

interface Props {
  onLogged: () => void;
}

const LoginWithEmailPopup = ({ onLogged }: Props) => {
  const { values, handleChange, submitForm, errors, isValid, isSubmitting, dirty } = useLoginWithEmailForm(onLogged);
  const { showSignUpPopup } = useSignUpPopup();
  const { t } = useTranslation('account');

  return (
    <Container data-testid={'login-email-popup'}>
      <Header data-testid={'header'}>{t('login-email.header')}</Header>
      <EmailInput
        testId={'email-field'}
        value={values.email}
        placeholder={t('login-email.email-placeholder')}
        inputType={'email'}
        onChange={handleChange('email')}
        errorMessage={errors.email}
      />
      <PasswordInput
        testId={'password-field'}
        value={values.password}
        placeholder={t('login-email.password-placeholder')}
        onChange={handleChange('password')}
        errorMessage={errors.password}
      />
      <LoginButton
        data-testid={'login-button'}
        onClick={submitForm}
        disabled={!dirty || !isValid || isSubmitting}
        type={'submit'}>
        {t('login-email.login-button')}
      </LoginButton>
      <SignupButton data-testid={'sign-up-button'} onClick={() => showSignUpPopup(onLogged)}>
        {t('login-email.sign-up-button')}
      </SignupButton>
      <PrivacyAndTerms />
    </Container>
  );
};

export default LoginWithEmailPopup;
