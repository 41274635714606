import { ReactNode, useRef } from 'react';

import { usePopupManager } from 'providers/PopupManagerProvider';
import { PopupOptions } from 'providers/popupTypes';

export const usePopup = () => {
  const { showPopup: showPopupByManager, hidePopup: hidePopupByManager, popups } = usePopupManager();
  const popupId = useRef<string | null>(null);

  const showPopup = (content: ReactNode, options: PopupOptions = {}) => {
    if (!popupId.current) {
      const onAfterHide = () => {
        popupId.current = null;
        options.onAfterHide?.();
      };

      const popupWithOnClose = popups.find(popup => !!popup.onClose);
      const onClose = () => {
        options.onClose?.() || popupWithOnClose?.onClose?.();
      };

      popupId.current = showPopupByManager(content, { ...options, onAfterHide, onClose });
    }
  };

  const hidePopup = () => {
    if (popupId.current) {
      hidePopupByManager(popupId.current);
      popupId.current = null;
    }
  };

  return { showPopup, hidePopup };
};
