import { useTranslation } from 'react-i18next';

import { Text2 } from 'components/typography/Texts';
import { useGlobalData } from 'providers/GlobalDataProvider';
import { ItemDto } from 'services/Item/itemService.dto';

interface Props {
  item: ItemDto;
}

const ConditionInfo = ({ item }: Props) => {
  const { t } = useTranslation('item');
  const { itemConditions } = useGlobalData();

  if (item.itemType === 'SERVICE' || !item.condition) return <></>;

  const resolveItemCondition = (code: string) => itemConditions.find(it => it.code === code)?.displayName;
  return <Text2 data-testid={'condition'}>{t('condition', { condition: resolveItemCondition(item.condition) })}</Text2>;
};

export default ConditionInfo;
