import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { OpacityOverlay } from 'components/ui-elements/OpacityOverlay';
import { PaymentModule } from 'features/my-profile/payment/PaymentModule';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-self: flex-start;
`;

interface Props {
  isProcessing: boolean;
}

const PaymentSelection = ({ isProcessing }: Props) => {
  const { t } = useTranslation('checkout');

  return (
    <Container data-testid={'payment-selection'}>
      <PaymentModule header={t('item.payment-selection')} />
      {isProcessing && <OpacityOverlay data-testid={'opacity-overlay'} />}
    </Container>
  );
};

export default PaymentSelection;
