import { useVerifyEmailPopup } from 'features/account/verify-email/useVerifyEmailPopup';
import { useGlobalError } from 'hooks/useGlobalError';
import { userService } from 'services/User/userService';
import { storeLastSignUpEmail } from 'utils/localStorageData';

export const useEmailCodeRetry = () => {
  const { showVerifyEmailPopup } = useVerifyEmailPopup();
  const { unknownError } = useGlobalError();

  const retryVerification = async (email: string) => {
    try {
      await userService.resendActivationToken(email);
      storeLastSignUpEmail(email);
      showVerifyEmailPopup(email);
    } catch (error) {
      unknownError();
    }
  };

  return { retryVerification };
};
