import Select from 'react-select';
import styled from 'styled-components';

import BaseInputField, {
  BaseInputProps,
  containerStyle,
  fontStyle,
  inputStyle,
  placeholderStyle,
} from './BaseInputField';

const StyledSelect = styled(Select)<{ $error: boolean; $success: boolean }>`
  ${containerStyle};
  ${fontStyle};
  padding: 0;

  .react-select__control {
    ${inputStyle};
  }

  ::placeholder {
    ${placeholderStyle}
  }
`;

interface Option {
  value: string;
  label: string;
}

interface Props extends BaseInputProps {
  options: Option[];
}

const Dropdown = (props: Props) => {
  const { value, options, placeholder, errorMessage, successMessage, onChange } = props;
  const { className, testId, disabled } = props;

  // TODO
  const handleChange = (newValue: any) => {
    onChange(newValue ? newValue.value : '');
  };

  return (
    <BaseInputField testId={testId} className={className} successMessage={successMessage} errorMessage={errorMessage}>
      <StyledSelect
        className='react-select'
        classNamePrefix='react-select'
        menuPosition={'fixed'}
        value={options.find(option => option.value === value)}
        placeholder={placeholder}
        options={options}
        isSearchable={true}
        isMulti={false}
        onChange={handleChange}
        $error={!!errorMessage}
        $success={!!successMessage}
        isDisabled={disabled}
      />
    </BaseInputField>
  );
};

export default Dropdown;
