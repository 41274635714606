import { useState } from 'react';

import { useGlobalError } from 'hooks/useGlobalError';
import { useAuth } from 'providers/AuthProvider';
import { authService } from 'services/Auth/authService';
import { userService } from 'services/User/userService';
import { clearLastSignUpEmail } from 'utils/localStorageData';

type VerificationStatusType = 'notExecuted' | 'inProgress' | 'completed' | 'failed';

export const useVerifyEmail = (credentials: { email: string; password?: string }, onConfirmed: () => void) => {
  const [status, setStatus] = useState<VerificationStatusType>('notExecuted');

  const { unknownError } = useGlobalError();
  const { appLogin } = useAuth();

  const verifyEmail = async (pin: string) => {
    setStatus('inProgress');
    try {
      await userService.confirmSignUp(credentials.email, pin);
      setStatus('completed');
      clearLastSignUpEmail();
    } catch (error: any) {
      if (error.response?.status === 400) {
        setStatus('failed');
      } else {
        setStatus('notExecuted');
        unknownError();
      }
    }
  };

  const confirmVerification = async () => {
    if (!!credentials.password) {
      try {
        const { data: loggedData } = await authService.login(credentials.email, credentials.password);
        appLogin(loggedData.authToken, 'Email');
        onConfirmed();
      } catch (error) {
        unknownError();
      }
    } else {
      onConfirmed();
    }
  };

  const resendCode = async () => {
    try {
      await userService.resendActivationToken(credentials.email);
    } catch (error) {
      unknownError();
    }
  };

  return { status, verifyEmail, resendCode, confirmVerification };
};
