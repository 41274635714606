import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import ItemDetails from 'components/item/ItemTile/ItemDetails';
import ItemName from 'components/item/ItemTile/ItemName';
import ItemPhoto from 'components/item/ItemTile/ItemPhoto';
import { HeartIcon } from 'components/item/ItemTile/tileIcons';
import { TrackedItemDto } from 'services/Item/itemService.dto';

const BorderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${theme.color.borderGray};
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  overflow: hidden;
`;

const ImageContainer = styled.div`
  width: 100%;
  position: relative;
`;

interface Props {
  item: TrackedItemDto;
  onClick: (itemId: number, orderId?: number) => void;
}

export const MyActivityItemTile = ({ item, onClick }: Props) => {
  return (
    <BorderContainer onClick={() => onClick(item.id, item.orderId)} data-testid={'item-tile'}>
      <ImageContainer>
        <ItemPhoto image={item.imageUrlList[0]} />
        <HeartIcon />
      </ImageContainer>
      <ItemName title={item.title} />
      <ItemDetails item={item} />
    </BorderContainer>
  );
};
