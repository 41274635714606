import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import CheckIconSource from 'assets/svg/successful-icon-green.svg';
import { PaddedContainer } from 'components/containers/ItemCampaignContainers';
import { H3 } from 'components/typography/Headers';
import { Text1 } from 'components/typography/Texts';
import { PurchaseDto } from 'services/Order/orderService.dto';
import { TransactionDto } from 'services/Payment/paymentService.dto';

import PickupDetails from './PickupDetails';

const BackgroundContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  background-color: ${theme.color.lighterGray};
`;

const DataContainer = styled(PaddedContainer)`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  ${theme.mq.tablet} {
    flex-direction: column;
  }
`;

const CheckIcon = styled.img`
  width: 210px;
  height: 210px;
  margin-right: 100px;

  ${theme.mq.tablet} {
    margin: 50px 0;
  }
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 475px;
`;

const Header = styled(H3)`
  margin-bottom: 10px;
`;

const Description = styled(Text1)`
  margin-bottom: 10px;
`;

const TransactionText = styled(Text1)`
  color: ${theme.color.lighterGray2};
`;

interface Props {
  purchase: PurchaseDto;
  transaction: TransactionDto;
}

const CheckoutSuccessContent = ({ purchase, transaction }: Props) => {
  const { t } = useTranslation('checkout');

  return (
    <BackgroundContainer data-testid={'checkout-success'}>
      <DataContainer>
        <CheckIcon src={CheckIconSource} />
        <DetailsContainer>
          <Header data-testid={'checkout-success-header'}>{t('success.header')}</Header>
          <Description data-testid={'checkout-success-description'}>{t('success.description')}</Description>
          <TransactionText data-testid={'checkout-success-transaction'}>
            {t('success.transaction-id', { transactionId: transaction.id })}
          </TransactionText>
          {!purchase.shipmentRequired && <PickupDetails sellerId={purchase.ownerId} itemId={purchase.itemId} />}
        </DetailsContainer>
      </DataContainer>
    </BackgroundContainer>
  );
};

export default CheckoutSuccessContent;
