import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useLoginPopup } from 'features/account';
import { useConversations } from 'providers/ConversationProvider';
import { useGlobalData } from 'providers/GlobalDataProvider';
import { ConversationMessagingContext } from 'services/Message/messageService.dto';

export const useContact = (context: ConversationMessagingContext, interlocutorId?: number, objectId?: number) => {
  const navigate = useNavigate();
  const { currentUser } = useGlobalData();
  const { showLoginPopup } = useLoginPopup();
  const { conversations, createConversation } = useConversations();
  const [conversationId, setConversationId] = useState<number | undefined>();
  const contactInterlocutor = () => {
    if (!currentUser) {
      showLoginPopup();
      return;
    }
    if (currentUser.id === interlocutorId) {
      navigate('/messenger');
      return;
    }
    const conversation = conversations.find(
      it =>
        it.conversation.context === context &&
        (it.conversation.item?.id === objectId || it.conversation.campaign?.id === objectId)
    )?.conversation;
    if (!conversation && !!interlocutorId && context !== 'SUPPORT') {
      createConversation({ context, contextualObjectId: objectId }, interlocutorId).then(response =>
        setConversationId(response)
      );
    } else {
      setConversationId(conversation?.conversationId);
    }
  };

  const contactSupport = () => {
    const conversation = conversations.find(it => it.conversation.context === 'SUPPORT')?.conversation;
    if (!!conversation) {
      setConversationId(conversation?.conversationId);
    }
  };

  useEffect(() => {
    if (!!conversationId) {
      navigate('/messenger/' + conversationId);
    }
  }, [conversationId]);

  return { contactOwner: contactInterlocutor, contactSupport };
};
