import { notificationService } from 'services/Notification/notificationService';
import { AppAreaType } from 'services/Notification/notificationService.dto';

export type ShareType =
  | 'MY_ITEM_FOR_SALE'
  | 'CAMPAIGN_DONATED'
  | 'CAMPAIGN_READY'
  | 'ITEM_FOR_SALE'
  | 'CAMPAIGN_PUBLISHED'
  | 'DEFAULT';

export const DEFAULT_SHARE_TYPE = 'DEFAULT';
export const ITEM_SHARE_TYPES = ['MY_ITEM_FOR_SALE', 'ITEM_FOR_SALE'];
export const CAMPAIGN_SHARE_TYPES = ['CAMPAIGN_DONATED', 'CAMPAIGN_READY', 'CAMPAIGN_PUBLISHED'];

export const ShareTypeAppArea: Record<ShareType, AppAreaType> = {
  MY_ITEM_FOR_SALE: 'ITEM',
  CAMPAIGN_DONATED: 'CAMPAIGN',
  CAMPAIGN_READY: 'CAMPAIGN',
  ITEM_FOR_SALE: 'ITEM',
  CAMPAIGN_PUBLISHED: 'CAMPAIGN',
  DEFAULT: 'DEFAULT_APP_AREA',
};

interface DeepLinkAttributes {
  context?: AppAreaType;
  objectId?: number;
}

export const generateDeepLink = async (attributes: DeepLinkAttributes): Promise<string> => {
  return await notificationService
    .generateDeepLink(attributes.context, attributes.objectId)
    .then(response => response.data.url);
};
