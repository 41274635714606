import styled, { css } from 'styled-components';

import { theme } from 'assets/styles/theme';

import { Text1Bold, Text2Bold } from './Texts';

const buttonStyles = css`
  cursor: pointer;
  background-color: ${theme.color.white};
  color: ${theme.color.red};
  border: none;

  :disabled {
    cursor: auto;
    color: ${theme.color.darkGray};
  }
`;

export const TextButton1 = styled(Text1Bold.withComponent('button'))`
  ${buttonStyles}
`;

export const TextButton2 = styled(Text2Bold.withComponent('button'))`
  ${buttonStyles}
`;
