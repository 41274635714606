import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import auctionEndIcon from 'assets/svg/auction-end-icon.svg';
import { Text1, Text1Bold } from 'components/typography/Texts';
import { AuctionDto } from 'services/Item/itemService.dto';
import { currentDate, parseBackendDate } from 'utils/backendDateParser';
import { formatCountdown, formatDate } from 'utils/dateFormatter';

import { isAuctionExpired } from './auctionUtils';

const Container = styled.div`
  width: 100%;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 7px;
`;

const IconWrapper = styled.div`
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InfoTextWrapper = styled.div`
  margin-left: 10px;
  &:first-of-type {
    margin-right: 10px;
  }
`;

const CounterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const GreenText = styled(Text1Bold)`
  color: ${theme.color.lightGreen};
  margin-left: 5px;
`;

interface Props {
  auction?: AuctionDto;
}

const ENDING_SOON_OFFSET_HOURS = 72;

const AuctionInfo = ({ auction }: Props) => {
  const { t } = useTranslation('item');
  const [currentDateTime, setCurrentDateTime] = useState(currentDate());

  useEffect(() => {
    if (auction) {
      const interval = setInterval(() => setCurrentDateTime(currentDate()), 1000);
      return () => clearInterval(interval);
    }
  }, [auction]);

  const isEndingSoon = (currentDateTime: Date, endDateTime: Date): boolean => {
    const timeDiff = new Date(endDateTime.getTime() - currentDateTime.getTime());
    return timeDiff.getTime() <= ENDING_SOON_OFFSET_HOURS * 60 * 60 * 1000;
  };

  const formatAuctionEnd = () => {
    if (!auction) return;
    const endDateTime = parseBackendDate(auction.endDateTime);
    if (isAuctionExpired(auction)) {
      return <Text1>{t('auction-ended')}</Text1>;
    }
    if (isEndingSoon(currentDateTime, endDateTime)) {
      return (
        <CounterWrapper>
          <Trans
            i18nKey='item:auction-end-counter'
            values={{ end: formatCountdown(currentDateTime, endDateTime) }}
            components={[<GreenText key={1} />]}
          />
        </CounterWrapper>
      );
    }
    return <Text1>{t('auction-end-date', { date: formatDate(endDateTime) })}</Text1>;
  };

  return (
    <Container>
      <Row>
        <IconWrapper>
          <img src={auctionEndIcon} alt='auction end' />
        </IconWrapper>
        <InfoTextWrapper data-testid={'auction-end'}>{formatAuctionEnd()}</InfoTextWrapper>
      </Row>
    </Container>
  );
};

export default AuctionInfo;
