import styled from 'styled-components';

import HideIconSource from 'assets/svg/hide-password.svg';
import ShowIconSource from 'assets/svg/show-password.svg';

const Button = styled.button`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  outline: none;
  width: 22px;
  height: 20px;
`;

const ShowIcon = styled.img`
  width: 22px;
  padding-bottom: 1px;
`;

const HideIcon = styled.img`
  width: 22px;
`;

interface Props {
  active: boolean;
  className?: string;
  testId?: string;
  onClick: () => void;
}

const EyeButton = ({ active, className, testId, onClick }: Props) => {
  return (
    <Button data-testid={testId} className={className} onClick={onClick}>
      {active && <HideIcon src={HideIconSource} />}
      {!active && <ShowIcon src={ShowIconSource} />}
    </Button>
  );
};

export default EyeButton;
