import { useTranslation } from 'react-i18next';

import { InfoSection, InfoSectionWrapper, StyledTitle } from 'components/containers/InfoSection';
import { ScreenContainer } from 'components/containers/ScreenContainer';

export const ActivateAccountSuccess = () => {
  const { t } = useTranslation('account');

  return (
    <ScreenContainer>
      <InfoSectionWrapper>
        <InfoSection onSuccess={true} />
        <StyledTitle>{t('account-activated')}</StyledTitle>
      </InfoSectionWrapper>
    </ScreenContainer>
  );
};
