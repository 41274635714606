import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import locationIcon from 'assets/svg/pointer-map.svg';
import RedButton from 'components/buttons/RedButton';
import { IconWrapper } from 'components/containers/Containers';
import { Text1 } from 'components/typography/Texts';
import { MeetupPointDto } from 'services/Order/orderService.dto';

const Container = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: ${theme.color.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  padding: 12px 60px;

  ${theme.mq.desktop} {
    padding: 12px 24px;
  }

  ${theme.mq.phone} {
    padding: 12px 24px;
    flex-direction: column;
    align-items: start;
  }
`;

const AddressContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Address = styled(Text1)`
  color: ${theme.color.darkerGray};
`;

const SendButton = styled(RedButton)`
  max-width: 240px;
  height: 46px;

  ${theme.mq.phone} {
    max-width: 100%;
  }
`;

interface Props {
  meetupPoint: MeetupPointDto;
  onSendClick: () => void;
}

export const MessageLocationSelectPanel = ({ onSendClick, meetupPoint }: Props) => {
  const { t } = useTranslation('message');

  const formatAddress = () => {
    if (!!meetupPoint.streetAddress) {
      return (
        <>
          <Address>{`${meetupPoint.city}, ${meetupPoint.streetAddress}`}</Address>
          <Address>{`${meetupPoint.state}, ${meetupPoint.zip}`}</Address>
        </>
      );
    } else {
      return (
        <>
          <Address>{`${meetupPoint.city}`}</Address>
          <Address>{`${meetupPoint.state}, ${meetupPoint.zip}`}</Address>
        </>
      );
    }
  };

  return (
    <Container>
      <AddressContainer>
        <IconWrapper src={locationIcon} alt={'address location'} />
        <TextContainer>
          <Text1>{meetupPoint.name}</Text1>
          {formatAddress()}
        </TextContainer>
      </AddressContainer>
      <SendButton onClick={onSendClick}>{t('map.send-btn')}</SendButton>
    </Container>
  );
};
