import { useTranslation } from 'react-i18next';
import Select, { StylesConfig } from 'react-select';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { Text1 } from 'components/typography/Texts';

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: auto;
`;

const styles: StylesConfig = {
  control: (styles, { isFocused }) => ({
    ...styles,
    minWidth: '100px',
    boxShadow: isFocused ? 'none' : styles.boxShadow,
    borderColor: isFocused ? `${theme.color.red}` : styles.borderColor,
    '&:hover': {
      borderColor: `${theme.color.red}`,
    },
  }),
  valueContainer: styles => {
    return {
      ...styles,
      justifyContent: 'flex-start',
    };
  },
  menu: styles => {
    return {
      ...styles,
      minWidth: '200px',
    };
  },
  menuList: styles => {
    return {
      ...styles,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    };
  },
  option: (styles, { isFocused, isSelected }) => {
    const color = `${theme.color.red}`;
    const focusedColor = 'rgba(252, 104, 111, 0.2)';

    return {
      ...styles,
      backgroundColor: isSelected ? color : isFocused ? focusedColor : undefined,
      textAlign: 'start',
    };
  },
};

export enum BuyerListOrderStatus {
  CONFIRMED = 'CONFIRMED',
  AUTHORIZED = 'AUTHORIZED',
  CHARGED = 'CHARGED',
  PICKED_UP = 'PICKED_UP',
  COMPLETED = 'COMPLETED',
  PAID = 'PAID',
  SHIPPABLE = 'SHIPPABLE',
  SHIPPED = 'SHIPPED',
}

export type SortByFilterOptions = {
  id: number;
  title: string;
  type: BuyerListOrderStatus[];
  isVisible?: boolean;
};

interface FilterProps {
  availableFilterOptions: SortByFilterOptions[];
  selectedFilterId: number;
  handleChange: (option: { value: number; label: string } | null) => void;
}

export const OrderStatusFilter = ({ availableFilterOptions, selectedFilterId, handleChange }: FilterProps) => {
  const { t } = useTranslation('myProfile');

  const options = availableFilterOptions.map(option => ({
    value: option.id,
    label: option.title,
  }));

  const selectedOption = options.find(option => option.value === selectedFilterId);

  return (
    <FilterContainer data-testid={'order-status-filter'}>
      <Text1>{t('tracked-item.show')}</Text1>
      <Select
        value={selectedOption}
        onChange={(selectedOption: any) => handleChange(selectedOption)}
        options={options}
        styles={styles}
      />
    </FilterContainer>
  );
};
