import { MouseEvent, useEffect, useState } from 'react';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import closeIcon from 'assets/svg/close-white.svg';
import errorIcon from 'assets/svg/notification-error.svg';
import infoIcon from 'assets/svg/notification-info.svg';
import successIcon from 'assets/svg/notification-success.svg';
import InfoButton from 'components/buttons/InfoButton';
import { IconWrapper } from 'components/containers/Containers';
import { H5 } from 'components/typography/Headers';
import { Text1 } from 'components/typography/Texts';
import { NotificationData } from 'providers/NotificationProvider';

const Container = styled.div<{
  backgroundColor: string;
  isVisible: boolean;
}>`
  width: 100%;
  height: 60px;
  background-color: ${props => props.backgroundColor};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transform: translateY(${props => (props.isVisible ? '0' : '-100%')});
  transition: opacity 0.3s ease, transform 0.3s ease;
`;

const InnerContainer = styled.div<{ clickable: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  max-width: ${theme.maxWidthContainer}px;
  cursor: ${props => (props.clickable ? 'pointer' : 'default')};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 24px;
  padding: 24px;

  ${theme.mq.phone} {
    padding: 12px;
    gap: 12px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  height: 46px;
`;

const ActionContainer = styled.div`
  margin: auto 24px;
  display: flex;
  height: 100%;
  gap: 20px;
`;

const CloseIcon = styled(IconWrapper)`
  margin-right: 14px;
  cursor: pointer;
  padding: 10px;
`;

const Title = styled(H5)`
  color: ${theme.color.white};
`;

const Description = styled(Text1)`
  color: ${theme.color.white};
`;

interface NotificationProps {
  notification: NotificationData;
  onClose: () => void;
  onClick?: () => void;
}

export const NotificationItem = ({ notification, onClose, onClick }: NotificationProps) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);

    if (notification.content.autoHide) {
      const timer = setTimeout(() => {
        handleClose();
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, []);

  const handleClose = () => {
    setIsVisible(false);
    setTimeout(() => {
      onClose();
    }, 300);
  };

  const handleCloseClick = (event: MouseEvent<HTMLImageElement>) => {
    event.stopPropagation();
    handleClose();
  };

  return (
    <Container
      data-testid={notification.content.type}
      isVisible={isVisible}
      backgroundColor={notificationConfig[notification.content.type].backgroundColor}>
      <InnerContainer clickable={!!onClick} onClick={onClick}>
        <ContentContainer>
          <IconWrapper src={notificationConfig[notification.content.type].icon} />
          {notification.content.title && <Title>{notification.content.title}</Title>}
          {notification.content.description && <Description>{notification.content.description}</Description>}
        </ContentContainer>
        <ActionContainer>
          {notification.content.type === 'info' && (
            <ButtonContainer>
              {notification.content.firstButton && (
                <InfoButton onClick={onClick}>{notification.content.firstButton.label}</InfoButton>
              )}
              {notification.content.secondButton && (
                <InfoButton onClick={onClick}>{notification.content.secondButton.label}</InfoButton>
              )}
            </ButtonContainer>
          )}
          <CloseIcon data-testid={'close-button'} src={closeIcon} onClick={handleCloseClick} />
        </ActionContainer>
      </InnerContainer>
    </Container>
  );
};

interface NotificationConfig {
  [key: string]: {
    backgroundColor: string;
    icon: string;
  };
}

const notificationConfig: NotificationConfig = {
  success: {
    backgroundColor: `${theme.color.notificationGreen}`,
    icon: successIcon,
  },
  info: {
    backgroundColor: `${theme.color.blue}`,
    icon: infoIcon,
  },
  error: {
    backgroundColor: `${theme.color.red}`,
    icon: errorIcon,
  },
};
