import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { OpacityOverlay } from 'components/ui-elements/OpacityOverlay';
import { ShippingAddressesModule } from 'features/my-profile/shippingAddress/ShippingAddressesModule';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-self: flex-start;
`;

interface Props {
  isProcessing: boolean;
}

const AddressSelection = ({ isProcessing }: Props) => {
  const { t } = useTranslation('checkout');

  return (
    <Container data-testid={'address-selection'}>
      <ShippingAddressesModule header={t('item.address-selection')} />
      {isProcessing && <OpacityOverlay data-testid={'opacity-overlay'} />}
    </Container>
  );
};

export default AddressSelection;
