import { useTranslation } from 'react-i18next';
import { useMask } from '@react-input/mask';
import { FormikErrors } from 'formik';
import styled from 'styled-components';

import countries from 'assets/constants/countries';
import states from 'assets/constants/states';
import { theme } from 'assets/styles/theme';
import Spinner from 'components/ui-elements/Spinner';
import {
  DropdownInput,
  FormContainer,
  InputsContainer,
  InputVariant,
  PhoneNumberInput,
  TextInput,
} from 'features/my-profile/components/FormContainers';
import { ShippingAddressFormData } from 'features/my-profile/shippingAddress/hooks/useShippingAddressForm';
import {
  MAX_ADDRESS_LENGTH,
  MAX_CITY_LENGTH,
  MAX_POSTAL_CODE_LENGTH,
  MAX_SINGLE_NAME_LENGTH,
  MAX_TOTAL_NAME_LENGTH,
} from 'features/my-profile/shippingAddress/utils/shippingAddressUtils';
import { useShippingAddress } from 'providers/ShippingAddressProvider';

interface Props {
  values: ShippingAddressFormData;
  setFieldValue: (field: string, value: string) => void;
  errors: FormikErrors<ShippingAddressFormData>;
  isEditMode: boolean;
}

const TextInputInRow1 = styled(TextInput)`
  flex-basis: 48%;
`;

const TextInputInRow2 = styled(TextInput)`
  flex-basis: 30%;

  ${theme.mq.phone} {
    flex-basis: 48%;
  }
`;

const TextInputInRow3 = styled(TextInput)`
  flex-basis: 20%;

  ${theme.mq.phone} {
    flex-basis: 48%;
  }
`;

const StyledDropdownInput = styled(DropdownInput)`
  flex-basis: 21%;

  ${theme.mq.phone} {
    flex-basis: 48%;
  }
`;

const StyledPhoneNumberInput = styled(PhoneNumberInput)`
  flex-basis: 32%;
`;

export const ShippingAddressForm = ({ values, setFieldValue, errors, isEditMode }: Props) => {
  const { t } = useTranslation('myProfile');
  const { isSaving } = useShippingAddress();
  const variant: InputVariant = isEditMode ? 'UPDATE' : 'NEW';

  const zipInputRef = useMask({ mask: '______', replacement: { _: /\d|-/ } });

  if (isSaving) return <Spinner />;
  return (
    <FormContainer data-testid={'shipping-address-form'} variant={variant}>
      <InputsContainer>
        <TextInputInRow1
          testId={'first-name-field'}
          value={values.firstName}
          placeholder={t('form-inputs.first-name-placeholder')}
          onChange={value => setFieldValue('firstName', value)}
          errorMessage={errors.firstName}
          variant={variant}
          maxLength={
            values.lastName.length === 0 ? MAX_SINGLE_NAME_LENGTH : MAX_TOTAL_NAME_LENGTH - values.lastName.length
          }
        />
        <TextInputInRow1
          testId={'last-name-field'}
          value={values.lastName}
          placeholder={t('form-inputs.last-name-placeholder')}
          onChange={value => setFieldValue('lastName', value)}
          errorMessage={errors.lastName}
          variant={variant}
          maxLength={
            values.firstName.length === 0 ? MAX_SINGLE_NAME_LENGTH : MAX_TOTAL_NAME_LENGTH - values.firstName.length
          }
        />
        <TextInputInRow2
          testId={'address-field'}
          value={values.addressLine1}
          placeholder={t('form-inputs.address-placeholder')}
          onChange={value => setFieldValue('addressLine1', value)}
          errorMessage={errors.addressLine1}
          variant={variant}
          maxLength={MAX_ADDRESS_LENGTH}
        />
        <TextInputInRow2
          testId={'address-optional-field'}
          value={values.addressLine2}
          placeholder={t('form-inputs.address-optional-placeholder')}
          onChange={value => setFieldValue('addressLine2', value)}
          errorMessage={errors.addressLine2}
          variant={variant}
          maxLength={MAX_ADDRESS_LENGTH}
        />
        <TextInputInRow2
          testId={'city-field'}
          value={values.city}
          placeholder={t('form-inputs.city-placeholder')}
          onChange={value => setFieldValue('city', value)}
          errorMessage={errors.city}
          variant={variant}
          maxLength={MAX_CITY_LENGTH}
        />
        <StyledDropdownInput
          testId={'state-field'}
          value={values.state}
          options={states}
          placeholder={t('form-inputs.state-placeholder')}
          onChange={value => setFieldValue('state', value)}
          errorMessage={errors.state}
          variant={variant}
        />
        <TextInputInRow3
          testId={'zip-field'}
          inputRef={zipInputRef}
          value={values.zip}
          placeholder={t('form-inputs.zip-placeholder')}
          onChange={value => setFieldValue('zip', value)}
          errorMessage={errors.zip}
          variant={variant}
          maxLength={MAX_POSTAL_CODE_LENGTH}
        />
        <StyledDropdownInput
          testId={'country-field'}
          value={values.country}
          options={countries}
          placeholder={t('form-inputs.country-placeholder')}
          onChange={value => setFieldValue('country', value)}
          errorMessage={errors.country}
          variant={variant}
          disabled={true}
        />
        <StyledPhoneNumberInput
          testId={'phone-field'}
          value={values.phone}
          defaultCountry={'US'}
          placeholder={t('form-inputs.phone-placeholder')}
          onChange={value => setFieldValue('phone', value)}
          errorMessage={errors.phone}
          variant={variant}
        />
      </InputsContainer>
    </FormContainer>
  );
};
