import styled from 'styled-components';

import Spinner from 'components/ui-elements/Spinner';
import { usePayment } from 'providers/PaymentProvider';

import { PaymentFormHeader } from './PaymentFormHeader';
import { PaymentMethod } from './PaymentMethod';

const PaymentMethodsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

interface Props {
  header?: string;
}

export const PaymentMethodsList = ({ header }: Props) => {
  const { paymentMethods, isLoading, setIsInsertMode } = usePayment();

  return (
    <div data-testid={'payment-methods-list'}>
      <PaymentFormHeader header={header} onAddNew={() => setIsInsertMode(true)} />
      {isLoading && <Spinner />}
      <PaymentMethodsContainer>
        {!isLoading &&
          paymentMethods?.map(paymentMethod => (
            <PaymentMethod key={paymentMethod.id} paymentMethod={paymentMethod} isLast={paymentMethods.length === 1} />
          ))}
      </PaymentMethodsContainer>
    </div>
  );
};
