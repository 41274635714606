import { useTranslation } from 'react-i18next';

import RedButton from 'components/buttons/RedButton';
import { ItemDto } from 'services/Item/itemService.dto';

import { useListOfSoldPopup } from './useListOfSoldPopup';

interface Props {
  item: ItemDto;
}

export const ListOfSoldButton = ({ item }: Props) => {
  const { t } = useTranslation('myProfile');
  const { showListOfSoldPopup } = useListOfSoldPopup();

  return (
    <RedButton data-testid={'list-of-sold-btn'} onClick={() => showListOfSoldPopup({ item })}>
      {t('tracked-item.multiple-list')}
    </RedButton>
  );
};
