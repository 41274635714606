import { useEffect, useState } from 'react';
import axios, { AxiosPromise } from 'axios';

import { ApiResponse } from './apiResponse';

export const useBackendData = <T>(
  provider: (id: number) => AxiosPromise<T>,
  id?: number,
  options?: { fetchIf?: boolean }
) => {
  const [response, setResponse] = useState<ApiResponse<T>>({ loading: true });

  const fetchData = (id: number) => {
    setResponse({ loading: true });

    provider(id)
      .then(({ data: content }) => {
        setResponse({ loading: false, content });
      })
      .catch(error => {
        if (axios.isAxiosError(error)) {
          setResponse({ loading: false, error });
        } else {
          throw error;
        }
      });
  };

  const refreshData = () => {
    if (id) {
      fetchData(id);
    }
  };

  useEffect(() => {
    if (options?.fetchIf !== false && id) {
      fetchData(id);
    } else {
      setResponse({ loading: false });
    }
  }, [id, options?.fetchIf]);

  return { ...response, refreshData };
};
